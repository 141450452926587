import ApiConfig from "../../api/ApiConfig";
import Axe from "../../assets/images/x.svg";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import Carousel from "react-bootstrap/Carousel";
import Dropdown from "react-bootstrap/Dropdown";
import FileSaver from "file-saver";
import Form from "react-bootstrap/Form";
import Logo from "../../assets/images/Logo.svg";
import Nav from "react-bootstrap/Nav";
import React, { useContext, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Up_arrow_white from "../../assets/images/Up_arrow_white.svg";
import close_modal from "../../assets/images/close_modal.svg";
import close_modal_dark from "../../assets/images/close_modal_dark.svg";
import download_icon from "../../assets/images/download_icon.svg";
import download_icon2 from "../../assets/images/download_icon2.svg";
import ic_bars_dot from "../../assets/images/ic_bars_dot.svg";
import ic_green_pen from "../../assets/images/ic_green_pen.svg";
import ic_search from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ic_view from "../../assets/images/ic_view.svg";
import ic_zoom from "../../assets/images/ic_zoom.svg";
import image_prf from "../../assets/images/image_prf.svg";
import img_custom from "../../assets/images/img_custom.png";
import img_logo from "../../assets/images/img_logo.svg";
import img_logo_bottom from "../../assets/images/img_logo_bottom.png";
import img_logo_left from "../../assets/images/img_logo_left.png";
import img_logo_top from "../../assets/images/img_logo_top.png";
import letter from "../../assets/images/letter.svg";
import project_document from "../../assets/images/project_document.svg";
import view_blue from "../../assets/images/view_blue.svg";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";

const ProjectDocumentation = () => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  let [pageLoad, setPageLoad] = useState(true);

  // state of View Button
  //   let [isView, setIsView] = useState(false);
  //   let [isView1, setIsView1] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [noOfRows, setNoOfRows] = useState(1);
  const {
    sidebar,
    setSidebar,
    backdrop,
    setbackdrop,
    profile,
    setprofile,
    temp,
    settemp,
    Dark,
  } = useContext(AppContext);
  const [Print, setPrint] = useState(false);
  const [loader, setLoader] = useState(false);

  // GET_SALARY_SLIP
  const getSalarySlip = async () => {
    try {
      setLoader(true);
      const res = await simpleGetCallWithErrorResponse(
        ApiConfig.GET_SALARY_SLIP
      );
      const { Success, Message, Data } = res?.json;
      if (Success === true) {
        const { FileContents, ContentType, FileDownloadName } = Data;
        FileSaver.saveAs(
          "data:" + ContentType + ";base64," + FileContents,
          FileDownloadName
        );
      }
    } catch (error) {
      console.log("catch error response of GET_SALARY_SLIP API", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <BackDropLoader />}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Personal </h6>
          </div>
          <section className="cx-content-section">
            <div className="row main-row">
              <div className="project-doc-section">
                <section className="project-doc-section-wrapper">
                  <div>
                    <div className="templates_body-main row">
                      <div className="col-lg-3 col-md-4">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp1"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp1"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Salary Slip</label>
                              <Link to="#">
                                {" "}
                                <img
                                  src={download_icon}
                                  alt=""
                                  onClick={getSalarySlip}
                                />{" "}
                              </Link>
                            </div>
                            <div className="right-logo">
                              <div className="img-right img">
                                <Link to="/projectdetails">
                                  {" "}
                                  <img
                                    src={project_document}
                                    alt=""
                                    className="folder-img"
                                  />
                                </Link>
                              </div>
                              {/* <Link to="/projectdetails">
                                <img src={ic_zoom} alt="" className="" />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      {/* <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp2'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp2'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp3'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp3'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp4'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp4'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp5'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp5'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp6'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp6'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp7'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp7'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className='col-lg-3 col-md-4'>
                        <div className='training-letter'>
                          <input
                            type='radio'
                            name='temp'
                            id='temp7'
                            className='d-none form-check-input'
                          />
                          <label
                            htmlFor='temp7'
                            className='label-main-out d-block'
                          >
                            <div className='text-logo text-center'>
                              <label htmlFor=''>Project Name</label>
                              <Link to='#'>
                                <img src={download_icon} alt='' />{' '}
                              </Link>
                            </div>
                            <div className='right-logo'>
                              <div className='img-right img'>
                                <Link to='/projectdetails'>
                                  {' '}
                                  <img
                                    src={project_document}
                                    alt=''
                                    className='folder-img'
                                  />
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ProjectDocumentation;
