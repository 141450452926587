import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import NoData from "../../sharedComponent/NoData";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";

const AssignCallUsers = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Call Assigning</h6>
        </div>

        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="mt-0 cus-mb">
              <input
                style={{ color: "var(--black_111111_LightMode)" }}
                name="uploadfile"
                type="file"
              />
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <button className="sml-btn-sub-header" style={{ color: "#fff" }}>
                Upload
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <button
              className="sml-btn-sub-header"
              onClick={() => setShow(!show)}
            >
              <Link className="details-link">Assign</Link>
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-evenly custom-mb agent-head-section">
          <div className="role-table-header-main"></div>
          {/* <button className="imporButton">
            <Link to="/CandidatesData" className="details-link">
              View Data
            </Link>
          </button> */}
          {/* <button className="imporButton">
            <Link to="/DownloadAgentReport" className="details-link">
              Download Data
            </Link>
          </button> */}
          {/* <button className="imporButton">
            <Link to="/BulkCallView" className="details-link">
              Re-sign Candidate
            </Link>
          </button> */}
          {/* <button className="imporButton">
            <Link to="/UpdateUserDetails" className="details-link">
              Call Report
            </Link>
          </button> */}
        </div>

        <div className="d-flex justify-content-evenly custom-mb agent-head-section">
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header>
              <Modal.Title>
                <label htmlFor="" className="create-role-text">
                  Modal Title
                </label>
              </Modal.Title>
              <div className="rightAxe">
                <img src={Axe} alt="" onClick={() => setShow(false)} />
              </div>
            </Modal.Header>
            <div className="hr-line-modal">
              <hr style={{ margin: "0" }} />
            </div>
            <Modal.Body>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Client Name
                </label>
                <select
                  className="tasKshowusser"
                  // onChange={(e) => setService(e.target.value)}
                >
                  <option>Agent List</option>
                  <option>Thushara</option>
                  <option>sobhisha</option>
                </select>
              </div>
            </Modal.Body>

            <div className="hr-line-modal-1">
              <hr style={{ margin: "0" }} />
            </div>
            <Modal.Footer className="model-footer-arbaz-responsive">
              <div>
                <button
                  className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                  onClick={handleClose}
                >
                  Save Changes
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr No.</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
                <th scope="col">Update Date</th>
                <th scope="col">Location</th>
                <th scope="col">Technology</th>
                <th scope="col">User Details</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row-custom">
                <td colSpan={9}>
                  <NoData />
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default AssignCallUsers;
