import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import ic_view from "../../assets/images/ic_view.svg";
import image_prf from "../../assets/images/image_prf.svg";
import ic_search from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
// import view_blue from "../../assets/images/view_blue.svg";
import letter from "../../assets/images/letter.svg";
import download_icon from "../../assets/images/download_icon.svg";
import download_icon2 from "../../assets/images/salary_download.svg";
import download_icon_blue from "../../assets/images/salary_download_blue.svg";
import view_salary_grey from "../../assets/images/view_salary_grey.svg";
import view_blue from "../../assets/images/view_salary_blue.svg";
import project_document from "../../assets/images/project_document.svg";

import ic_bars_dot from "../../assets/images/ic_bars_dot.svg";
import Logo from "../../assets/images/Logo.svg";
import img_logo_bottom from "../../assets/images/img_logo_bottom.png";
import img_logo_left from "../../assets/images/img_logo_left.png";
import img_logo from "../../assets/images/img_logo.svg";
import img_logo_top from "../../assets/images/img_logo_top.png";
import img_custom from "../../assets/images/img_custom.png";
import ic_zoom from "../../assets/images/ic_zoom.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ic_green_pen from "../../assets/images/ic_green_pen.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Up_arrow_white from "../../assets/images/Up_arrow_white.svg";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { AppContext } from "../../context/AppContext";
import Axe from "../../assets/images/x.svg";
import close_modal from "../../assets/images/close_modal.svg";
import close_modal_dark from "../../assets/images/close_modal_dark.svg";

const Personal = () => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const [isHovered1, setIsHovered1] = useState(false);
  const onMouseEnter1 = () => {
    setIsHovered1(true);
  };
  const onMouseLeave1 = () => {
    setIsHovered1(false);
  };

  let [pageLoad, setPageLoad] = useState(true);

  // state of View Button
  //   let [isView, setIsView] = useState(false);
  //   let [isView1, setIsView1] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);

  const [noOfRows, setNoOfRows] = useState(1);
  const {
    sidebar,
    setSidebar,
    backdrop,
    setbackdrop,
    profile,
    setprofile,
    temp,
    settemp,
    Dark,
  } = useContext(AppContext);
  const [Print, setPrint] = useState(false);
  return (
    <>
      {/* <Header />
      <Sidebar /> */}

      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">My Documents </h6>
          </div>
          <section className="cx-content-section">
            <div className="row main-row">
              <div className="personal-section">
                <section className="personal-section-wrapper">
                  <div>
                    <div className="templates_body-main row">
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp1"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp1"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Training Letter</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp2"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp2"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Training Certificate</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp3"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp3"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Salary Breakup</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp4"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp4"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Internship Letter</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp5"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp5"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Salary Slip</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow1}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow1}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp6"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp6"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Offer Letter</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp7"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp7"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Appointment Letter</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 personal_card">
                        <div className="training-letter">
                          <input
                            type="radio"
                            name="temp"
                            id="temp8"
                            className="d-none form-check-input"
                          />
                          <label
                            htmlFor="temp8"
                            className="label-main-out d-block"
                          >
                            <div className="text-logo text-center">
                              <label htmlFor="">Increment Letter</label>
                              <Link to="#">
                                <img src={download_icon} alt="" />{" "}
                              </Link>
                            </div>
                            <div className="right-logo logo-back">
                              <div className="img-right img">
                                <img
                                  src={img_logo}
                                  alt=""
                                  className="folder-img"
                                  onClick={handleShow}
                                />
                              </div>
                              {/* <Link to="#" onClick={handleShow}>
                                <img
                                  src={ic_zoom}
                                  alt=""
                                  className="zoom-img"
                                />
                              </Link> */}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setbackdrop(false);
                        settemp(false);
                      }}
                    >
                      <Modal
                        style={{ zIndex: "2200" }}
                        show={show}
                        onHide={handleClose}
                        className="modal-templates"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Body className="document-popup-modal">
                          <img src={letter} alt="" />
                        </Modal.Body>
                      </Modal>
                    </div>
                    <div
                      onClick={() => {
                        setbackdrop(false);
                        settemp(false);
                      }}
                    >
                      <Modal
                        size="lg"
                        show={show1}
                        onHide={handleClose1}
                        aria-labelledby="contained-modal-title-vcenter"
                        className="salary-slip-modal"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            Salary Slip
                          </Modal.Title>
                        </Modal.Header>
                        <div className="lineSeperate"></div>
                        <Modal.Body>
                          <div className="salary-slip-modal-wrapper">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="year-selector">
                                  <label htmlFor="" className="mb-2 text-grey">
                                    Year
                                  </label>
                                  <select
                                    className="form-select tasKCategory1"
                                    aria-label="Default select example"
                                  >
                                    <option selected>Select Year</option>
                                    <option>2022</option>
                                    <option>2021</option>
                                    <option>2020</option>
                                    <option>2019</option>
                                    <option>2018</option>
                                    <option>2017</option>
                                    <option>2016</option>
                                    <option>2015</option>
                                    <option>2014</option>
                                    <option>2013</option>
                                    <option>2012</option>
                                    <option>2011</option>
                                    <option>2010</option>
                                    <option>2009</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="month-selector">
                                  <label htmlFor="" className="mb-2 text-grey">
                                    Month
                                  </label>
                                  <select
                                    className="form-select tasKCategory1"
                                    aria-label="Default select example"
                                  >
                                    <option selected>Select Month</option>
                                    <option>January</option>
                                    <option>February</option>
                                    <option>March</option>
                                    <option>April</option>
                                    <option>May</option>
                                    <option>June</option>
                                    <option>July</option>
                                    <option>August</option>
                                    <option>September</option>
                                    <option>October</option>
                                    <option>November</option>
                                    <option>December</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="buttons-wrapper mt-2">
                                  <Modal.Footer>
                                    <Button
                                      onClick={handleClose1}
                                      className="btn-cancel mx-3"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      className="btn-generate"
                                      onClick={handleShow2}
                                    >
                                      Generate
                                    </Button>
                                  </Modal.Footer>
                                </div>
                              </div>

                              {show2 ? (
                                <div className="col-lg-12">
                                  <div class="lineSeperate2"></div>
                                  <div className="generate-test mt-3">
                                    <span className="text-grey">
                                      Genrated Salary Slip
                                    </span>
                                  </div>
                                  <div className="download-option mt-2">
                                    <div className="date-wrapper">
                                      <span>Aug 17, 2022</span>
                                    </div>
                                    <div className="action-wrapper">
                                      <Link
                                        to="#"
                                        onMouseEnter={onMouseEnter}
                                        onMouseLeave={onMouseLeave}
                                        onClick={() => {
                                          handleShow();
                                          setShow1(false);
                                        }}
                                        className="mx-2"
                                      >
                                        <span>View</span>
                                        {isHovered ? (
                                          <img src={view_blue} alt="" />
                                        ) : (
                                          <img src={view_salary_grey} alt="" />
                                        )}
                                      </Link>
                                      <Link
                                        to="#"
                                        onMouseEnter={onMouseEnter1}
                                        onMouseLeave={onMouseLeave1}
                                        className="mx-2"
                                      >
                                        <span>Download</span>
                                        {isHovered1 ? (
                                          <img
                                            src={download_icon_blue}
                                            alt=""
                                          />
                                        ) : (
                                          <img src={download_icon2} alt="" />
                                        )}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Personal;
