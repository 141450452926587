import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import NoData from "../../sharedComponent/NoData";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import "../../assets/css/crmphonebook/Agent.scss";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

export default function DownloadAgentReport() {
  const [selectedRows, setSelectedRows] = useState(10);
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const {} = useContext(AppContext);
  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Candidates Data Download</h6>
          </div>

          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input type="text" placeholder="Search here" />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper mt-0 cus-mb">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  value={selectedRows}
                  onChange={handleSelectChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper m-0 ">
                <input type="date" placeholder="Enter Date" />
              </div>
              <div className="entries-wrapper mt-0 cus-mb">
                <p>Client Name</p>
                <select className="tasKshowusser">
                  <option>Agent List</option>
                  <option>Thushara</option>
                  <option>sobhisha</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <button className="sml-btn-sub-header">
                <label style={{ color: "#fff" }}>Get Data</label>
              </button>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th scope="col">Name</th>
                  <th scope="col">Agent</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">Current Profession</th>
                  <th scope="col">Update Date</th>
                  <th scope="col">Location</th>
                  <th scope="col">Technology</th>
                  <th scope="col">Current CTC</th>
                  <th scope="col">Looking Job Role</th>
                  <th scope="col">Experience</th>
                  <th scope="col">Notice Period</th>
                  <th scope="col">Negotiable</th>
                  <th scope="col">Perferred Location</th>
                  <th scope="col">Current Company</th>
                  <th scope="col">Job Change Day</th>
                  <th scope="col">Communication</th>
                  <th scope="col">Remarks</th>
                  <th scope="col">Additional Notes</th>
                  <th scope="col">Offer</th>
                  <th scope="col">ECTC Market Standard</th>
                  <th scope="col">ECTC Hike</th>
                  <th scope="col">Relevant Experience</th>
                  <th scope="col">Last day in Organisation</th>
                  <th scope="col">Schedule Meeting Day</th>
                  <th scope="col">Schedule Meeting Time</th>
                  <th scope="col">Job Role Description</th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-row-custom">
                  <td colSpan={28}>
                    <NoData />
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination />
          </div>
        </div>
      </div>
    </>
  );
}
