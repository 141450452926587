import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import redbytes from "../../assets/images/custom-rb-logo.png";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import ic_excel from "../../assets/images/ic_excel.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import plus from "../../assets/images/plus_officecaller.svg";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Form from "react-bootstrap/Form";

const Features = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [showPersonal, setShowPersonal] = useState(false);
  const [showPersonaledit, setShowPersonaledit] = useState(false);
  const [category, setCategory] = useState([]);
  const [domain, setDomain] = useState([]);

  const handleUpdate = () => {
    // Handle the update logic here
    console.log("Updated Category:", category);
    console.log("Updated Domain:", domain);
    // Close the modal after updating
    setShowPersonal(false);
  };
  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end
  const agentdata = [
    {
      id: 1,
      name: "Login",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 2,
      name: "Reports and analysis Management",
      level: "Average",
      complexity: "Advanced",
    },
    {
      id: 3,
      name: "User Management",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 4,
      name: "Content Management",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 5,
      name: "AR Management",
      level: "High",
      complexity: "Advanced",
    },
    {
      id: 6,
      name: "User Profile Management",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 7,
      name: "Rewards Management",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 8,
      name: "Analytical Management",
      level: "High",
      complexity: "Advanced",
    },
    {
      id: 9,
      name: "Chats & Forum Management",
      level: "High",
      complexity: "Advanced",
    },
    {
      id: 10,
      name: "Synchronization Management",
      level: "High",
      complexity: "Advanced",
    },
    {
      id: 11,
      name: "Notification Management",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 12,
      name: "Payment Gateway Management",
      level: "Average",
      complexity: "Advanced",
    },
    {
      id: 13,
      name: "Social Media Management",
      level: "Average",
      complexity: "Advanced",
    },
    {
      id: 14,
      name: "Rating and Reviews Management",
      level: "Average",
      complexity: "Basic",
    },
    {
      id: 15,
      name: "Logout",
      level: "Average",
      complexity: "Basic",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <div className="textHeader">
            <h6 className="mt-2">Features</h6>
          </div>
          <div className="buttonsInsider">
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => setShowPersonal(true)}
            >
              + Add Category
            </button>
            {/* <button
                className="create-btn-sub-header"
                variant="primary"
              >
               Import Excel File
              </button>
              <button
                className="create-btn-sub-header"
                variant="primary"
              >
               Export Excel File
              </button> */}
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search "
                // onChange={(e) => {
                //   setSearchValue(e.target.value);
                //   setCurrentPage(0);
                // }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                // onChange={(e) => {
                //   setUsersPerPage(e.target.value);
                //   setCurrentPage(0);
                // }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </div>
          </div>

          <div className="import-export-btn-wrapper mt-4">
            <button>
              {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
              <img
                src={ic_excel}
                // onClick={(e) => downloadExcelFile(e)}
                alt=""
              />
              {/* )} */}
            </button>
          </div>
        </div>

        <div id="crm-call-form">
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              {" "}
              Sr.No.
              <thead>
                <tr className="firstTabelRow">
                  <th scope="col"> Sr.No.</th>
                  <th scope="col"> Feature Module</th>

                  <th scope="col">Weighting Factor</th>
                  <th scope="col">Basic/ Advanced Actions</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {agentdata.map((item, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{item.id}</td>
                    <td>{item.name}</td>

                    <td> {item.level}</td>
                    <td> {item.complexity}</td>
                    <td>
                      {" "}
                      <div>
                        <img
                          onClick={() => setShowPersonaledit(true)}
                          src={edit}
                          alt="Image 1"
                          className="cell-image"
                        />

                        <Link to="/ViewFeatures">
                          <img
                            src={eye}
                            alt="Image 2"
                            className="cell-image ml-1"
                          />
                        </Link>

                        <Link to="/LeadListCount">
                          <img
                            src={deleteicon}
                            alt="Image 2"
                            className="cell-image ml-1"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
          // data={EmployeeList}
          // pageChangeHandler={pageChangeHandler}
          // usersPerPage={usersPerPage}
          // currentPage={currentPage}
          // searchValue={searchValue}
          // searchedDataPageCounts={searchedDataPageCounts}
          />
        </div>
      </div>

      <Modal
        size="xl"
        show={showPersonal}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Category
          </Modal.Title>
          <div className="rightAxe" onClick={() => setShowPersonal(false)}>
            <img src={Axe} alt="" />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    App *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    App Type *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="12">Admin Panel</option>
                    <option value="13">Primary App</option>
                    <option value="14">Secondary App</option>
                    <option value="15">Real Estate</option>
                    <option value="16">Social Media</option>
                    <option value="17">Transport &amp; Logistics</option>
                    <option value="18">Travel Booking</option>
                    <option value="19">Accounting</option>
                    <option value="20">Ecommerce</option>
                    <option value="22">Services</option>
                    <option value="23">Non-governmental Organization</option>
                    <option value="24">Chatbot App</option>
                    <option value="25">CRM</option>
                  </select>
                </div>
              </div>

              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    App Base
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Icon *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>
              </div>
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Domain *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="12">Education</option>
                    <option value="13">Food Delivery</option>
                    <option value="14">Lifestyle</option>
                    <option value="15">Real Estate</option>
                    <option value="16">Social Media</option>
                    <option value="17">Transport & Logistics</option>
                    <option value="18">Travel Booking</option>
                    <option value="19">Accounting</option>
                    <option value="20">Ecommerce</option>
                    <option value="22">Services</option>
                    <option value="23">Non-governmental Organization</option>
                    <option value="24">Chatbot App</option>
                    <option value="25">CRM</option>
                  </select>
                </div>
                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Category *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="37">AR, VR &amp; Games</option>
                    <option value="38">Digital Content</option>
                    <option value="39">Discovery App</option>
                    <option value="40">
                      International &amp; Language Learning
                    </option>
                    <option value="41">Online learning</option>
                    <option value="42">Skill Upgrade</option>
                    <option value="43">Steam Coding</option>
                    <option value="44">Tutoring</option>
                    <option value="60">General</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button className="btn-width saveBtn">Update</button>
          </div>
        </Form>
      </Modal>
      <Modal
        size="xl"
        show={showPersonaledit}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Features
          </Modal.Title>
          <div className="rightAxe" onClick={() => setShowPersonaledit(false)}>
            <img src={Axe} alt="" />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    App *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    App Type *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="12">Admin Panel</option>
                    <option value="13">Primary App</option>
                    <option value="14">Secondary App</option>
                    <option value="15">Real Estate</option>
                    <option value="16">Social Media</option>
                    <option value="17">Transport &amp; Logistics</option>
                    <option value="18">Travel Booking</option>
                    <option value="19">Accounting</option>
                    <option value="20">Ecommerce</option>
                    <option value="22">Services</option>
                    <option value="23">Non-governmental Organization</option>
                    <option value="24">Chatbot App</option>
                    <option value="25">CRM</option>
                  </select>
                </div>
              </div>

              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    App Base
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Icon *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>
              </div>
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Domain *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="12">Education</option>
                    <option value="13">Food Delivery</option>
                    <option value="14">Lifestyle</option>
                    <option value="15">Real Estate</option>
                    <option value="16">Social Media</option>
                    <option value="17">Transport & Logistics</option>
                    <option value="18">Travel Booking</option>
                    <option value="19">Accounting</option>
                    <option value="20">Ecommerce</option>
                    <option value="22">Services</option>
                    <option value="23">Non-governmental Organization</option>
                    <option value="24">Chatbot App</option>
                    <option value="25">CRM</option>
                  </select>
                </div>
                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Category *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="37">AR, VR &amp; Games</option>
                    <option value="38">Digital Content</option>
                    <option value="39">Discovery App</option>
                    <option value="40">
                      International &amp; Language Learning
                    </option>
                    <option value="41">Online learning</option>
                    <option value="42">Skill Upgrade</option>
                    <option value="43">Steam Coding</option>
                    <option value="44">Tutoring</option>
                    <option value="60">General</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => setShowPersonaledit(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-width saveBtn"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Features;
