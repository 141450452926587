import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { simpleGetCallWithErrorResponse,putMultipartWithAuthCall} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Loader from "../../Loader";
import { ToastMsg } from "../../TostMsg";


const OrgDetails = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [loading, setLoading] = useState(false)

  const [updateorg, setUpdateOrg] = useState({
    OrgId:'',
    OrgName: '',
    PhoneNo: '',
    AddressLine1: '',
    AddressLine2: '',
    CountryName: '',
    CityName: '',
    PinCode: '',
    Email: '',
    Fax: '',
    PoBoxNo: '',
    Website: '',
    LogoUrl: '',
    ContactPerson: '',
    State: '',

  })

  const [updateOrgImg, setUpdateOrgImg] = useState('')

  const getOrganisetionDetails = () => {
    setLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_ORGANISATION)
      .then((res) => {
        console.log(res.data);
        setLoading(false)
        setUpdateOrg(res?.json)
     
      })
      .catch((err) => {
        console.log(err);
      })
  }

  console.log("updateorg--->",updateorg)

  useEffect(() => {
    getOrganisetionDetails();
  }, [])

  useEffect(() => {
    // Update the image URL state whenever the organization details are fetched
    setUpdateOrgImg(updateorg.LogoUrl);
  }, [updateorg]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateOrg((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a URL for the selected file and update the state
      const newImageUrl = URL.createObjectURL(file);
      setUpdateOrgImg(newImageUrl);
    }
  };

  const OnupdateOraganetins = (e)=>{
    e.preventDefault()
    setLoading(true)

    const formData = new FormData();
    formData.append('Orgid',updateorg.OrgId)
    formData.append('OrgName',updateorg.OrgName)
    formData.append('PhoneNo',updateorg.PhoneNo)
    formData.append('AddressLine1',updateorg.AddressLine1)
    formData.append('AddressLine2',updateorg.AddressLine2)
    formData.append('CountryName',updateorg.CountryName)
    formData.append('CityName',updateorg.CityName)
    formData.append('PinCode',updateorg.PinCode)
    formData.append('Email',updateorg.Email)
    formData.append('Fax',updateorg.Fax)
    formData.append('PoBoxNo',updateorg.PoBoxNo)
    formData.append('Website',updateorg.Website)
    formData.append('LogoUrl',updateorg.LogoUrl)
    formData.append('ContactPerson',updateorg.ContactPerson)
    formData.append('State',updateorg.State)
    formData.append('PhotoPath',updateOrgImg)


    putMultipartWithAuthCall(ApiConfig.UPDATE_ORGANIZATIONS,formData)
    .then((res)=>{
      if(res.Success === true){
        ToastMsg('success',res.Message )
        setLoading(false)
        getOrganisetionDetails();
      }else{
        ToastMsg('error',res.json.Message )
        setLoading(false)

      }
      console.log(res.data)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)

    })

  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header active ">
                  Organization Details
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header">
                  Manage Department
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header">
                  Manage Designation
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header">
                  Manage Locations
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

        {
          loading?(
            <>
            <Loader/>
            </>
          ):(
            <div className="bg-white rounded-3 p-2">
            <h4 className="text-start">Organizatin Profile</h4>
            <hr />
            <form>
            <div className="row justify-content-center ">
            <div className="col-7">
                {/* Display the logo, either the original or the newly selected one */}
                <img
                  src={updateOrgImg}
                  alt="Organization Logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
                <br />
                <input type="file" onChange={handleFileChange} />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3 ">
                    Organization Name :
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control bg-white  css-13cymwt-control"
                  name='OrgName'
                  value={updateorg.OrgName}
                  onChange={handleChange}
                />
              </div>
  
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">Website :</label>
                </div>
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="Website"
                  value={updateorg?.Website}
                  onChange={handleChange}

                />
              </div>
  
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3 ">
                    Contact No :
                  </label>
                </div>
  
                <input type="number"
                  className="form-control bg-white css-13cymwt-control"
                  name="PhoneNo"
                  value={updateorg?.PhoneNo}
                  onChange={handleChange}

                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">
                    Contact Person :
                  </label>
                </div>
  
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="ContactPerson"
                  value={updateorg?.ContactPerson}
                  onChange={handleChange}

                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">Email :</label>
                </div>
  
                <input type="email"
                  className="form-control bg-white css-13cymwt-control"
                  name="Email"
                  value={updateorg?.Email}
                  onChange={handleChange}

  
                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">Fax :</label>
                </div>
  
                <input type="number"
                  className="form-control bg-white css-13cymwt-control"
                  name="Fax"
                  value={updateorg?.Fax}
                  onChange={handleChange}

  
                />
              </div>
            </div>
  
            <h4 className="text-start">Address Details</h4>
            <hr />
  
            <div className="row justify-content-center ">
              {/* <div className="col-7 d-flex">
                <div className="col">
                  <label className="form-label text-nowrap me-3">
                    Organization Name :
                  </label>
                </div>
  
                <input
                  type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="OrgName"
                  value={updateorg?.OrgName}
                  onChange={handleChange}

                />
              </div> */}
  
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">
                    Address line1 :
                  </label>
                </div>
  
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="AddressLine1"
                  value={updateorg?.AddressLine1}
                  onChange={handleChange}
                  
  
                />
              </div>
  
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">
                    Address line2 :
                  </label>
                </div>
  
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="AddressLine2"
                  value={updateorg?.AddressLine2}
                  onChange={handleChange}

                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">City :</label>
                </div>
  
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="CityName"
                  value={updateorg?.CityName}
                  onChange={handleChange}
  
                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">State :</label>
                </div>
  
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="State"
                  value={updateorg?.State}
                  onChange={handleChange}

                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">Country :</label>
                </div>
  
                <input type="text"
                  className="form-control bg-white css-13cymwt-control"
                  name="CountryName"
                  value={updateorg?.CountryName}
                  onChange={handleChange}

                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">Pincode :</label>
                </div>
  
                <input type="number"
  
                  className="form-control bg-white css-13cymwt-control"
                  name="PinCode"
                  value={updateorg?.PinCode}
                  onChange={handleChange}

                />
              </div>
              <div className="col-7 d-flex">
                <div className="col-4">
                  <label className="form-label text-nowrap me-3">
                    Po Box No :
                  </label>
                </div>
  
                <input type="number"
                  className="form-control bg-white css-13cymwt-control"
                  name="PoBoxNo"
                  value={updateorg?.PoBoxNo}
                  onChange={handleChange}

                />
              </div>
              <div className="col-2 w-100 text-center">
                <button className="allocateionBtn" onClick={OnupdateOraganetins}>Update</button>
              </div>
            </div>
          </form>
          </div>
          )
        }
    </div>
     
   
  );
};
export default OrgDetails;
