import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { Accordion, Nav, Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import Multiselect from 'multiselect-react-dropdown';
import MultipleValueTextInput from 'react-multivalue-text-input';

import Loader from '../Loader';
import Beatloader from '../../sharedComponent/Beatloader';

const Meetings = () => {
  const { callsLoading, sidebar, callHystory, socket, suggetions } = useContext(AppContext);
  const [state, setState] = useState(suggetions.map(suggetion => {
    return { id: suggetion.EmployeeId, name: suggetion.Email && suggetion.Email }
  }))
  const [selectedValue, setSelectedValue] = useState([])
  const [loading, setLoading] = useState(false)
  const [oguests, setOguests] = useState([])

  const [meetingDetails, setmeetingDetails] = useState({ title: "" })
  const onSelect = (selected) => {
    setSelectedValue(selected)
  }
  const onRemove = (removed) => {
    setSelectedValue(selectedValue.filter((single) => { return single.name != removed.name }))
  }
  useEffect(() => {

    if (socket) {
      socket.on('create-meeting', (data) => {
        setLoading(false)
        setmeetingDetails({ title: "", start_time: "", end_time: "" })
        setSelectedValue([])


      })
    }
  }, [socket])

  const handleSubmit = async (e) => {
    console.log(selectedValue);
    setLoading(true)
    socket.emit('create-meeting', {
      guests: selectedValue.map((single) => { return single.name }),
      oguests: oguests,
      title: meetingDetails.title,
      start_date: meetingDetails.start_time,
      end_date: meetingDetails.end_time,
      reminder_time: meetingDetails.reminder_time
    })
  }

  return (
    <div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div className="container ">
        <div className="right-chat-section2">
          <form className=''>
            <div className="row">

              <div class="form-group col-lg-6 my-2">
                <label for="exampleInputEmail1">Meeting Title</label>
                <input type="text" onChange={(e) => { setmeetingDetails({ ...meetingDetails, title: e.target.value }) }} value={meetingDetails.title} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter meeting title" />
              </div>
              <div className="col-md-6"></div>
              <div class="form-group col-lg-4 my-2">
                <label for="exampleInputEmail1">Start time</label>
                <input type="datetime-local" class="form-control" value={meetingDetails.start_time} onChange={(e) => { setmeetingDetails({ ...meetingDetails, start_time: e.target.value }) }} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

              </div>
              <div class="form-group col-lg-4 my-2">
                <label for="exampleInputEmail1">End time</label>
                <input type="datetime-local" class="form-control" value={meetingDetails.end_time} onChange={(e) => { setmeetingDetails({ ...meetingDetails, end_time: e.target.value }) }} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

              </div>

              <div class="form-group col-lg-4 my-2">
                <label for="exampleInputEmail1">Notification Reminder(before)</label>
                <select class="form-control " value={meetingDetails.reminder_time}
                  onChange={(e) => { setmeetingDetails({ ...meetingDetails, reminder_time: e.target.value }) }}
                >


                  <option selected value="5">5 min</option>
                  <option value="10">10 min</option>
                  <option value="20">20 min</option>
                  <option value="30">30 min</option>
                </select>
              </div>


              <div class="form-group col-md-6 my-3">
                <label for="exampleInputPassword1">Guests</label>
                <Multiselect
                  options={state} // Options to display in the dropdown
                  //selectedValues={selectedValue} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  showArrow={true}
                />
              </div>
              <div class="form-group col-md-6 my-3">
                <label for="exampleInputPassword1">Other Guests</label>
                <MultipleValueTextInput
                  class="form-control"
                  onItemAdded={(item, allItems) => setOguests(allItems)}
                  onItemDeleted={(item, allItems) => setOguests(allItems)}
                  name="item-input"
                  placeholder="separate Mails with COMMA or ENTER."
                />
              </div>
              <div className="col-md-12">

                {
                  loading ? <Beatloader /> : <button onClick={() => handleSubmit()} className="custoBtn my-3">Create</button>

                }
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
  )
}

export default Meetings