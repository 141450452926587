import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";

export default function GoogleAnalytics() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Google Analytics</h6>
        </div>
        <div className="col-12 pushmain ">
          <div className="row  ">
            <div className=" col-md-6   ">
              <div className="WeeklyMain   ">
                <div class="row">
                  <div class="">
                    <h4>Step by step working</h4>
                    <ul class="timeline">
                      <li>
                        <a target="" href="">
                          Quick Start
                        </a>

                        <p>
                          The Google Analytics Data API v1 gives you
                          programmatic access to Google Analytics 4 (GA4) report
                          data.
                        </p>
                      </li>
                      <li>
                        <a href="#">Enable the API</a>

                        <p>
                          Access your Google Analytics dashboard from here
                          click. Select your project and move to APIs and
                          Services section ib left tab and open Enable APIs and
                          Services. Click on + sign to enable apis. Enable the
                          google analytics api, reporting api, data api.
                        </p>
                      </li>
                      <li>
                        <a href="#">Create Service Account</a>

                        <p>
                          After this go to the admin section at the bottom of
                          left tab, and select service account option. Now click
                          on + sign to create service account, add the required
                          fields like name. Then grant this account role and
                          done the process.
                        </p>
                      </li>

                      <li>
                        <a href="#">Download Credentials File</a>

                        <p>
                          Go to the APIs and services section and look for
                          credentials section. Scroll down and select the
                          service account that you have created. Select key tab
                          and add new key from ADD KEY option and select JSON
                          option. Downloading will get started for
                          credentials.json file.
                        </p>
                      </li>

                      <li>
                        <a href="#">Add User to Analytics Dashboard</a>

                        <p>
                          Go to the admin section at the bottom of left tab,
                          select property access management and from + option on
                          right top corner add user with the email address
                          associated with client_email in the downloaded json
                          file. From same tab you can access property settings
                          from where you can get property id.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-6   ">
              <div className="WeeklyMain   ">
                <div class="row">
                  <div class="">
                    <h4>Google Analytics Settings</h4>

                    <div className="my-3">
                      <label>Property ID</label>
                      <input
                        //   value={newPassword}
                        placeholder=""
                        type="text"
                        className="tasKCategory1"
                        style={{
                          //   fontSize: "14px",
                          height: "38px",
                          borderRadius: "10px",
                        }}
                        //   onChange={(e) => {
                        //     setNewPassword(e.target.value);
                        //   }}
                      />
                    </div>

                    <div className="my-3">
                      <label>Credentials JSON File </label>
                      <input placeholder="" type="file" style={{}} />
                    </div>

                    <div className="my-3 buttonpushmain">
                      <Button className="custoBtnForCrmCall">Submit</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
