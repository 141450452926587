import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { simpleGetCallWithToken, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";

const ProjectNotAssigned = () => {

    const { sidebar, setSidebar, Dark, setDark, userData,permissions } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [projectData, setProjectData] = useState([]);

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(5);

    const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);

    const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
    };

    const searchedDataPageCounts =
        projectData &&
        projectData.length &&
        projectData.filter((item) =>
            item.ProjectName?.toLowerCase().includes(searchValue)
        );
  
        const [excelLoading, setExcelLoading] = useState(false);

        const downloadExcelFile = (e) => {
          setExcelLoading(true);
          e.preventDefault();
          simpleGetCallWithErrorResponse(ApiConfig.GET_PROJECTNOTASSIGNED_EXPORT)
            .then((res) => {
              console.log("Download File Response", res);
              if (res.error) {
                console.log("Error response", res);
              } else {
                if (res.json.Success === true) {
                  setExcelLoading(false);
                  let data = res.json.Data;
                  FileSaver.saveAs(
                    "data:" + data.ContentType + ";base64," + data.FileContents,
                    data.FileDownloadName
                  );
                } else {
                  console.log("res", res);
                  setExcelLoading(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        };
      

    const fetchProjectList = () => {
        setLoading(true);
        simpleGetCallWithToken(ApiConfig.GET_PROJECTNOTASSIGNED_LIST)
            .then((res) => {
                if (res.Success === true) {
                    setLoading(false);
                    setProjectData(res.Data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useState(() => {
        fetchProjectList()
    }, [])

    const listProjectnotAssign =
        <table>
            <thead>
                <tr className="firstTabelRow">
                    <th>Sr.no</th>
                    <th>Employee Name</th>
                    <th>Last Assigned Project</th>
                    <th>Assign Project</th>
                </tr>
            </thead>
            <tbody>
                {
                    projectData?.slice(startIndex, endIndex).map((data, index) =>
                        <tr className="table-row-custom">
                            <td>{startIndex + index + 1}</td>
                            <td>
                                {/* <Link to="/ProjectAssignment"> */}
                                {data.EmployeeName}
                                {/* </Link> */}
                            </td>
                            <td>{data.ProjectName}</td>
                            <td> <Link to="/ProjectAssignment">
                                <button type="button"className="btn-infoaf">Assign</button>
                                </Link>                               
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>

    const searchlistProjectnotAssign = 
        <table>
            <thead>
                <tr className="firstTabelRow">
                    <th>Sr.no</th>
                    <th>Employee Name</th>
                    <th>Last Assigned Project</th>
                    <th>Assign Project</th>
                </tr>
            </thead>
            <tbody>
                {
                    projectData?.filter(item => item.EmployeeName.toLowerCase().includes(searchValue.toLowerCase()))
                    .slice(startIndex, endIndex).map((data, index) =>
                        <tr className="table-row-custom">
                            <td>{startIndex + index + 1}</td>
                            <td>
                                {/* <Link to="/ProjectAssignment"> */}
                                {data.EmployeeName}
                                {/* </Link> */}
                            </td>
                            <td>{data.ProjectName}</td>
                            <td>
                                <Link to="/ProjectAssignment">
                                    Assign
                                </Link>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">List of Employee - Project Not Assigned</h6>
                    </div>
                    <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
                        <div className="role-table-header">
                            <div className="search-input-wrapper">
                                <input
                                    type="text"
                                    placeholder="Search by Employee Name"
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        setCurrentPage(0);
                                    }}
                                />
                                {Dark === "lightMode" ? (
                                    <img src={searchicon} alt="" />
                                ) : (
                                    <img src={ic_search_dark} alt="" />
                                )}
                            </div>
                            <div className="entries-wrapper">
                                <p>Entries per page</p>
                                <select
                                    className="form-select select-drop-icon"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                        setUsersPerPage(e.target.value);
                                        setCurrentPage(0);
                                    }}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                        </div>
                        <div className="import-export-btn-wrapper mt-4">
                            {
                                permissions&&permissions['Project Not Assigned Export']&&
                            <button>
                                {excelLoading ? (
                                    <ExcelLoader />
                                ) : (
                                    <img
                                        src={ic_excel}
                                        onClick={(e) => downloadExcelFile(e)}
                                        alt=""
                                    />
                                )}
                            </button>
                           }
                       
                    </div>
                    </div>
                    <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                        {loading ? (
                            <Loader />
                        ) : (
                            projectData && projectData.length > 0 ?
                                <>
                                {searchValue === "" ? listProjectnotAssign : searchlistProjectnotAssign}
                                </>
                                :
                                <NoData />
                        )
                        }
                    </div>
                </div>
                <Pagination
                    data={projectData}
                    pageChangeHandler={pageChangeHandler}
                    usersPerPage={usersPerPage}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    searchedDataPageCounts={searchedDataPageCounts}
                />
            </div>
        </>
    )
}

export default ProjectNotAssigned;
