import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import Eyes from "../../assets/images/ic_show_eyes.svg";
import Modal from 'react-bootstrap/Modal';

const IssueCard = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);
    const [show, setShow] = useState(false);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main">
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2">Issue Card</h6>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="search-input-wrapper">
                                <input
                                    type="text"
                                    placeholder="Search"
                                // onChange={(e) => {
                                //     setSearchValue(e.target.value)
                                // }}
                                />
                                <img src={searchicon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="mainTableBox">
                        <div className="insideTable">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Date Of joining</th>
                                        <th>Location</th>
                                        <th>Card Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Dom</td>
                                        <td>Nick</td>
                                        <td>01-01-2020</td>
                                        <td>Kerla</td>
                                        <td>123456789</td>
                                        <td onClick={() => setShow(true)}>
                                            <img src={Eyes} alt="" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Dom</td>
                                        <td>Nick</td>
                                        <td>01-01-2020</td>
                                        <td>Kerla</td>
                                        <td>123456789</td>
                                        <td onClick={() => setShow(true)}>
                                            <img src={Eyes} alt="" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Dom</td>
                                        <td>Nick</td>
                                        <td>01-01-2020</td>
                                        <td>Kerla</td>
                                        <td>123456789</td>
                                        <td onClick={() => setShow(true)}>
                                            <img src={Eyes} alt="" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <Modal centered show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Issue Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalCOntent">
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum
                            quisquam minima eos nam sint repellendus ipsa corporis modi, unde
                            quaerat molestias quibusdam dolores, asperiores inventore sed molestiae,
                            error maxime id!
                        </p>
                        <button className="SendBtn">Send</button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}


export default IssueCard;
