import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import BookIcon from "../../assets/images/ic_excel.svg";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import "../../assets/css/crmphonebook/Agent.scss";
import { Form, Tab, Tabs } from "react-bootstrap";
import Pagination from "../../sharedComponent/Pagination";
import NoData from "../../sharedComponent/NoData";
export default function CallHistory() {
  const [selectedRows, setSelectedRows] = useState(10);
  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const {} = useContext(AppContext);
  const [key, setKey] = useState("all");

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Non Call History</h6>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input type="text" placeholder="Search here" />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper mt-0 cus-mb">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  value={selectedRows}
                  onChange={handleSelectChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>
          <Form noValidate id="crm-call-form">
            <div id="tabsBelow" className="mt-4">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                // className="mb-0"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="all" title="All">
                  <div
                    className="table-wrapper"
                    id="scrol-tblNtable-wrapper-af"
                  >
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Call Time</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="table-row-custom">
                          <td colSpan={3}>
                            <NoData />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Pagination />
                  </div>
                </Tab>
                <Tab eventKey="inboundCalls" title="Inbound Calls">
                  <div
                    className="table-wrapper"
                    id="scrol-tblNtable-wrapper-af"
                  >
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th scope="col">Name / Phone Number</th>
                          <th scope="col">Type</th>
                          <th scope="col">Call Date</th>
                          <th scope="col">Call Start Time</th>
                          <th scope="col">Call End Time</th>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Call Status</th>
                          <th scope="col">Call Recording</th>
                          <th scope="col">MAKE CALL</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="table-row-custom">
                          <td colSpan={9}>
                            <NoData />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Pagination />
                  </div>
                </Tab>
                <Tab eventKey="outboundCalls" title="Outbound Calls">
                  <Tab.Content>
                    <div
                      className="table-wrapper"
                      id="scrol-tblNtable-wrapper-af"
                    >
                      <table>
                        <thead>
                          <tr className="firstTabelRow">
                            <th scope="col">Name / Phone Number</th>
                            <th scope="col">Type</th>
                            <th scope="col">Call Date</th>
                            <th scope="col">Call Start Time</th>
                            <th scope="col">Call End Time</th>
                            <th scope="col">Agent Name</th>
                            <th scope="col">Call Status</th>
                            <th scope="col">Call Recording</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="table-row-custom">
                            <td colSpan={9}>
                              <NoData />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Pagination />
                    </div>
                  </Tab.Content>
                </Tab>
              </Tabs>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
