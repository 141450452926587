import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import ImgVector from "../../assets/images/img_vector.svg";
import { Button, Carousel, Dropdown } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import optionIcon from "../../assets/images/Editac.svg";
import deleteIcon from "../../assets/images/DelectAc.svg";
import ReactPaginate from "react-paginate";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ApiConfig from "../../api/ApiConfig";
import {
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../api/ApiServices";
import g20 from "../../assets/images/g20.png";
import Loader from "../Loader";
import { ToastMsg } from "../TostMsg";
import { getDateInBySplitYYYYMMDD } from "../../common";
import ButtonLoader from "../../sharedComponent/ButtonLoader";
import BootstrapSpinner from "../../sharedComponent/BootstrapSpinner";
import NoData from "../../sharedComponent/NoData";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Pagination from "../../sharedComponent/Pagination";

const MyAnnouncement = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  //Create Modal
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose = () => setShow(false);
  const [employName, setEmployName] = useState([]);
  const [branchName, setBranchName] = useState([]);
  const [teamName, setTeamName] = useState([]);
  const [announceMentId, setAnnounceMentId] = useState();
  const [addAnnouncment, setAddAnnouncment] = useState({
    Title: "",
    AnnouncementDate: "",
    AssignToBranchId: 0,
    Message: "",
    PriorityId: "",
    AttachmentPath: null,
    AttachmentPreview: "",
    OrgId: Number(userData.OrgId),
    CreatedBy: Number(userData.EmpId),
    ViewCount: 0,
    AssignToTeamId: "",
    AssignToEmployeeId: "",
  });

  const [error, setError] = useState({
    AttachmentPath: "",
  });
  const [allannList, setAllannList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    allannList &&
    allannList.length &&
    allannList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  console.log("addAnnouncment,", addAnnouncment);

  const getBranchApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.ORGANIZATION_BRANCH,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
        LocationId: Number(userData.LocationId),
      })
    )
      .then((res) => {
        setBranchName(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTeamApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.ORGANIZATION_TEAM,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setTeamName(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEmployeeApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.EMPLOEE_NAME_LIST,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setEmployName(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Edit Modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // Delete Modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Privew Model
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow4(false);
  const handleShow5 = () => setShow4(true);

  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => {
    setShow6(false);
  };
  const handleShow6 = () => {
    setShow6(true);
    setShow4(false);
  };

  const [loading, setLoading] = useState(false);
  const [mloading, setMLoading] = useState(false);
  const [announcment, setAnnouncment] = useState([]);

  useEffect(() => {
    getAnnounctmentApi();
    getBranchApi();
    getTeamApi();
    getEmployeeApi();
  }, []);

  const getAnnounctmentApi = (id) => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.MY_ANNOUNCEMENTS,
      JSON.stringify({
        EmpId: !id ? Number(userData.EmpId) : 0,
        OrgId: !id ? Number(userData.OrgId) : 0,
        annoucementId: !id ? 0 : id,
      })
    )
      .then((res) => {
        setLoading(false);
        setAllannList(res.json.Data);

        // let announcmentData = res.json.Data[0];
        // setAddAnnouncment({
        //   Title: announcmentData.Title,
        //   AnnouncementDate: announcmentData?.Date
        //     ? announcmentData?.Date
        //     : new Date(),
        //   AssignToBranchId: announcmentData.AssignBranchId,
        //   Message: announcmentData.Description,
        //   PriorityId: announcmentData.PriorityId,
        //   AttachmentPath: announcmentData.ImagePath,
        //   AttachmentPreview: announcmentData.AttachmentPreview,
        //   AssignToTeamId: announcmentData.AsssignTeamId,
        //   AssignToEmployeeId: announcmentData.AssignEmployeeId,
        //   ViewCount: announcmentData.ViewCount,
        //   AnnouncementId: announcmentData.AnnouncementId,
        //   CreatedBy: Number(userData.EmpId),
        //   OrgId: Number(userData.OrgId),
        //   OrgId: Number(userData.OrgId),
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAnouncementApi = () => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.DELETE_ANNOUNCEMENTS,
      JSON.stringify({
        AnnoucementId: announceMentId,
      })
    )
      .then((res) => {
        if (res.json.Success === true) {
          setLoading(false);
          ToastMsg("success", res.json.Message);
          getAnnounctmentApi();
        } else {
          console.log("Error response", res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPreViewCountApi = (id) => {
    setMLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.PREVIEW_ANNOUNCEMENTS,
      JSON.stringify({
        AnnoucementId: id,
        // EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setMLoading(false);
        setAnnouncment(res.json.Data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const AddorUpdateAnnouncmentApi = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let fromdata = new FormData();

      fromdata.append("Title", addAnnouncment.Title);
      fromdata.append("AnnouncementDate", addAnnouncment.AnnouncementDate);
      fromdata.append("AssignToBranchId", addAnnouncment.AssignToBranchId);
      fromdata.append("Message", addAnnouncment.Message);
      fromdata.append("PriorityId", addAnnouncment.PriorityId);
      fromdata.append("AttachmentPreview", addAnnouncment.AttachmentPreview);
      fromdata.append("OrgId", addAnnouncment.OrgId);
      fromdata.append("ViewCount", addAnnouncment.ViewCount);
      fromdata.append("AssignToTeamId", addAnnouncment.AssignToTeamId);
      fromdata.append("CreatedBy", addAnnouncment.CreatedBy);
      fromdata.append("AttachmentPath", addAnnouncment.AttachmentPath);
      setBtnLoading(true);
      multipartPostCallWithErrorResponse(
        ApiConfig.CRATE_ANNOUNCEMENT,
        fromdata
      ).then((res) => {
        if (res.json.Message === true) {
          ToastMsg("success", res.json.Message);
        } else {
          ToastMsg("error", res.json.Message);
        }
        //ToastMsg("success", res.json.Message);
        getAnnounctmentApi();
        handleClose1();
        setBtnLoading(false);
      });
    }
    setValidated(true);
  };

  const UpdateAnnouncmentApi = async (e, id) => {
    e.preventDefault();
    setBtnLoading(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let fromdata = new FormData();

      fromdata.append("AnnoucementId", addAnnouncment.AnnouncementId);
      fromdata.append("Title", addAnnouncment.Title);
      fromdata.append("AnnouncementDate", addAnnouncment.AnnouncementDate);
      fromdata.append("AssignToBranchId", addAnnouncment.AssignToBranchId);
      fromdata.append("Message", addAnnouncment.Message);
      fromdata.append("PriorityId", addAnnouncment.PriorityId);
      fromdata.append("AttachmentPath", addAnnouncment.AttachmentPath);
      fromdata.append("AttachmentPreview", addAnnouncment.AttachmentPreview);
      fromdata.append("OrgId", addAnnouncment.OrgId);
      fromdata.append("CreatedBy", addAnnouncment.CreatedBy);
      fromdata.append("ViewCount", addAnnouncment.ViewCount);
      fromdata.append("AssignToTeamId", addAnnouncment.AssignToTeamId);
      fromdata.append("AssignToEmployeeId", addAnnouncment.AssignToEmployeeId);
      multipartPostCallWithErrorResponse(
        ApiConfig.UPDATE_ANNOUNCEMENTS,
        fromdata
      ).then((res) => {
        ToastMsg("success", res.json.Message);
        getAnnounctmentApi();
        handleClose7();
        handleClose();
        setBtnLoading(false);
      });
    }
    setValidated(true);
  };

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Announcemnet</h6>
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                setAddAnnouncment({
                  Title: "",
                  AnnouncementDate: "",
                  AssignToBranchId: 0,
                  Message: "",
                  PriorityId: "",
                  AttachmentPath: null,
                  AttachmentPreview: "",
                  OrgId: Number(userData.OrgId),
                  CreatedBy: Number(userData.EmpId),
                  ViewCount: 0,
                  AssignToTeamId: 0,
                  AssignToEmployeeId: 0,
                });
                handleShow1();
                setValidated(false);
              }}
            >
              + Add{" "}
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header  accoridance">
              <div className="search-input-wrapper allAcordiance">
                <Link to="/AllAnnouncement">
                  <button className="all-anc-btn-sub-header">
                    All Announcement
                  </button>
                </Link>
              </div>
              <div className="entries-wrapper myAccordiance">
                <Link to="/MyAnnouncement">
                  <button className=" active my-anc-btn-sub-header">
                    My Announcement
                  </button>
                </Link>
              </div>
              <div className="role-table-header">
                <div className="entries-wrapper">
                  <p>Entries per page</p>
                  <select
                   style={{width:'200px'}}
                    className="form-select select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setUsersPerPage(e.target.value);
                      setCurrentPage(0);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25" selected>
                      25
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div
            className="table-wrapper custom-table-width"
            id="scrol-tblNtable-wrapper-af"
          >
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr.no</th>
                    <th>Select Team / Project</th>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Priority</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {allannList && allannList.length > 0 ? (
                    allannList
                      .slice(startIndex, endIndex)
                      .map((allnt, index) => {
                        return (
                          <tr
                            className="table-row-custom"
                            key={"sdfdfsd" + index}
                          >
                            <td> {startIndex + index + 1}</td>
                            <td>{allnt.AssignedToName}</td>
                            <td>{allnt.Title}</td>
                            <td
                              onClick={handleShow4}
                              className="pointerCurcer12"
                            >
                              <img
                                style={{ width: "30px", height: "30px" }}
                                src={
                                  !allnt.ImagePath
                                    ? ImgVector
                                    : allnt.ImagePath
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                      allnt.ImagePath
                                    : allnt.ImagePath &&
                                      URL.createObjectURL(allnt.ImagePath)
                                }
                                alt=""
                                onClick={(e) =>
                                  getPreViewCountApi(allnt.AnnouncementId)
                                }
                              />{" "}
                            </td>
                            <td
                              onClick={handleShow4}
                              className="pointerCurcer12 break-ln-spaces"
                            >
                              {allnt.Description}
                            </td>
                            <td>{allnt.CreatedBy}</td>
                            <td>{allnt.Date.slice(0, 9)} </td>
                            <td
                              className={
                                allnt.Priority == "High"
                                  ? "status-red"
                                  : allnt.Priority == "Medium"
                                  ? "status-yellow"
                                  : allnt.Priority == "Low"
                                  ? "status-green"
                                  : ""
                              }
                            >
                              {" "}
                              {allnt.Priority === "High" ? (
                                <span>High</span>
                              ) : allnt.Priority === "Medium" ? (
                                <span>Medium</span>
                              ) : allnt.Priority === "Low" ? (
                                <span>Low</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="custom-padding-for-btn">
                              <Button
                                className="edit-btn"
                                onClick={() => {
                                  console.log("allnt,", allnt);
                                  setAddAnnouncment({
                                    Title: allnt.Title,
                                    AnnouncementDate: allnt?.Date
                                      ? allnt?.Date
                                      : new Date(),
                                    AssignToBranchId: allnt.AssignBranchId,
                                    Message: allnt.Description,
                                    PriorityId: allnt.PriorityId,
                                    AttachmentPath: allnt.ImagePath,
                                    AttachmentPreview: allnt.AttachmentPreview,
                                    AssignToTeamId: allnt.AsssignTeamId,
                                    AssignToEmployeeId: allnt.AssignEmployeeId,
                                    ViewCount: allnt.ViewCount,
                                    AnnouncementId: allnt.AnnouncementId,
                                    CreatedBy: Number(userData.EmpId),
                                    OrgId: Number(userData.OrgId),
                                    OrgId: Number(userData.OrgId),
                                  });

                                  //   {
                                  //     "AnnouncementId": 5,
                                  //     "AssignedToName": "Aurangabad- Branch",
                                  //     "Title": "tittle",
                                  //     "Description": "fdsdfsdf",
                                  //     "CreatedBy": "Dr. Naziya",
                                  //     "Date": "4/19/2023 12:00:00 AM +05:30",
                                  //     "Priority": "High",
                                  //     "ImagePath": "MicrosoftTeams-image (10).png_2023.png",
                                  //     "PriorityId": 1,
                                  //     "AssignEmployeeId": 0,
                                  //     "AsssignTeamId": 64,
                                  //     "AssignBranchId": 887,
                                  //     "ViewCount": 2,
                                  //     "AttachmentPreview": "sdfsdfs"
                                  // }
                                  // getAnnounctmentApi(allnt.AnnouncementId);

                                  handleShow7();
                                }}
                              >
                                <img src={optionIcon} alt="" />
                              </Button>
                              <Button
                                className="delete-btn"
                                onClick={() => {
                                  handleShow3();
                                  setAnnounceMentId(allnt.AnnouncementId);
                                }}
                              >
                                {" "}
                                <img src={deleteIcon} alt="" className="mx-2" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <>
                      <tr className="ErrorDataNotFound">
                        <td colSpan={6}>
                          <NoData />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <Pagination
            data={allannList}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={searchedDataPageCounts}
          />
        </div>
      </div>

      {/* Create News and Blogs Modal Start*/}
      <Modal
        size="xl"
        className="create-news-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header className="create-header">
          <Modal.Title className="create-title">
            Create Announcement
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow1(false)} />
          </div>
        </Modal.Header>
        <Form
          noValidate
          validated={validated}
          onSubmit={AddorUpdateAnnouncmentApi}
        >
          <Modal.Body className="">
            <div className="announcement-modal-body">
              <div className="row">
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Title</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        Title: e.target.value,
                      });
                    }}
                    value={addAnnouncment.Title}
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">Please Enter Title</div>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Date</label>
                  <input
                    type="date"
                    placeholder="0"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AnnouncementDate: e.target.value,
                      });
                    }}
                    value={
                      addAnnouncment.AnnouncementDate.length &&
                      new Date(addAnnouncment.AnnouncementDate)
                        .toISOString()
                        .slice(0, 10)
                    }
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">
                    Please select Announcemnet Date
                  </div>
                </div>
             
                
                {/* <div class="col-md-6 row pe-0"> */}
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Assign To Branch
                    </label>
                    <select
                      disabled={
                        addAnnouncment.AssignToTeamId ||
                        addAnnouncment.AssignToEmployeeId
                          ? true
                          : false
                      }
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToBranchId: e.target.value,
                        });
                      }}
                      value={addAnnouncment.AssignToBranchId}
                      required
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      {branchName &&
                        branchName.map((branch, index) => {
                          return (
                            <option
                              key={"343dsd" + index}
                              value={branch.LocationId}
                            >
                              {branch.LocationName}
                            </option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">Please select Branch</div>
                  </div>
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Assign To Team
                    </label>
                    <select
                      disabled={
                        addAnnouncment.AssignToBranchId ||
                        addAnnouncment.AssignToEmployeeId
                          ? true
                          : false
                      }
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToTeamId: e.target.value,
                        });
                      }}
                      value={addAnnouncment.AssignToTeamId}
                      // required
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      {teamName &&
                        teamName.map((team, index) => {
                          return (
                            <option key={"teadd" + index} value={team.TeamId}>
                              {team.TeamName}
                            </option>
                          );
                        })}
                    </select>
                    {/* <div className="invalid-feedback">Please select Team</div> */}
                  </div>
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Assign To Members
                    </label>
                    <select
                      disabled={
                        addAnnouncment.AssignToBranchId ||
                        addAnnouncment.AssignToTeamId
                          ? true
                          : false
                      }
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToEmployeeId: e.target.value,
                        });
                      }}
                      value={addAnnouncment.AssignToEmployeeId}
                      // required
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      {employName &&
                        employName.map((name, index) => {
                          return (
                            <option key={"dfgve" + index} value={name.Id}>
                              {name.FullName}
                            </option>
                          );
                        })}
                    </select>
                    {/* <div className="invalid-feedback">Please select Member</div> */}
                  </div>
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Priority
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          PriorityId: e.target.value,
                        });
                      }}
                      required
                      value={addAnnouncment.PriorityId}
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      <option value="1">High</option>
                      <option value="2">Medium</option>
                      <option value="3">Low</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select priority
                    </div>
                  </div>
                  <div class="col-md-6 pe-0 mb-2">
                    <label class="mb-2 text-grey">Attachment</label>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      placeholder="Browse Files.."
                      class="form-control tasKCategory1"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        // Check if a file is selected
                        if (selectedFile) {
                          console.log("Selected file size:", selectedFile.size);
                          // Check if the file size exceeds 4MB
                          if (selectedFile.size > 4 * 1024 * 1024) {
                            console.log("File size exceeds 4MB");
                            setError({
                              ...error,
                              AttachmentPath:
                                "File size should not exceed 4MB.",
                            });
                          } else {
                            // Get the file extension
                            const fileExtension = selectedFile.name
                              .split(".")
                              .pop()
                              .toLowerCase();
                            // Define allowed extensions
                            const allowedExtensions = ["png", "jpg", "jpeg"];
                            // Check if the file extension is allowed
                            if (!allowedExtensions.includes(fileExtension)) {
                              setError({
                                ...error,
                                AttachmentPath:
                                  "Only .png, .jpg, and .jpeg files are allowed.",
                              });
                            } else {
                              setAddAnnouncment({
                                ...addAnnouncment,
                                AttachmentPath: e.target.files[0],
                              });
                              setError({
                                ...error,
                                AttachmentPath: "",
                              });
                            }
                          }
                        }
                        // setAddAnnouncment({
                        //   ...addAnnouncment,
                        //   AttachmentPath: e.target.files[0],
                        // });
                      }}
                      required
                      // value={addAnnouncment?.AttachmentPath.length && addAnnouncment?.AttachmentPath}
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                   />
                    {error.AttachmentPath.length > 0 && (
                      <div className="NotFoundLeft">{error.PhotoPath}</div>
                    )}
                  </div>
                  <div className="invalid-feedback">Please select file</div>
                {/* </div> */}
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Attachment Preview</label>
                  <textarea
                    name=""
                    id=""
                    cols="25"
                    rows="8"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AttachmentPreview: e.target.value,
                      });
                    }}
                    required
                    value={addAnnouncment.AttachmentPreview}
                  ></textarea>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Message/Description</label>
                  {/* <input type="text" placeholder="0" class="form-control tasKCategory1" /> */}
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="8"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        Message: e.target.value,
                      });
                    }}
                    value={addAnnouncment.Message}
                    required
                    style={{
                      fontSize: "14px",
                      height: "100px",
                      border: "1px solid #ced4da",
                    }}
                  ></textarea>
                  <div className="invalid-feedback">
                    Please Enter Message or Description
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          {/* <div className="lineSeperate"></div>
        <Modal.Footer className="create-footer">
          <div className="col-md-6 d-flex justify-content-end align-items-end">
            <div className="mainBtnsSub">
              <button className="btn-width cancelBtn mx-4" onClick={handleClose}>Cancel</button>
              <button className="btn-width saveBtn">
              Submit
              </button>
            </div>
          </div>
        </Modal.Footer> */}

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              className="btn-width cancelBtn mx-3"
              type="submit"
              onClick={handleClose1}
            >
              Cancel
            </button>
            <button
              disabled={btnLoading ? true : false}
              className="btn-width saveBtn"
            >
              {btnLoading ? (
                <div
                  class="spinner-border text-light"
                  role="status"
                  style={{
                    marginTop: "5px",
                    marginLeft: "45px",
                    width: "1.3rem",
                    height: "1.3rem",
                  }}
                ></div>
              ) : (
                "Add"
              )}
            </button>
          </div>
        </Form>
      </Modal>
      {/* Edit News and Blogs Modal Start*/}
      <Modal
        size="xl"
        className="create-news-modal"
        show={show7}
        onHide={handleClose7}
        centered
      >
        <Modal.Header className="create-header" closeButton>
          <Modal.Title className="create-title">
            Update Announcement
          </Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={UpdateAnnouncmentApi}>
          <Modal.Body className="">
            <div className="announcement-modal-body">
              <div className="row">
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Title</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        Title: e.target.value,
                      });
                    }}
                    value={addAnnouncment.Title}
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">Please Enter Title</div>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Date</label>
                  <input
                    type="date"
                    placeholder="0"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AnnouncementDate: e.target.value,
                      });
                    }}
                    value={
                      addAnnouncment.AnnouncementDate.length &&
                      getDateInBySplitYYYYMMDD(addAnnouncment.AnnouncementDate)
                    }
                    // value={"2023-04-18"}
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">
                    Please select Announcemnet Date
                  </div>
                </div>
               
                {/* <div class="col-md-6 row pe-0"> */}
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Assign To Branch
                    </label>
                    <select
                      class="form-select tasKCategory1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToBranchId: e.target.value,
                        });
                      }}
                      value={addAnnouncment.AssignToBranchId}
                      required
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      {branchName &&
                        branchName.map((branch, index) => {
                          return (
                            <option
                              key={"343dsd" + index}
                              value={branch.LocationId}
                            >
                              {branch.LocationName}
                            </option>
                          );
                        })}
                    </select>
                    <div className="invalid-feedback">Please select Branch</div>
                  </div>
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Assign To Team
                    </label>
                    <select
                      class="form-select tasKCategory1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToTeamId: e.target.value,
                        });
                      }}
                      value={addAnnouncment.AssignToTeamId}
                      // required
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      {teamName &&
                        teamName.map((team, index) => {
                          return (
                            <option key={"teadd" + index} value={team.TeamId}>
                              {team.TeamName}
                            </option>
                          );
                        })}
                    </select>
                    {/* <div className="invalid-feedback">Please select Team</div> */}
                  </div>
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Assign To Members
                    </label>
                    <select
                      class="form-select tasKCategory1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToEmployeeId: e.target.value,
                        });
                      }}
                      value={addAnnouncment.AssignToEmployeeId}
                      // required
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      {employName &&
                        employName.map((name, index) => {
                          return (
                            <option key={"dfgve" + index} value={name.Id}>
                              {name.FullName}
                            </option>
                          );
                        })}
                    </select>
                    {/* <div className="invalid-feedback">Please select Member</div> */}
                  </div>
                  <div className="col-lg-6 pe-0 mb-2">
                    <label for="" class="mb-2 text-grey">
                      Priority
                    </label>
                    <select
                      class="form-select tasKCategory1"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          PriorityId: e.target.value,
                        });
                      }}
                      required
                      value={addAnnouncment.PriorityId}
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="">Please select</option>
                      <option value="1">High</option>
                      <option value="2">Medium</option>
                      <option value="5">Low</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select priority
                    </div>
                  </div>
                  <div class="col-md-6 pe-0 mb-2">
                    <label class="mb-2 text-grey">Attachment</label>
                    <input
                      type="file"
                      placeholder="Browse Files.."
                      class="form-control tasKCategory1"
                      onChange={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AttachmentPath: e.target.files[0],
                        });
                      }}
                      value={addAnnouncment.ImagePath}
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    />
                  </div>
                  {/* {
                      !addAnnouncment.ImagePath.length || !addAnnouncment.ImagePath.name &&
                      <div className="invalid-feedback">Please select file</div>

                    } */}
                {/* </div> */}
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Attachment Preview</label>
                  <textarea
                    name=""
                    id=""
                    cols="25"
                    rows="8"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AttachmentPreview: e.target.value,
                      });
                    }}
                    required
                    value={addAnnouncment.AttachmentPreview}
                     style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>

          {/* <div className="lineSeperate"></div>
        <Modal.Footer className="create-footer">
          <div className="col-md-6 d-flex justify-content-end align-items-end">
            <div className="mainBtnsSub">
              <button className="btn-width cancelBtn mx-4" onClick={handleClose}>Cancel</button>
              <button className="btn-width saveBtn">
              Submit
              </button>
            </div>
          </div>
        </Modal.Footer> */}

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              disabled={btnLoading ? true : false}
            >
              {btnLoading ? <ButtonLoader /> : "Submit"}
            </button>
          </div>
        </Form>
      </Modal>
      {/* Delete News and Blogs Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Announcement
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              deleteAnouncementApi();
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Delete News and Blogs Modal End */}

      {/* Privew Model */}

      <Modal
        size="xl"
        className=""
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header className="announcement-header" closeButton>
          <Modal.Title className="announcement-title">Announcement</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          {mloading ? (
            <div style={{ margin: "15%" }}>
              <BootstrapSpinner />
            </div>
          ) : (
            <div className="Announcement">
              <div className="top">
                <h3>Welcome to {announcment.Title}</h3>
                <p className="text-grey">
                  Posted on {announcment.Date?.slice(0, 9)}{" "}
                  <b className="right-divider">{announcment.CreatedBy}</b>{" "}
                  <span>{announcment.ViewCount}+ Views</span>
                </p>
              </div>

              <img
                alt=""
                onClick={handleShow6}
                className="AnnouncImg"
                src={
                  !announcment.ImagePath
                    ? g20
                    : announcment.ImagePath
                    ? ApiConfig.BASE_URL_FOR_IMAGES + announcment.ImagePath
                    : announcment.ImagePath &&
                      URL.createObjectURL(announcment.ImagePath)
                }
              ></img>

              <div className="discribtion">
                <p className="qution">{announcment.Description}</p>
                {console.log("Description ---> ", announcment.Description)}
                {console.log("ViewCount ---> ", announcment.ViewCount)}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="delete-footer">
          {/* <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="primary" className="sbmt-btn" onClick={handleClose3}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        size="xxl"
        className="carsousel-img-model"
        show={show6}
        onHide={handleClose6}
        dialogClassName="modal-90w"
        centered
      >
        {/* <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <div>Views @</div>
            <div>99+</div>
          </div>

        </Modal.Header> */}
        <Modal.Body className=" imagecarosla p-0">
          <div className=" p-2">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=First slide&bg=373940"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=Second slide&bg=282c34"
                  alt="Second slide"
                />

                <Carousel.Caption>
                  <h3>Second slide label</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=Third slide&bg=20232a"
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="delete-footer">
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleClose6}
          >
            Close
          </Button>
          <Button variant="primary" className="sbmt-btn" onClick={handleClose4}>
            Delete
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default MyAnnouncement;
