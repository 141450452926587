import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  putMultipartWithAuthCallWithErrorResponse,
  putWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

const ComplianceSetting = () => {
  const items = [
    { name: "Lead", description: "Santeep Kumar" },
    { name: "Enquiry Id", description: "None" },
    {
      name: "App Category",
      description: "VT Pricing Calc - Contract Based Solution",
    },
    {
      name: "Category Text",
      description: "VT Pricing Calc - Contract Based Solution",
    },
    { name: "App Platform", description: "vehicletracking.qa" },
    { name: "Cross Platform Status", description: "None" },
    { name: "Which Cross Platform", description: "None" },
    { name: "Grand Cost", description: "39500 QAR" },
    { name: "Favourite", description: "None" },
    { name: "Enquiry Status", description: "untagged" },
    { name: "Status Since", description: "June 6, 2024, 1:21 p.m." },
    { name: "Enq Through", description: "app OR web" },
    { name: "Enq From", description: "vehicletracking-utm" },
    { name: "Proposal Shared Status", description: "None" },
    { name: "Proposal Shared Date", description: "None" },
    { name: "Alias", description: "None" },
    { name: "Country Code", description: "None" },
    { name: "Choosen Approach", description: "None" },
    { name: "Choosen Devices", description: "None" },
    { name: "Choosen Screen Count", description: "None" },
    { name: "Choosen Login Methods", description: "leads.LoginMethods.None" },
    { name: "Choosen Features", description: "leads.ChoosenFeatures.None" },
    { name: "Choosen Language", description: "None" },
    { name: "Choosen Admin", description: "None" },
    { name: "Admin Efforts", description: "None" },
    { name: "Choosen Webapp", description: "None" },
    { name: "Secondary App", description: "None" },
    { name: "Screen Efforts", description: "None" },
    { name: "Webservices", description: "None" },
    { name: "Webapp Efforts", description: "None" },
    { name: "Ios Efforts", description: "None" },
    { name: "Android Efforts", description: "None" },
    { name: "Crossplatform Efforts", description: "None" },
    { name: "Secondary App Efforts", description: "None" },
    { name: "Features Efforts", description: "None" },
    { name: "Database Efforts", description: "None" },
    { name: "Grand Total Efforts", description: "None" },
    { name: "Approx Cost", description: "None" },
    { name: "Timeline Month", description: "None" },
    { name: "User Comment", description: "None" },
    { name: "Enq Date", description: "June 6, 2024, 7:51 a.m." },
    { name: "Mail Date", description: "June 6, 2024, 7:51 a.m." },
    { name: "Created On", description: "June 6, 2024, 1:21 p.m." },
    { name: "Enquiry Untagged Count", description: "0" },
    { name: "Enquiry Unqualified Count", description: "0" },
    { name: "Enquiry Cold Count", description: "0" },
    { name: "Enquiry Warm Count", description: "0" },
    { name: "Enquiry Hot Count", description: "0" },
    { name: "Enquiry Won Count", description: "0" },
  ];

  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [complianceList, setComplianceList] = useState([]);
  const [complianceObj, setComplianceObj] = useState([]);

  const getComplianceSettings = () => {
    simpleGetCallWithToken(ApiConfig.GET_COMPLIANCE_SETTINGS)
      .then((res) => {
        console.log("res", res.Data);
        if (res.Success === true) {
          setComplianceList(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComplianceSettings();
  }, []);

  //   const handleSelect = (val) => {
  //     console.log("handleSelect", val);
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ComplianceSetting: [...complianceObj],
    };
    console.log("data", data);
    putWithAuthCall(ApiConfig.SET_COMPLIANCE_SETTINGS, JSON.stringify(data))
      .then((res) => {
        if (res.Success === true) {
          swal(res.Message);
          // setComplianceObj([]);
          // setComplianceObj([
          //   {
          //     ComplianceType: "",
          //     SettingsId: 0,
          //     SenderId: 0,
          //   },
          // ]);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Enquiry Details</h6>
          </div>
          <div className="EnquiryCard">
            <ul style={{ listStyleType: "none", paddingTop: 20 }}>
              {items.map((item, index) => (
                <li key={index}>
                  <div className="row col-md-12 ml-2 mt-2">
                    <p className="col-md-4 mt-2">{item.name}</p>:
                    <p className="col-md-4"> {item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplianceSetting;
