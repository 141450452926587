import "../../assets/css/tasklist.scss";
import "../../assets/css/tasklist.scss";
import ApiConfig from "../../api/ApiConfig";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Check from "../../assets/images/check.svg";
import Form from "react-bootstrap/Form";
import Loader from "../Loader";
import Modal from "react-bootstrap/Modal";
import NoData from "../../sharedComponent/NoData";
import React, { useContext, useEffect, useState } from "react";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import moment from "moment";
import optionIcon from "../../assets/images/bx_edit.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Button, Dropdown, ModalFooter } from "react-bootstrap";
import { toast } from "react-toastify";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import { daysCount, getDateddmmyyyy } from "../../common";
import { AppContext } from "../../context/AppContext";
import { notifyError } from "../../toaster/notify";
import { ToastMsg } from "../TostMsg";

const Leave = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  //States
  const [subListLoader, setSubListLoder] = useState(false);
  const [subapproveList, setSubApproveList] = useState(false);
  const [showSublistModal, setShowSublistModal] = useState(false);
  const [hide, setHide] = useState(true);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  console.log("leaveTypes,", leaveTypes);
  const [leaveStructure, setLeaveStructure] = useState([]);
  const [leaveData, setLeaveData] = useState({
    EmployeeId: Number(userData.EmpId),
    NoOfLeave: 0,
    LeaveFrom: "",
    LeaveTo: "",
    LeaveTypeId: "",
    LeaveApplicationDate: new Date(),
    EntryDate: new Date(),
    EntryBy: Number(userData.EmpId),
    Status: "",
    Reason: "",
    IsHalfDay: false,
    LeaveTypeName: "",
    OrgId: Number(userData.OrgId),
    ApprovedBy: 0,
  });
  console.log("leaveData", leaveData);

  const [btnLoader, setBtnLoader] = useState(false);

  //States

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setHide(false);

  const onChangeHandler = (e) => {
    const leaveDataObj = { ...leaveData };
    leaveDataObj[e.target.name] = e.target.value;
    setLeaveData({ ...leaveDataObj });
  };

  useEffect(() => {
    getLeaveList();
  }, []);

  // LEAVE LIST API
  const getLeaveList = async () => {
    let body = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    setLoading(true);
    const response = await PostCallWithErrorResponse(
      ApiConfig.GET_LEAVE_LIST,
      JSON.stringify(body)
    );
    setLoading(false);
    setLeaveList(response.json.Data ? response.json?.Data : []);
  };

  // LEAVE TYPE API
  const getLeaveTypes = async () => {
    let body = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    const response = await PostCallWithErrorResponse(
      ApiConfig.GET_LEAVE_TYPE,
      JSON.stringify(body)
    );
    setLeaveTypes(response.json.Data ? response.json?.Data : []);
  };

  // LEAVE STRUCTURE API
  const getLeaveStructure = async () => {
    let body = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    const response = await PostCallWithErrorResponse(
      ApiConfig.GET_LEAVE_STRUCTURE,
      JSON.stringify(body)
    );
    setLeaveStructure(response.json.Data ? response.json?.Data : []);
  };

  // APPLY LEAVE API
  const applyLeaveApiCall = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (leaveData.LeaveFrom > leaveData.LeaveTo) {
      notifyError("End date should be greater than start date");
    } else {
      setBtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.APPLY_LEAVE,
        JSON.stringify(leaveData)
      )
        .then((res) => {
          if (res.json && res.json?.Success === true) {
            setBtnLoader(false);
            ToastMsg("success", res.json.Message);
            handleClose(false);
            setShow(false);
          } else {
            ToastMsg("error", res.json.Message);
            setBtnLoader(false);
            setShow(false);
          }
          getLeaveList();
        })
        .catch((err) => {
          setBtnLoader(false);
          console.log(err);
          setShow(false);
        });
    }
    setValidated(true);
  };

  const getApproveSubList = (LeaveApplicationId) => {
    setSubListLoder(true);
    PostCallWithErrorResponse(
      ApiConfig.GET_APPROVE_SUBlIST,
      JSON.stringify({
        leaveId: LeaveApplicationId,
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        console.log(res);
        if (res.json.Success) {
          setSubListLoder(false);
          setSubApproveList(res.json.Data);
        } else {
          setSubListLoder(false);
        }
      })
      .catch((err) => {
        setSubListLoder(false);
        console.log(err);
      });
  };

  const ErrorNotify = () => {
    toast.error("Please Select End Date Should be Greater Than Start Date");
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          {permissions && permissions["Create Leave"] && (
            <div className="sub-header-role ">
              <h6 className="mt-2">Apply Leave</h6>
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  getLeaveTypes();
                  getLeaveStructure();
                  handleShow();
                  setValidated(false);
                  setLeaveData({
                    EmployeeId: Number(userData.EmpId),
                    NoOfLeave: 0,
                    LeaveFrom: "",
                    LeaveTo: "",
                    LeaveTypeId: "",
                    LeaveTypeName: "",
                    LeaveApplicationDate: new Date(),
                    EntryDate: new Date(),
                    EntryBy: Number(userData.EmpId),
                    Status: "",
                    Reason: "",
                    IsHalfDay: false,
                    OrgId: Number(userData.OrgId),
                    ApprovedBy: 0,
                  });
                }}
              >
                + Apply Leave{" "}
              </button>
            </div>
          )}

          <div className="table-wrapper table-wrapper-2">
            {loading ? (
              <Loader />
            ) : (
              <table>
                <tr className="firstTabelRow">
                  <th>Sr. no</th>
                  {/* <th>Employee Name</th> */}
                  <th>Leave Type</th>
                  <th>Leave From</th>
                  <th>Leave To</th>
                  <th>No.of Leaves</th>
                  <th>Leave Apply Date</th>
                  <th>Status</th>
                </tr>
                {leaveList?.length > 0 ? (
                  leaveList &&
                  leaveList.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="table-row-custom"
                        onClick={() => {
                          setShowSublistModal(true);
                          getApproveSubList(item.LeaveApplicationId);
                        }}
                      >
                        <td className="table-row-custom-td">{index + 1}</td>
                        {/* <td>{item.Full} </td> */}
                        <td>{item.Desc} </td>
                        <td>{getDateddmmyyyy(item.LeaveFrom)}</td>
                        <td>{getDateddmmyyyy(item.LeaveTo)}</td>
                        <td className="table-row-custom-td">
                          {item.NoOfLeave}
                        </td>
                        <td>{getDateddmmyyyy(item.LeaveApplicationDate)}</td>
                        <td
                          className={
                            item.Status === "A"
                              ? "status-green"
                              : item.Status === "P"
                              ? "status-label-grey"
                              : item.Status === "R"
                              ? "status-red"
                              : "status-label-grey"
                          }
                        >
                          {item.Status === "A" ? (
                            <span>Approve</span>
                          ) : item.Status === "P" ? (
                            <span> Pending</span>
                          ) : item.Status === "R" ? (
                            <span>Reject</span>
                          ) : (
                            ""
                          )}
                        </td>
                        {/* <td className="status-green">Active (On Priority)</td> */}
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr className="ErrorDataNotFound">
                      <td colSpan={6}>
                        <NoData />
                      </td>
                    </tr>
                  </>
                )}
              </table>
            )}
          </div>
        </div>
      </div>
      {/* // Add Modal Task */}
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="add-task-modal-title"
          >
            Apply Leave
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate validated={validated} onSubmit={applyLeaveApiCall}>
          <Modal.Body>
            <div className="mainFormSection">
              <div className="row" id="tite-alig">
                <div className="col-lg-6">
                  <div className="col-md-12">
                    <label htmlFor="" className="mb-2" style={{fontSize:"14px"}}>
                      Leave Type
                    </label>
                    <select
                      className="form-select tasKCategory1 dropDOwnImgh"
                      aria-label="Default select example"
                      name="LeaveTypeId"
                      onChange={(e) => {
                        let LeaveTypeId = e.target.value.split(",")[0];
                        let LeaveTypeName = e.target.value.split(",")[1];
                        setLeaveData({
                          ...leaveData,
                          LeaveTypeId: Number(LeaveTypeId),
                          LeaveTypeName: LeaveTypeName,
                        });
                      }}
                      required
                    >
                      <option value="">Please select</option>
                      {leaveTypes &&
                        leaveTypes.map((item) => {
                          return (
                            <option value={Object.values(item)}>
                              {item.LeaveTypeName}
                            </option>
                          );
                        })}
                    </select>
                    <Form.Control.Feedback type="invalid">
                      Leave type is required
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6 dateSelectIcon">
                        <label htmlFor="" className="mb-2" style={{fontSize:"14px"}}>
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control tasKCategory1"
                          name="LeaveFrom"
                          min={new Date().toISOString().split("T")[0]}
                          onChange={(e) => {
                            setLeaveData({
                              ...leaveData,
                              LeaveFrom: new Date(e.target.value)
                                .toISOString()
                                .toString(),
                              NoOfLeave: daysCount(
                                new Date(e.target.value),
                                leaveData.LeaveTo
                              ),
                            });
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Start date is required
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-6">
                        <label htmlFor="" className="mb-2 " style={{fontSize:"14px"}}>
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control tasKCategory1"
                          name="LeaveTo"
                          onChange={(e) => {
                            setLeaveData({
                              ...leaveData,
                              LeaveTo: new Date(e.target.value)
                                .toISOString()
                                .toString(),
                              NoOfLeave: daysCount(
                                leaveData.LeaveFrom,
                                new Date(e.target.value)
                              ),
                              IsHalfDay:
                                leaveData.LeaveFrom > 1
                                  ? !e.target.checked
                                  : e.target.checked,
                            });
                          }}
                          min={
                            leaveData.LeaveFrom
                              ? leaveData.LeaveFrom.split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          End date is required
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <label className="mb-2" style={{fontSize:"14px"}}>
                          Number of Leaves
                        </label>
                        <input
                          disabled
                          type="text"
                          placeholder="0"
                          className="form-control tasKCategory1 disabledColor"
                          required
                          value={leaveData.NoOfLeave ? leaveData.NoOfLeave : ""}
                        />
                        <Form.Control.Feedback type="invalid">
                          Number of leaves is required
                        </Form.Control.Feedback>
                      </div>

                      <div className="col-md-6">
                        <div className="switch-wrapper form-check form-switch">
                          <label htmlFor="" className="mb-2" style={{fontSize:"14px"}}>
                            Half Day
                          </label>
                          <input
                            class="form-check-input mb-2 switch-whitebtn"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={leaveData.IsHalfDay}
                            disabled={leaveData.NoOfLeave <= 1 ? false : true}
                            onChange={(e) => {
                              setLeaveData({
                                ...leaveData,
                                IsHalfDay: e.target.checked,
                                NoOfLeave: e.target.checked
                                  ? 0.5
                                  : leaveData.LeaveFrom && leaveData.LeaveTo
                                  ? daysCount(
                                      leaveData.LeaveFrom,
                                      leaveData.LeaveTo
                                    )
                                  : 0,
                              });
                            }}
                          ></input>
                        </div>

                        <select
                          className="form-select tasKCategory1"
                          aria-label="Default select example"
                          disabled={leaveData.IsHalfDay ? false : true}
                        >
                          <option value="">Please select</option>
                          <option value="Fisrt Half">First Half</option>
                          <option value="Second Half">Second Half</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label htmlFor="" className="mb-2" style={{fontSize:"14px"}}>
                      Reason
                    </label>
                    <textarea
                      className="form-control "
                      style={{ border: "1px solid #53b7e8" }}
                      rows="4"
                      placeholder="Enter a valid reason..."
                      name="Reason"
                      onChange={onChangeHandler}
                      required
                    ></textarea>
                    <Form.Control.Feedback type="invalid">
                      Please enter reason
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row bottomLeaveSec">
                    <div className="col-md-12 mb-2">
                      <label htmlFor="" className="mb-2" style={{fontSize:"14px"}}>
                        Leave Structure
                      </label>
                      <div className="leave-structure">
                        <table className="table table-hover table-stripped ">
                          <thead className="modal-footer-table-head">
                            <tr >
                              <th>Leave Type</th>
                              <th>Leave(s) Taken</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody className="modal-footer-table-body">
                            {leaveStructure && leaveStructure.length > 0 ? (
                              leaveStructure.map((item) => {
                                return (
                                  <tr
                                    key={item.EmployeeLeaveId}
                                    className="row-hover modal-footer-table-body-tr"
                                  >
                                    <td>{item.LeaveTypeName}</td>
                                    <td>{item.LeavesTaken}</td>
                                    <td>{item.Balance}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr className="ErrorDataNotFound">
                                <td colSpan={6}>Data Not Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12 mb-2">
                      <label htmlFor="" className="mb-2 " style={{fontSize:"14px"}}>
                        Authorized Person
                      </label>
                      <div className="leave-structure">
                        <table className="table table-hover table-stripped">
                          <thead className="modal-footer-table-head">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Designation</th>
                            </tr>
                          </thead>
                          <tbody className="modal-footer-table-body">
                            <tr className="modal-footer-table-body-tr">
                              <td>Mr. Sameer Kutty</td>
                              <td>CEO</td>
                            </tr>
                            <tr>
                              <td className="modal-footer-table-body-tr">
                                Haris Pulliyil
                              </td>
                              <td>Product Manager</td>
                            </tr>
                            <tr className="modal-footer-table-body-tr">
                              <td>Shabana Shaikh</td>
                              <td>Accountant / HR</td>
                            </tr>
                            <tr>
                              <td className="modal-footer-table-body-tr">
                                Dr.Naziya Pathan
                              </td>
                              <td>center Head</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <div class="lineSeperate"></div>
          <Modal.Footer>
            <div className="col-md-6 d-flex justify-content-end align-items-end">
              <div className="mainBtnsSub">
                <button className="btn-width cancelBtn mx-4" onClick={handleClose}>Cancel</button>
                <button className="btn-width saveBtn">
                  Apply
                </button>
              </div>
            </div>
          </Modal.Footer> */}

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              className="btn-width cancelBtn mx-3"
              onClick={handleClose}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              disabled={btnLoader ? true : false}
            >
              {btnLoader ? (
                <div
                  class="spinner-border text-light"
                  role="status"
                  style={{
                    marginTop: "5px",
                    width: "1.3rem",
                    height: "1.3rem",
                    marginLeft: "40%",
                  }}
                ></div>
              ) : (
                "Apply"
              )}
            </button>
          </div>
        </Form>
      </Modal>

      {/* =================================== SubList Modal ===================================== */}

      <Modal
        size="lg"
        show={showSublistModal}
        onHide={() => setShowSublistModal(false)}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="add-task-modal-title"
          >
            Leave Details
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShowSublistModal(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Modal.Body>
          <div className="leaveDetails-table-wrapper">
            <table>
              <thead>
                <tr className="lineSeperate">
                  <th>Sr. No.</th>
                  <th>Date</th>
                  <th>Reason</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {subListLoader ? (
                  <div
                    class="spinner-border text-info"
                    role="status"
                    style={{ marginLeft: "200%", marginTop: "20px" }}
                  ></div>
                ) : subapproveList && subapproveList.length > 0 ? (
                  subapproveList.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1} </td>
                        <td>{getDateddmmyyyy(item.LeaveDate)} </td>
                        <td>{item.Reason} </td>
                        <td
                          className={
                            item.Status === "A"
                              ? "status-green"
                              : item.Status === "P"
                              ? "status-label-grey"
                              : item.Status === "R"
                              ? "status-red"
                              : "status-label-grey"
                          }
                        >
                          {item.Status === "A" ? (
                            <span>Approve</span>
                          ) : item.Status === "P" ? (
                            <span> Pending</span>
                          ) : item.Status === "R" ? (
                            <span>Reject</span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={6}>Data Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>

        {/* <div className="lineSeperate"></div>
            <div
              className="d-flex justify-content-end align-items-center mainBtnsSub"
              id="leave-detail-btns"
            >
              <button
                disabled={buttonLoader ? true : false}
                className="btn-width cancelBtn mx-3"
                onClick={(e) => {
                  setShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-width saveBtn"
                onClick={(e) => {
                  // approveAndRejectAPI();
                }}
              >
                {true ? btnLoader : "Approve"}
              </button>
            </div> */}
      </Modal>
    </>
  );
};

export default Leave;
