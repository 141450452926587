import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function Beatloader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20%",
        marginBottom: "20%",
      }}
    >
      <BeatLoader color="#1890ff" className="spinner" />
    </div>
  );
}

export default Beatloader;
