import { min } from "lodash";
import { useEffect, useState } from "react";

export const getDateddmmyyyy = (inputDate) => {
  let formDate = new Date(inputDate);
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  let formatedDate = `${date < 10 ? 0 : ""}${date}-${
    month < 10 ? 0 : ""
  }${month}-${year}`;
  return formatedDate;
};

export const getDateYYYYMMDD = (inputDate) => {
  let formDate = new Date(inputDate);
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  var formatedDate = "";
  return (formatedDate = `${year}/${month}/${date}`);
};

export const getDateDashYYYYMMDD = (inputDate) => {
  let formDate = new Date(inputDate);
  console.log("formDate", formDate);
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  var formatedDate = "";
  return (formatedDate = `${year}-${month}-${date}`);
};

export const getDateDashDDMMYYYY = (inputDate) => {
  let formDate = new Date(inputDate);
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  var formatedDate = `${date < 10 ? 0 : ""}${date}-${
    month < 10 ? 0 : ""
  }${month}-${year}`;
  return formatedDate && formatedDate;
};

export const getDatemmddyyyy = (date) => {
  let formDate = new Date(date);
  var date1 = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  var formatedDate = "";
  return (formatedDate = `${month}/${date1}/${year}`);
};

export const getDDateddmmyyyy = (inputDate) => {
  var splittedDate = inputDate.slice(0, 10).split("/").join("-");

  let formDate = new Date(splittedDate);
  var formatedDate = "";
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  return (formatedDate = `${date < 10 ? "0" : ""}${date}-${
    month < 10 ? "0" : ""
  }${month}-${year}`);
};

export const getDDateyyyymmdd = (inputDate) => {
  var splittedDate = inputDate.slice(0, 10).split("/").join("-");
  let formDate = new Date(splittedDate);
  var formatedDate = "";
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();
  formatedDate = `${year}-${month < 10 ? 0 : ""}${month}-${
    date < 10 ? 0 : ""
  }${date}`;
  return formatedDate;
};

export const getDateddmmyyyyInDash = (inputDate) => {
  var splittedDate = inputDate.slice(0, 10).split("/").join("-");

  let formDate = new Date(splittedDate);
  var formatedDate = "";
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  return (formatedDate = `${date}-${month}-${year}`);
};

export const getDDateYYYYMMDD = (inputDate) => {
  let formDate = new Date(inputDate);
  var formatedDate = "";
  var date = formDate.getDate();
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();

  return (formatedDate = `${year}-${month < 10 ? 0 : ""}${month}-${
    date < 10 ? 0 : ""
  }${date}`);
};

export const getDateInBySplitYYYYMMDD = (inputDate) => {
  var splittedDate = inputDate.slice(0, 10).split(" ").join("-");

  let formDate = new Date(splittedDate);
  console.log("formDate,", formDate);
  var formatedDate = "";
  var date = formDate.getDate();
  console.log("date,", date);
  var month = formDate.getMonth() + 1;
  var year = formDate.getFullYear();
  formatedDate = `${year}-${month < 10 ? 0 : ""}${month}-${
    date < 10 ? 0 : ""
  }${date}`;
  console.log("formatedDate,", formatedDate);
  return formatedDate;
};

// =============================================== Time ========================================= //

export const convertTimeInMinutes = (time) => {
  var totalMinuts = time.getHours() * 60 + time.getMinutes();
  return totalMinuts;

  // var arr = time.split(":").map((item) => Number(item));
  // var minuts = arr[0] * 60 + arr[1];
  // return minuts;
};

export const totalEnteredTime = (totalEnteredTime) => {
  var splittedArr = totalEnteredTime.split(":");
  var totalMinutes = Number(splittedArr[0]) * 60 + Number(splittedArr[1]);

  var hours = totalMinutes / 60;
  var rhours = Math.floor(hours);
  var minute = (hours - rhours) * 60;
  var rminutes = Math.round(minute);

  let res = `${rhours < 10 ? 0 : ""}${rhours}:${
    rminutes < 10 ? 0 : ""
  }${rminutes}`;

  return res;
};

export const minutesInHors = (minutes) => {
  var hours = minutes / 60;
  var rhours = Math.floor(hours);
  var minute = (hours - rhours) * 60;
  var rminutes = Math.round(minute);

  let res = `${rhours < 10 ? 0 : ""}${rhours}:${
    rminutes < 10 ? 0 : ""
  }${rminutes}`;

  return res;
};

export const setWorkHrs = (enteredTime) => {
  var hours = enteredTime?.getHours();
  var minutes = enteredTime?.getMinutes();
  var totalTime = `${hours}:${minutes}`;
  return totalTime;
};

export const convertMinutesInTime = (time) => {
  var splittedStr = time.split(":");
  let dt = new Date();
  dt.setHours(Number(splittedStr[0]), Number(splittedStr[1]));
  return dt;
};

export const displayTime = (minutes) => {
  var hours = minutes / 60;
  var rhours = Math.floor(hours);
  var minute = (hours - rhours) * 60;
  var rminutes = Math.round(minute);

  let dt = new Date();
  dt.setHours(rhours, rminutes);
  return {
    hours: dt.getHours(),
    minutes: dt.getMinutes(),
  };
};

export const punchedTime = (time) => {
  let splittedArr = time ? (time + "").split(".") : "00:00";
  let hours = Number(splittedArr[0]);
  let minutes = splittedArr[1] ? splittedArr[1] : "00";
  let convertedMintes = minutes?.includes("0") ? minutes : `${minutes}${0}`;
  let punchedTime = `${hours < 10 ? 0 : ""}${hours}:${convertedMintes}`;
  // let punchedTime = `${hours < 10 ? 0 : ""}${hours}:${minutes < 10 ? 0 : ""}${minutes}`
  return time !== 0 ? punchedTime : "";
};

// ========================================= Week Days =========================================== //

export const getWeekDay = (day) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let weekDay = weekday[new Date(day).getDay()];

  return weekDay;
};

// ================================ Count days for Leaves ====================================== //
export const daysCount = (date1, date2) => {
  let diffDays = 0;
  let startDate = new Date(date1);
  let EndDate = new Date(date2);
  while (startDate <= EndDate) {
    const dayOfWeek = startDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      diffDays++;
    }
    startDate.setDate(startDate.getDate() + 1);
  }
  return diffDays;
};

// ================================ Count days for Project Calc ====================================== //
export const projectHoursCount = (date1, date2) => {
  let diffDays = 0;
  let startDate = new Date(date1);
  let EndDate = new Date(date2);
  while (startDate <= EndDate) {
    const dayOfWeek = startDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      diffDays++;
    }
    startDate.setDate(startDate.getDate() + 1);
  }
  return diffDays * 8;
};

// ======================== Entered Hours should not greater than Punched Hours ======================= //

export const isEnteredHours = (totalPunchedTime, totalEnteredTime) => {
  var splittedArr = (totalPunchedTime + "").split(".");
  var totalMinutes = Number(splittedArr[0]) * 60 + Number(splittedArr[1]);

  var trueOrFalse = totalEnteredTime > totalMinutes ? true : false;
  return trueOrFalse;
};

export const convertTimeIn24Format = (timeString) => {
  let splittedTime = timeString.split(":");
  let hours = Number(splittedTime[0]);
  let minutes = Number(splittedTime[1]);
  let timeFormat = splittedTime[2];
  let formattedHours = timeFormat.includes("PM") ? hours + 12 : hours;
  let time = `${formattedHours < 10 ? 0 : ""}${formattedHours}:${
    minutes < 10 ? 0 : ""
  }${minutes}`;
  let convertedTimeIn24Format = time ? time : "00:00";
  return convertedTimeIn24Format;
};

// FormData
export const sendAsFormData = (data) => {
  console.log("data", data);
  let formdata = new FormData();

  for (let key in data) {
    formdata.append(key, data[key]);
  }

  return formdata;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const convertTo12HourFormat = (timeStr) => {
  let [hours, minutes, seconds, ampm] = timeStr.split(":");
  let hour = parseInt(hours);

  let period = hour < 12 ? "AM" : "PM";

  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour -= 12;
  }

  return `${hour}:${minutes}:${seconds}:${period}`;
};

export const emojiReactions = [
  {
    label: "Like",
    id: "1",
    node: <div>👍</div>,
    emoji: "👍",
  },
  {
    label: "Smile",
    id: "2",
    node: <div>🙂</div>,
    emoji: "🙂",
  },
  {
    label: "laugh",
    id: "3",
    node: <div>🤣</div>,
    emoji: "🤣",
  },
  {
    label: "Surprised",
    id: "4",
    node: <div>😲</div>,
    emoji: "😲",
  },
  {
    label: "Thinking",
    id: "5",
    node: <div>🧐</div>,
    emoji: "🧐",
  },
  {
    label: "Heart",
    id: "6",
    node: <div>❤️</div>,
    emoji: "❤️",
  },
];

export const formatTime = (time) => {
  if (!time) return "";

  const [timePart, period] = time.split(" ");

  let [hours, minutes] = timePart.split(":");

  hours = parseInt(hours, 10);
  hours = hours % 12 || 12;
  minutes = parseInt(minutes, 10);

  // Determine if it's AM or PM
  const suffix = period.toLowerCase();

  return `${hours}:${minutes < 10 ? "0" + minutes : minutes} ${suffix}`;
};

export const formatTimes = (dateString) => {
  const date = new Date(dateString.replace(/T/, " "));

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return date.toLocaleTimeString("en-US", options);
};

export const countMessage = (arr, key) => {
  let t = 0;

  arr.forEach((i) => {
    t += Number(i[key]);
  });

  return (
    t > 0 && (
      <span
        class="badge"
        style={{
          position: "relative",
          marginLeft: "-2px",
        }}
      >
        {t}
      </span>
    )
  );
};

export const convertMinutesIntoHours = (minutes) => {
  let hours = Math.floor(minutes / 60);

  let remaining = minutes % 60;
  return `${hours} : ${remaining}`;
};

export const emailValidation = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

export const diffDate = (start, end) => {
  start = new Date(start);

  end = new Date(end);
  let Difference_In_Time = end.getTime() - start.getTime();

  return Math.round(Difference_In_Time / (1000 * 3600 * 24));
};
