import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader";
import { Link, useNavigate } from "react-router-dom";
import { simpleGetCallWithToken, simpleGetCallWithErrorResponse, } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Beatloader from "../../sharedComponent/Beatloader";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const UserLastLogin = () => {
  const [LastLogins, SetLastLogins] = useState([]);
  const [loading, setLoading] = useState(false);
  const { sidebar, Dark } = useContext(AppContext);
  //   Beatloader
  const fetchUserLastLogin = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_USER_LASTLOGIN)
      .then((res) => {
        if (res.Success == true) {
          SetLastLogins(res.Data);
        }
      })
      .catch((err) => console.log("catch error,", err))
      .finally(() => setLoading(false));
  };
  useState(() => {
    fetchUserLastLogin();
  }, []);

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.GET_USER_LASTLOGIN_EXPORT)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    LastLogins &&
    LastLogins.length &&
    LastLogins.filter((item) =>
      item.EmployeeName?.toLowerCase().includes(searchValue)
    );

  const displayLoggedInUser =
    Array.isArray(LastLogins) &&
    LastLogins.length > 0 &&
    LastLogins.slice(startIndex, endIndex).map((Data, index) => {
      return (
        <tr
          key={Data?.employeeId}
          className="table-row-custom"
          onClick={() => {
            navigate("/UserActivity", {
              state: { EmpId: Data?.employeeId },
            });
          }}
        >
          <td>{index + 1 + startIndex}</td>
          <td>{Data.EmployeeName}</td>
          <td>{Data.LastLoginTime}</td>
          <td>{Data.Device}</td>
        </tr>
      );
    });

  const displaySearchedLoggedInUser =
    Array.isArray(LastLogins) &&
    LastLogins.length > 0 &&
    LastLogins?.filter((item) =>
      item.EmployeeName?.toLowerCase().includes(searchValue.toLowerCase())
    )
      .slice(startIndex, endIndex)
      .map((Data, index) => {
        return (
          <tr
            key={Data?.employeeId}
            className="table-row-custom"
            onClick={() => {
              navigate("/UserActivity", {
                state: { EmpId: Data?.employeeId },
              });
            }}
          >
            <td>{index + 1 + startIndex}</td>
            <td>{Data.EmployeeName}</td>
            <td>{Data.LastLoginTime}</td>
            <td>{Data.Device}</td>
          </tr>
        );
      });

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">List of Employee - Last Login</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Employee Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>

              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
            <div className="import-export-btn-wrapper mt-4">
              <button>
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={ic_excel}
                    onClick={(e) => downloadExcelFile(e)}
                    alt=""
                  />
                )}
              </button>
            </div>
          </div>
          <div className="table-wrapper" style={{ height: "75vh" }}>
            {loading ? (
              //   <loading />
              <Beatloader />
            ) : LastLogins && LastLogins.length > 0 ? (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <></>
                    <th>Sr.no</th>
                    <th>Employee Name</th>
                    <th> Last Login </th>
                    <th> Device </th>
                  </tr>
                </thead>
                <tbody>
                  {searchValue
                    ? displaySearchedLoggedInUser
                    : displayLoggedInUser}
                  {/* {LastLogins.map((Data, index) => {
                    return (
                      <tr
                        key={Data?.employeeId}
                        className='table-row-custom'
                        onClick={() => {
                          navigate('/UserActivity', {
                            state: { EmpId: Data?.employeeId }
                          })
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{Data.EmployeeName}</td>
                        <td>{Data.LastLoginTime}</td>
                        <td>{Data.Device}</td>
                      </tr>
                    )
                  })} */}
                </tbody>
              </table>
            ) : (
              <NoData />
            )}
            <div style={{ bottom: 0 }}>
              {!loading && searchedDataPageCounts.length > usersPerPage && (
                <Pagination
                  data={LastLogins}
                  pageChangeHandler={pageChangeHandler}
                  usersPerPage={usersPerPage}
                  currentPage={currentPage}
                  searchValue={searchValue}
                  searchedDataPageCounts={searchedDataPageCounts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLastLogin;
