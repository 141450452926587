import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";

export default function CrmSendgrid() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Send Grid</h6>
        </div>
        <div className="col-12 pushmain ">
          <div className="row  ">
            <div className=" col-md-6   ">
              <div className="WeeklyMain   ">
                <div class="row">
                  <div class="">
                    <h4>Step by step working</h4>
                    <ul class="timeline">
                      <li>
                        <a target="" href="">
                          Sign up for a SendGrid account
                        </a>

                        <p>
                          When you sign up for a free SendGrid account, you'll
                          be able to send 100 emails per day forever. For more
                          account options, see pricing page.
                        </p>
                      </li>
                      <li>
                        <a href="#">Enable Two-factor authentication.</a>

                        <p>
                          Before setting up two-factor authentication, ensure
                          you have access to your account email address. You can
                          do this by visiting your Account Details and reviewing
                          your email address under Your Account Personal Info.
                          Once you have confirmed access to your account email
                          address, you may choose from 2 different verification
                          methods when using two-factor authentication: To set
                          up Two-Factor Authentication: Navigate to Settings,
                          and click Two-Factor Authentication. From this page,
                          you see an overview of your Two-Factor Authentication
                          settings, along with any settings that you have
                          created for credentialed users. Click Add Two-Factor
                          Authentication. Select either the Authy App or text
                          messages (SMS) as your means of authentication. Enter
                          your country code and phone number.
                        </p>
                      </li>
                      <li>
                        <a href="#">
                          Create and store a SendGrid API Key with Mail Send
                          Full Access permissions.
                        </a>

                        <p>
                          Unlike a username and password — credentials that
                          allow access to your full account — an API key is
                          authorized to perform a limited scope of actions. If
                          your API key is compromised, you can also cycle it
                          (delete and create another) without changing your
                          other account credentials.
                        </p>
                      </li>

                      <li>
                        <a href="#">Verify your Sender Identity</a>

                        <p>
                          To ensure our customers maintain the best possible
                          sender reputations and to uphold legitimate sending
                          behavior, we require customers to verify their Sender
                          Identities by completing Domain Authentication. A
                          Sender Identity represents your 'From' email
                          address—the address your recipients see as the sender
                          of your emails.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-6   ">
              <div className="WeeklyMain   ">
                <div class="row">
                  <div class="">
                    <h4>Sendgrid Settings</h4>

                    <div className="my-3">
                      <label>SENDGRID_API_KEY</label>
                      <input
                        //   value={newPassword}
                        placeholder=""
                        type="text"
                        className="tasKCategory1"
                        style={{
                          //   fontSize: "14px",
                          height: "38px",
                          borderRadius: "10px",
                        }}
                        //   onChange={(e) => {
                        //     setNewPassword(e.target.value);
                        //   }}
                      />
                    </div>

                    <div className="my-3">
                      <label>Sender Email</label>
                      <input
                        //   value={newPassword}
                        placeholder=""
                        type="text"
                        className="tasKCategory1"
                        style={{
                          //   fontSize: "14px",
                          height: "38px",
                          borderRadius: "10px",
                        }}
                        //   onChange={(e) => {
                        //     setNewPassword(e.target.value);
                        //   }}
                      />
                    </div>

                    <div className="my-3 buttonpushmain">
                      <Button className="custoBtnForCrmCall">Submit</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
