import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import Eyes from "../../assets/images/ic_show_eyes.svg";


const Canteen = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main">
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2"><Link to="/ProductStock">Product Stock</Link> / Canteen</h6>
                    </div>
                    <div className="createStock">
                        <div className="innerConmtainer">
                            <div className="StoreCreatedBox">
                                <table className="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td className="key">Name</td>
                                            <td>hello</td>
                                        </tr>
                                        <tr>
                                            <td className="key">Category</td>
                                            <td>hello</td>
                                        </tr>
                                        <tr>
                                            <td className="key">Address</td>
                                            <td>hello</td>
                                        </tr>
                                        <tr>
                                            <td className="key">Created By</td>
                                            <td>hello</td>
                                        </tr>
                                        <tr>
                                            <td className="key">Created Date</td>
                                            <td>hello</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="mainTableBox">
                        <div className="headingBox">
                            <label className="tableLab">Stock Log</label>
                        </div>
                        <div className="insideTable">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Action</th>
                                        <th>Date</th>
                                        <th>Expiry Date</th>
                                        <th>Count</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td></td>
                                        <td className="text-end">
                                            <button className="clearBtn">Clear</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Image</td>
                                        <td>Alex</td>
                                        <td>500</td>
                                        <td>500</td>
                                        <td></td>
                                        <td className="text-end">
                                            <button className="actionBtn">
                                                <Link to="/TransactionReceipt">
                                                    <img src={Eyes} alt="" />
                                                </Link>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Canteen;
