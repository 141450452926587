import React, { useEffect } from 'react'
import profile_img from "../assets/images/profile_img.svg";
import CallAccept from "../assets/images/chat/CallAccept.svg";
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import ApiConfig from '../api/ApiConfig';

export default function Calling() {
  const { setUserCalling, callDetaiils, socket, userData, activeChat } = useContext(AppContext)
  const navigate = useNavigate()
  console.log("callDetaiils",callDetaiils);
  let user = callDetaiils.channel_details && callDetaiils.channel_details.filter(user => userData.EmpId != user.EmployeeId)[0]
  let channelDetails = callDetaiils && callDetaiils.channel_details && callDetaiils.channel_details[0]
 
  return ( 
    <div>
      <div className="call_popUp_main">
        <div className="heading">
          <p>{callDetaiils.call_from == userData.EmpId && "Calling"} {user && user.FirstName ? user.FirstName + " " + user.LastName + " "
            : channelDetails && channelDetails.Team_Channel_Id ? channelDetails.channel_name + ">" + channelDetails.Team_Channel_Name : activeChat.project && activeChat.project + ">" + activeChat.Team_Channel_Name
          } {callDetaiils.call_from != userData.EmpId && "Calling"}...</p>
        </div>

        <div className="body_call">
          <img src={user && ApiConfig.BASE_URL_FOR_IMAGES + user.PhotoPath || profile_img} alt="" />
        </div>
        <div className="Footer_call">
          <div className="button_Call">
            <div className="reject" >
              <img src={CallAccept} alt="" onClick={() => {
                socket && socket.emit("call-reject", { call_id: callDetaiils.call_id })
                setUserCalling(false)
              }} />
            </div>
            {callDetaiils.call_from !== Number(userData.EmpId) &&
              <div className="approve" >
                <img src={CallAccept} alt="" onClick={() => {
                  let username = localStorage.getItem("Username") 
                  setUserCalling(false)
                  socket && socket.emit("call-accept", { call_id: callDetaiils.call_id,os_type:'web', ...callDetaiils })
                
                  // window.open("/jitsi/" + callDetaiils.call_id + "/" + callDetaiils.room_id + "/" + username, "_blank")
                  navigate("/jitsi/" + callDetaiils.call_id + "/" + callDetaiils.room_id + "/" + username, "_blank")
                }} />
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}
