import React, { useState, useContext } from "react";
import Logo from "../../assets/images/wifi-attendance.svg";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";

import ic_email from "../../assets/images/ic_email.svg";
import { Link, Navigate } from "react-router-dom";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { ToastMsg } from "../TostMsg";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
const Login = ({ setLoggedIn }) => {
  const [encryptedData, setEncryptedData] = useState("");

  const {
    setUserData,
    userData,
    setToken,
    setOpenSessionLogoutModal,
    setHeaderProfile,
    setPremissions,
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    // Corporate_id: "",
    Username: "",
    Password: "",
  });

  const [errMsg, setErrMsg] = useState({
    // Corporate_id: "",
    Username: "",
    Password: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    const usercopy = { ...user };
    const errMsgcopy = { ...errMsg };
    usercopy[name] = value;
    errMsgcopy[name] = "";
    setErrMsg(errMsgcopy);
    setUser(usercopy);
  };

  const LogInSubmit = async (e) => {
    e.preventDefault();
    const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9.]+\.)+[A-Za-z]+$/;
    // if (user.Corporate_id.trim().length === 0) {
    //   setErrMsg({ ...errMsg, Corporate_id: "Please Enter Corporate ID " });
    //   return;
    // }
    if (user.Username.length > 0 === "") {
      setErrMsg({ ...errMsg, Username: "Enter  Email ID " });
      return;
    }
    if (!regex.test(user.Username)) {
      setErrMsg({ ...errMsg, Username: "Enter Valid  Email ID " });
      return;
    }
    if (user.Password.trim().length === 0) {
      setErrMsg({ ...errMsg, Password: "Enter Password " });
      return;
    }
    if (user.Password.length < 6) {
      setErrMsg({ ...errMsg, Password: "you have to enter at least 6 digit!" });
      return;
    } else {
      setIsLoading(true);
      PostCallWithErrorResponse(ApiConfig.LOGIN, JSON.stringify({ ...user }))
        .then(async (res) => {
          // console.log(res.json.Data.Image)
          if (res.json && res.json?.Success === true) {
            console.log(res);
            ToastMsg("success", res.json.Message);
            setIsLoading(false);
          } else {
            ToastMsg("error", res.json.Message);
            setIsLoading(false);
          }
          setToken(res.json.Data.Token);
          setUserData({
            Token: res.json.Data.Token,
            Username: res.json.Data.Username,
            UserId: res.json.Data.UserId,
            Mobile: res.json.Data.Mobile,
            Permissions: res.json.Data.Permissions,
            EmpId: res.json.Data.EmpId,
            OrgId: res.json.Data.OrgId,
            Email: res.json.Data.Email,
            LastName: res.json.Data.LastName,
            FirstName: res.json.Data.FirstName,
            Image: res.json.Data.Image,
            IsActive: res.json.Data.IsActive,
            CompanyLogo:res.json.Data.CompanyLogo

          });
          setHeaderProfile({ PhotoPath: res?.json?.Data?.Image });
          setOpenSessionLogoutModal(false);
          localStorage.setItem("Username", res.json.Data.Username);
          localStorage.setItem("Email", res.json.Data.Email);
          localStorage.setItem("Token", res.json.Data.Token);
          localStorage.setItem("Mobile", res.json.Data.Mobile);
          localStorage.setItem("IsActive", res.json.Data.IsActive);
          localStorage.setItem("EmpId", res.json.Data.EmpId);
          localStorage.setItem("OrgId", res.json.Data.OrgId);
          localStorage.setItem("UserId", res.json.Data.UserId);
          localStorage.setItem("FirstName", res.json.Data.FirstName);
          localStorage.setItem("LastName", res.json.Data.LastName);
          localStorage.setItem("Image", res.json.Data.Image);
          localStorage.setItem("CompanyLogo", res.json.Data.CompanyLogo);
          localStorage.setItem("logedIn", true);

          //workflow login stroage
          localStorage.setItem("token", res.json.Data.Token);
          localStorage.setItem("status", "Active");
          localStorage.setItem("userId", res.json.Data.UserId);
          localStorage.setItem("role", res.json.Data.Role);
          localStorage.setItem("roleId", res.json.Data.RoleId);
          localStorage.setItem("wfmuser", res.json.Data.FirstName);
          localStorage.setItem("currentUser", res.json.Data);

          const wdata = {
            token: res.json.Data.Token,
            role: res.json.Data.Role,
            RoleId: res.json.Data.RoleId,
            userId: res.json.Data.UserId,
            empId: res.json.Data.EmpId,
            OrgId: res.json.Data.OrgId,
            mobile: res.json.Data.Mobile,
            Username: res.json.Data.Username,
            firstName: res.json.Data.FirstName,
            status: "Active",
          };
          //
          //localStorage.setItem("encrypted", encrypted.data);
          //console.log("encrypted",encrypted.data);
          // const secretKey = 'my-fixed-secret-key-12345';

          //const decryptedData = await CryptoJS.AES.encrypt(wdata, secretKey).toString(CryptoJS.enc.Utf8);
          //  console.log("encrypted", encrypted);

          const secretKey = "your-secret-key";

          const encrypted = await encryptData(JSON.stringify(wdata), secretKey);

          console.log("encrypted", encrypted);
          setLoggedIn(true);

          localStorage.setItem(
            "Permissions",
            JSON.stringify(res.json.Data.Permissions)
          );
          setPremissions(res?.json?.Data?.Permissions);
        })
        .catch((err) => console.log(err));
    }
  };
  async function encryptData(plainText, key) {
    //const encoder = new TextEncoder();
    //const data = encoder.encode(plainText);
    //const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector

    //const encryptedData = await window.crypto.subtle.encrypt(
    //    {
    //        name: "AES-GCM",
    //        iv: iv
    //    },
    //    key,
    //    data
    //);

    //return {
    //    iv: iv,
    //    data: encryptedData
    //};
    // const encrypted = await CryptoJS.AES.encrypt(plainText, key).toString(CryptoJS.enc.Utf8);
    const encrypted = await CryptoJS.AES.encrypt(plainText, key).toString();
    setEncryptedData(encrypted);
    localStorage.setItem("encrypted", encrypted);
    console.log("encrypted", encrypted);
    return encryptedData;
  }

  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    Login();
  };

  return (
    <main>
      <div className="main-auth login-main" id="main-login">
        <div className="login">
          <div className="top-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="outer-form">
            <div className="login-form ">
              <form>
                <div className="left ">
                  <div className="heading">
                    <label>Log In</label>{" "}
                  </div>
                  {/* <div className="form-control-div">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Corporate ID
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      disabled
                    // name="Corporate_id"
                    // onChange={handleChange}
                    />
                    {/* {errMsg.Corporate_id.length > 0 && (
                    <span className="text-danger text-center">
                      {errMsg.Corporate_id}
                    </span>
                  )} */}
                  {/*</div> */}
                  <div className="form-control-div">
                    <label
                      for="exampleFormControlInput2"
                      className="form-label"
                    >
                      Username
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput2"
                      placeholder="Username"
                      name="Username"
                      onChange={handleChange}
                    />
                    {errMsg.Username.length > 0 && (
                      <span className="text-danger text-center">
                        {errMsg.Username}
                      </span>
                    )}
                  </div>
                  <div className="form-control-div">
                    <div className="pass-main">
                      <div className="left-pass">
                        <label
                          for="exampleFormControlInput3"
                          className="form-label"
                        >
                          Password
                        </label>
                      </div>
                      <div
                        className="right-pass"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        <img
                          src={
                            !user.showPassword == true ? ic_show : ic_hide_eyes
                          }
                          alt="ic_hide_eyes"
                        />

                        <label
                          for="exampleFormControlInput3"
                          className="form-label"
                        >
                          {!user.showPassword == true ? "Show" : "Hide"}
                        </label>
                      </div>
                    </div>
                    <input
                      type={user.showPassword ? "text" : "Password"}
                      name="Password"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      value={user.Password}
                      className="form-control"
                      id="exampleFormControlInput3"
                      placeholder="Password"
                      autoComplete="false"
                      // onKeyUp={(e) => {
                      //   if (e.key == "Enter") LogInSubmit(e);
                      // }}
                    />
                    {errMsg.Password.length > 0 && (
                      <span className="text-danger text-center">
                        {errMsg.Password}
                      </span>
                    )}
                  </div>
                  <div className="login-button">
                    <Link to="">
                      <button
                        type="submit"
                        onClick={LogInSubmit}
                        disabled={isLoading ? true : false}
                      >
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <span className="me-2">Login</span>
                            <ClipLoader color="#fff" size={18} />
                          </div>
                        ) : (
                          <span>Login</span>
                        )}
                      </button>
                    </Link>
                  </div>
                  <div className="your-pass-text">
                    <label htmlFor="">
                      Need to find{" "}
                      <Link to="/ForgotPassword">your Password?</Link>
                    </label>
                  </div>
                </div>
              </form>
              <div className="wrapper">
                <div className="line"></div>
                <div className="wordwrapper">
                  <div className="word">or</div>
                </div>
                <div className="line"></div>
              </div>
              <div className="ORsectioMain">
                <div className="ORsectionLine2"></div>
                <div className="rspORsection">
                  <div className="word">or</div>
                </div>
                <div className="ORsectionLine"></div>
              </div>

              <div className="right ">
                <div className="email">
                  <Link to="/CreateAccount">
                    <img src={ic_email} alt="ic_email" />
                    <label>Sign up with email</label>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <label>
              Secure Login with reCAPTCHA subject to <br /> Google{" "}
              <Link to="#">Terms</Link> & <Link to="#">Privacy</Link>
            </label>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
