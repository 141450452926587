import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Editac from "../../assets/images/Editac.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
  DeleteCallWithErrorResponseWithTokenQuery,
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import moment from "moment";
import { notifySuccess } from "../../toaster/notify";
import { Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";

const ManageLocations = () => {
  const { sidebar } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [location, setLocation] = useState([]);
  const [addManageLocation, setAddManageLocation] = useState({
    LocationId: 0,
    LocationName: "",
    Country: "",
    EmailAlias: "",
    Lat: 0,
    Long: 0,
    TimeZone: 0,
    TimeZoneValue: "",
  });
  console.log("addManageLocations", addManageLocation);

  const [updateManageLocation, setUpdateManageLocation] = useState({
    LocationId: 0,
    LocationName: "",
    Country: "",
    EmailAlias: "",
    Lat: 0,
    Long: 0,
    TimeZone: 0,
    TimeZoneValue: "",
  });

  const timeZones = {
    "(GMT-12:00) International Date Line West": "-12:00",
    "(GMT-11:00) Midway Island, Samoa": "-11:00",
    "(GMT-10:00) Hawaii": "-10:00",
    "(GMT-09:00) Alaska": "-09:00",
    "(GMT-08:00) Pacific Time (US & Canada); Tijuana": "-08:00",
    "(GMT-07:00) Chihuahua, La Paz, Mazatlan, Mountain Time (US & Canada)":
      "-07:00",
    "(GMT-06:00) Central America, Central Time (US & Canada), Guadalajara":
      "-06:00",
    "(GMT-05:00) Bogota, Lima, Quito, Eastern Time (US & Canada)": "-05:00",
    "(GMT-04:00) Atlantic Time (Canada), Caracas, La Paz, Santiago": "-04:00",
    "(GMT-03:00) Newfoundland, Brasilia, Buenos Aires, Georgetown, Greenland":
      "-03:00",
    "(GMT-02:00) Mid-Atlantic": "-02:00",
    "(GMT-01:00) Azores, Cape Verde Island": "-01:00",
    "(GMT) Casablanca, Monrovia, Greenwich Mean Time: Edinburgh, London": "0",
    "(GMT+01:00) Amsterdam, Berlin, Rome, Vienna, Paris, West Central Africa":
      "+01:00",
    "(GMT+02:00) Athens, Cairo, Jerusalem, Istanbul": "+02:00",
    "(GMT+03:00) Baghdad, Kuwait, Moscow, Tehran": "+03:00",
    "(GMT+04:00) Abu Dhabi, Muscat, Baku, Tbilisi, Yerevan": "+04:00",
    "(GMT+04:30) Kabul": "+04:30",
    "(GMT+05:00) Ekaterinburg, Islamabad, Karachi, Tashkent": "+05:00",
    "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi": "+05:30",
    "(GMT+05:45) Kathmandu": "+05:45",
    "(GMT+06:00) Almaty, Novosibirsk, Astana, Dhaka": "+06:00",
    "(GMT+06:30) Rangoon": "+06:30",
    "(GMT+07:00) Bangkok, Hanoi, Jakarta, Krasnoyarsk": "+07:00",
    "(GMT+08:00) Beijing, Taipei, Hong Kong, Ulaan Bataar, Singapore": "+08:00",
    "(GMT+09:00) Osaka, Tokyo, Seoul": "+09:00",
    "(GMT+09:30) Adelaide, Darwin": "+09:30",
    "(GMT+10:00) Brisbane, Sydney, Guan, Vladivostok, Melbourne": "+10:00",
    "(GMT+11:00) Magadan, Solomon Island, New Caledonia": "+11:00",
    "(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka, Marshall Island":
      "+12:00",
    "(GMT+13:00) Nuku'alofa": "+13:00",
  };

  const [locationList, setLocationList] = useState([]);

  const changeData = (e) => {
    const { name, value } = e.target;
    setAddManageLocation({
      ...addManageLocation,
      [name]: value,
    });
  };
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Get Location
  const getLocation = () => {
    // setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_LOCATION)
      .then((res) => {
        if (res.Success === true) {
          // setLoading(false);
          setLocation(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    locationList &&
    locationList.length &&
    // locationList.filter((item) =>
    //   item.ProjectName?.toLowerCase().includes(searchValue)
    // );

    
    locationList.filter((item) =>
      (item.ProjectName || "").toLowerCase().includes(searchValue)
    );

  const fetchLocations = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_MANAGE_LOCATION)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setLocationList(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLocations();
    getLocation();
  }, []);
  const [btnLoader, setbtnLoader] = useState(false);

  const createLocation = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setbtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.CREATE_LOCATION,
        JSON.stringify(addManageLocation)
      )
        .then((res) => {
          setShow(false);
          setAddManageLocation({
            LocationId: 0,
            LocationName: "",
            Country: "",
            EmailAlias: "",
            Lat: 0,
            Long: 0,
            TimeZone: 0,
            TimeZoneValue: "",
          });

          if (res.json.Success) {
            fetchLocations();
            notifySuccess("Location added successfully");
          } else {
            console.log("error", res);
          }
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setbtnLoader(false);
        });
    }
  };

  const updateLocation = (e) => {
    e.preventDefault();
    setbtnLoader(true);
    PostCallWithErrorResponse(
      ApiConfig.CREATE_LOCATION,
      JSON.stringify(updateManageLocation)
    )
      .then((res) => {
        setShowEdit(false);
        if (res.json.Success) {
          fetchLocations();
          notifySuccess("Location Updated successfully");
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setbtnLoader(false);
      });
  };

  const deleteDesignation = (id) => {
    DeleteCallWithErrorResponseWithTokenQuery(
      ApiConfig.DELETE_LOCATION + `?id=${id}`
    )
      .then((res) => {
        if (res.json.Success) {
          fetchLocations();
          notifySuccess("Record deleted Successfully");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Manage Locations</h6>
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                setShow(true);
                // handleShow();
                // getTaskName();
                //     setAddTaskPayload({
                //         ProjectId: 0,
                //         TaskName: "",
                //         StartDate: "",
                //         EndDate: "",
                //         BudgetedHours: 0,
                //         Priority: "",
                //         EmpId: Number(userData.EmpId),
                //     });
                //     setValidated(false);
              }}
            >
              + Add New Record{" "}
            </button>
          </div>
          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="table-wrapper">
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {locationList && locationList.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>Location Name</th>
                          <th>Country</th>
                          <th>Email Alias</th>
                          <th>Latitude</th>
                          <th>Longitude</th>
                          <th>Time Zone</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {locationList
                          .slice(startIndex, endIndex)
                          .map((data, index) => {
                            return (
                              <tr className="table-row-custom" key={index}>
                                <td>{startIndex + index + 1}</td>
                                <td>{data.LocationName}</td>
                                {/* <td>{moment(data.HolidayDate).format("L")}</td> */}
                                <td>{data.Country}</td>
                                <td>{data.EmailAlias}</td>
                                <td>{data.Lat}</td>
                                <td>{data.Long}</td>
                                <td>{data.TimeZone}</td>
                                {/* <td>{data.LocationName ? data.LocationName : <span className="text-danger">No Data</span>}</td> */}
                                <td>
                                  <img
                                    src={Editac}
                                    className="mx-2 editBtn"
                                    onClick={() => {
                                      setShowEdit(true);
                                      // getEditData(data);
                                      // getSingleHoliday(data.Id)
                                      setUpdateManageLocation({
                                        LocationId: data?.LocationId,
                                        LocationName: data?.LocationName,
                                        Country: data?.Country,
                                        EmailAlias: data?.EmailAlias,
                                        Lat: data?.Lat,
                                        Long: data?.Long,
                                        TimeZone: data?.TimeZone,
                                        TimeZoneValue: data?.TimeZoneValue,
                                      });
                                    }}
                                    alt=""
                                  />
                                  <img
                                    onClick={() => {
                                      deleteDesignation(data.LocationId);
                                    }}
                                    src={DelectAc}
                                    alt=""
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        </div>
        <Pagination
          data={locationList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>

      {/* Add Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form noValidate validated={validated} onSubmit={createLocation}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Add New Record
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShow(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Location Name
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="LocationName"
                value={addManageLocation.LocationName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Location name
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Country
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="Country"
                value={addManageLocation.Country}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Country
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                EmailAlias
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="EmailAlias"
                value={addManageLocation.EmailAlias}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter EmailAlias
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Latitude
              </label>
              <input
                required
                name="latitude" // Add name attribute
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^-?\d*\.?\d*$/.test(inputValue)) {
                    setAddManageLocation({
                      ...addManageLocation,
                      Lat: Number(inputValue),
                    });
                  }
                }}
              />

              <Form.Control.Feedback type="invalid">
                Please enter Latitude
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Longitude
              </label>
              <input
                required
                name="longitude"
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^-?\d*\.?\d*$/.test(inputValue)) {
                    setAddManageLocation({
                      ...addManageLocation,
                      Long: Number(inputValue),
                    });
                  }
                }}
              />

              <Form.Control.Feedback type="invalid">
                Please enter Longitude
              </Form.Control.Feedback>
            </div>

            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Time Zone
              </label>
              <select
                required
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                // value={addManageLocation.TimeZone}
                onChange={(e) => {
                  setAddManageLocation({
                    ...addManageLocation,
                    TimeZoneValue: e.target.value,
                  });
                }}
              >
                <option value="">Select Time Zone...</option>
                {Object.keys(timeZones).map((text) => (
                  <option key={text} value={timeZones[text]}>
                    {text}
                  </option>
                ))}
              </select>
              <Form.Control.Feedback type="invalid">
                Please select a Time Zone
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div className="Bottom-btn-arbaz-responsive">
              <button
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={createHoliday}
                type="submit"
              >
                {btnLoader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="me-2">Loading...</span>
                    <ClipLoader color="#fff" size={18} />
                  </div>
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showEdit}
        onHide={() => setShowEdit(false)}
      >
        <Form noValidate validated={validated} onSubmit={updateLocation}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Edit Record
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShowEdit(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Location Name
              </label>
              <input
                required
                // onChange={(e) => {
                //     changeData(e)
                // }}
                onChange={(e) => {
                  setUpdateManageLocation({
                    ...updateManageLocation,
                    LocationName: e.target.value,
                  });
                }}
                name="LocationName"
                value={updateManageLocation.LocationName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Location name
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Country
              </label>
              <input
                required
                // onChange={(e) => {
                //     changeData(e)
                // }}
                onChange={(e) => {
                  setUpdateManageLocation({
                    ...updateManageLocation,
                    Country: e.target.value,
                  });
                }}
                name="Country"
                value={updateManageLocation.Country}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Country
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                EmailAlias
              </label>
              <input
                required
                // onChange={(e) => {
                //     changeData(e)
                // }}
                onChange={(e) => {
                  setUpdateManageLocation({
                    ...updateManageLocation,
                    EmailAlias: e.target.value,
                  });
                }}
                name="EmailAlias"
                value={updateManageLocation.EmailAlias}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter EmailAlias
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Latitude
              </label>
              <input
                required
                name="latitude" // Add name attribute
                type="text"
                className="date-label-input tasKCategory1"
                value={updateManageLocation.Lat}
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^-?\d*\.?\d*$/.test(inputValue)) {
                    setUpdateManageLocation({
                      ...updateManageLocation,
                      Lat: Number(inputValue),
                    });
                  }
                }}
              />

              <Form.Control.Feedback type="invalid">
                Please enter Latitude
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Longitude
              </label>
              <input
                required
                name="longitude"
                type="text"
                className="date-label-input tasKCategory1"
                value={updateManageLocation.Long}
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^-?\d*\.?\d*$/.test(inputValue)) {
                    setUpdateManageLocation({
                      ...updateManageLocation,
                      Long: Number(inputValue),
                    });
                  }
                }}
              />

              <Form.Control.Feedback type="invalid">
                Please enter Longitude
              </Form.Control.Feedback>
            </div>

            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Time Zone
              </label>
              <select
                required
                value={updateManageLocation.TimeZoneValue}
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                // value={addManageLocation.TimeZone}
                onChange={(e) => {
                  setUpdateManageLocation({
                    ...updateManageLocation,
                    TimeZoneValue: e.target.value,
                  });
                }}
              >
                <option value="">Select Time Zone...</option>
                {Object.keys(timeZones).map((text) => (
                  <option key={text} value={timeZones[text]}>
                    {text}
                  </option>
                ))}
              </select>
              <Form.Control.Feedback type="invalid">
                Please select a Time Zone
              </Form.Control.Feedback>
            </div>

           
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div className="Bottom-btn-arbaz-responsive">
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={addTaskByAdmin}
                type="submit"
              >
                Update
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ManageLocations;
