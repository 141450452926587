import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Form from 'react-bootstrap/Form';

const CreateCategories = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2">Create Categories</h6>
                    </div>
                    <div className="CreateCategories">
                        <div className="innerBox">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <Form.Group controlId="validationCustom01" className="mb-3">
                                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                            />
                                            <Form.Control.Feedback type="invalid">Name Is Required</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Group controlId="validationCustom01" className="mb-3">
                                            <Form.Label>parent Category <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required as="select" type="select" name="payment_method"
                                            >
                                                <option value="">Select Category</option>
                                                <option value="cash">Cash</option>
                                                <option value="online">Online</option>
                                                <option value="card_machine">Card Machine</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Select A Category</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Group controlId="validationCustom01" className="mb-3">
                                            <Form.Label>Inventory Evaluation<span className="text-danger">*</span></Form.Label>
                                            <Form.Control required as="select" type="select" name="payment_method"
                                            >
                                                <option value="">Select Category</option>
                                                <option value="cash">Cash</option>
                                                <option value="online">Online</option>
                                                <option value="card_machine">Card Machine</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Select A Category</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Group controlId="validationCustom01" className="mb-3">
                                            <Form.Label>Image <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="file"
                                                required
                                                name="file"
                                            // onChange={handleChange}
                                            // isInvalid={!!errors.file}
                                            />
                                            <Form.Control.Feedback type="invalid">Select A Category</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mainFormCheck mb-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                            <label class="form-check-label" for="flexSwitchCheckChecked">Visible To POS</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mainFormCheck mb-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked2" />
                                            <label class="form-check-label" for="flexSwitchCheckChecked2">Visible To Online</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-end">
                                        <button className="Create">Create</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateCategories;
