import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import BarChart from "../../component/Barchart.js";
import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const Crmwebsite = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const dataArrayLocation = [
    {
      id: 1,
      Title: "api key",

      Url: "http://admin.officecaller.com",
      key: "7dac0fcac909b349",
      Action: "Delete",
    },

    {
      id: 2,
      Title: "api key",

      Url: "http://admin.officecaller.com",
      key: "7dac0fcac909b349",
      Action: "Delete",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataArrayLocation.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(dataArrayLocation.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role">
          <h6 className="mt-2">Website</h6>
          <button className="custoBtnForCrmCall">
            <a className=" textcolor" href="Addwebsite">
              Add Website
            </a>
          </button>
        </div>

        <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>

        <div
          className="  d-flex align-items-center justify-content-center "
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
          }}
        ></div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table className="">
            <thead className="firstTabelRow">
              <tr classname="row-hover modal-footer-table-body-tr">
                <th>ID</th>
                <th>Title</th>
                <th>Website Url</th>
                <th>API Key</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id} className="table-row-custom">
                  <td>
                    <td>{item.id}</td>
                  </td>
                  <td>{item.Title}</td>
                  <td>{item.Url}</td>
                  <td>{item.key}</td>
                  <td>
                    <Link to="" onClick={handleShow3}>
                      <img
                        src={deleteicon}
                        alt="Image 2"
                        className="cell-image "
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Call Assigning International
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="modalProp mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Client Name
            </label>
            <select
              className="tasKshowusser"
              // onChange={(e) => setService(e.target.value)}
            >
              <option>Agent List</option>
              <option>Thushara</option>
              <option>sobhisha</option>
            </select>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleClose}
            >
              Save Changes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this Website ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Crmwebsite;
