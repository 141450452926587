import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import { DeleteCallWithErrorResponseWithToken, PostCallWithErrorResponse, postMultipartWithAuthCall, putWithAuthCall } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";

export default function CreateToken() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
    const naviget = useNavigate()

    const [addwhatsApp,setAddWhatsApp] = useState({
      token:'',
      source :"",
      phone_number_id :""
    })

    const onAddWhatsAppData=(e)=>{
      e?.preventDefault()
      const body = {
        token:addwhatsApp.token,
        source:addwhatsApp.source,
        phone_number_id:addwhatsApp.phone_number_id
      }

      PostCallWithErrorResponse(ApiConfig.GETWHATSAPPADD,JSON.stringify(body))
      .then((res)=>{
        console.log("add whatsApp Data success--->",res)
        if(res.json.success === true){
          ToastMsg("success",res.json.message)
        naviget("/whatsappsetting")

        }else{
          ToastMsg("error",res.json.message)
        }
      })
      .catch((err)=>{
        console.log("Add whatsApp Data Error--->",err)
      })
    }
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">WhatsApp Settings</h6>
        </div>

        <div className="WeeklyMain">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Token :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  //   value={newPassword}
                  placeholder="TOKEN"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  value={addwhatsApp.token}

                    onChange={(e) => {
                      setAddWhatsApp({...addwhatsApp,token:e.target.value});
                    }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Source :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  placeholder="SOURCE"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  value={addwhatsApp.source}

                  onChange={(e) => {
                    setAddWhatsApp({...addwhatsApp,source:e.target.value});
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Phone Number ID :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                    value={addwhatsApp.phone_number_id}
                  placeholder="PHONE NUMBER ID"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  onChange={(e) => {
                    setAddWhatsApp({...addwhatsApp,phone_number_id:e.target.value});
                  }}
                />
              </div>
            </div>
          </div>

          <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
            <button className="custoBtnForCrmCall" onClick={onAddWhatsAppData}>
              <a className=" textcolor" href="WhatsappSetting">
                Create Whatsapp Token
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
