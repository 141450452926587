import React, { useEffect, useState } from 'react'
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import CallAcceptRed from "../../assets/images/chat/CallAcceptRed.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { Scroller } from '@fullcalendar/react';
export default function CallingScreen() {
    const { call_id, room_id, username } = useParams();
    const [showCloseButton, setShowCloseButton] = useState(false)
    const { socket, setCallDetaiils } = useContext(AppContext);
    const [participantId, setParticipantId] = useState("")
    const navigate = useNavigate();
    console.log("Callewd", call_id);
    const callColsed = (e) => {
        socket && socket.emit('call-room-left', {
            id: participantId
        })
        console.log("Callewd", call_id);
        socket && socket.emit("call-end", { call_id: call_id });
        setCallDetaiils(null)
        navigate("/chat")

    }

    return (
        <>
            <div className='jitsi-wrapper' onMouseMove={(e) => {
                console.log("e", e);
                // ev.preventDefault();
                var target = e.target;
                var domRect = target.getBoundingClientRect();
                console.log("offsetY: " + e.clientY + " height: " + domRect.height)
                console.log("domRect.height- e.clientY", domRect.height - e.clientY);
                if (domRect.height - e.clientY < domRect.height / 3) {
                    setShowCloseButton(true)
                }
                else setShowCloseButton(false)
            }}>
                {/* <div>
                    {
                        room_id &&
                        <JitsiMeeting
                            key={"vpaas-magic-cookie-9a3fc94260004c68b6cac3325a10004d"}
                            domain="meet.jit.si"
                            roomName={room_id}

                            configOverwrite={{
                                startWithAudioMuted: true,
                                disableModeratorIndicator: true,
                                startScreenSharing: true,
                                enableEmailInStats: false,
                                prejoinPageEnabled: false,
                                enableClosePage: false

                                //   whiteboard : {
                                //     enabled: true,
                                //     collabServerBaseUrl: 'http://localhost:3002/'
                                // }
                            }}

                            interfaceConfigOverwrite={{

                                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                                TOOLBAR_BUTTONS: [
                                    'camera',
                                    'chat',
                                    'closedcaptions',
                                    'desktop',
                                    'download',
                                    'embedmeeting',
                                    'etherpad',
                                    'feedback',
                                    'filmstrip',
                                    'fullscreen',
                                    // 'hangup',
                                    'help',
                                    'highlight',
                                    'invite',
                                    'linktosalesforce',
                                    'livestreaming',
                                    'microphone',
                                    'noisesuppression',
                                    'participants-pane',
                                    'profile',
                                    'raisehand',
                                    'recording',
                                    'security',
                                    'select-background',
                                    'settings',
                                    'shareaudio',
                                    'sharedvideo',
                                    'shortcuts',
                                    'stats',
                                    'tileview',
                                    'toggle-camera',
                                    'videoquality',
                                    'whiteboard',
                                ]
                            }}
                            userInfo={{
                                displayName: username
                            }}
                            // onReadyToClose={callColsed}

                            onApiReady={(externalApi) => {

                                externalApi.addListener('participantJoined', (data) => {
                                    //tirgger part_info
                                    //event (send,partcipant_info)

                                })
                                externalApi.addListener('videoConferenceJoined', (data) => {
                                    console.log("videoConferenceJoined", data);
                                    setParticipantId(data.id)

                                    socket && socket.emit('call-room-joined', {
                                        participantId: data.id,
                                        os_type: 'web',
                                        roomName: data.roomName

                                    })


                                })

                                externalApi.addListener('participantLeft', (data) => {
                                    socket && socket.emit('call-room-left', {
                                        id: data.id
                                    })

                                    externalApi.getRoomsInfo().then(rooms => {

                                        if (rooms.rooms[0].participants && rooms.rooms[0].participants.length == 1) {

                                            socket && socket.emit('call-room-left', {
                                                id: rooms.rooms[0].participants[0].id
                                            })
                                            callColsed();

                                        }
                                    })
                                });
                                externalApi.addListener('audioMuteStatusChanged', (data) => {
                                    console.log("audioMuteStatusChanged", data);
                                })
                                // here you can attach custom event listeners to the Jitsi Meet External API
                                // you can also store it locally to execute commands
                                //   setApi(externalApi);

                            }}
                            getIFrameRef={(iframeRef) => {
                                iframeRef.style.height = "100vh";
                            }}
                            containerStyle={{ marginTop: -25, position: 'absolute', zIndex: 1 }}
                        />
                    }

                </div> */}
                <div className="button_Call" >
                    <div className="reject" >
                        <img src={CallAcceptRed} alt="" onClick={callColsed} />
                    </div>

                </div>
            </div>
        </>
    )
}
