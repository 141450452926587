import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import phone from "../../assets/images/phone.svg";
import phonetwentyfour from "../../assets/images/phonetwentyfour.svg";
import profileblue from "../../assets/images/profileblue.svg";
import GroundAmbualnce from "../../assets/images/ground-ambulance.svg";

const WhatsappPromotion = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const dataArray = [
    {
      id: 1,
      name: "Call",
      Count: "23232",
      img: phone,
    },
    { id: 2, name: "Users", Count: "23232", img: profileblue },
    { id: 3, name: "Candidates", Count: "23232", img: profileblue },
    { id: 4, name: "Agents", Count: "23232", img: profileblue },
    {
      id: 5,
      name: "Not Call Classification",
      Count: "23232",

      img: phone,
    },
    { id: 6, name: "Zero Call List", Count: "23232", img: phone },

    { id: 7, name: "Services Request", Count: "23232", img: phonetwentyfour },
  ];

  const dataMap = new Map(dataArray.map((item) => [item.id, item]));

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">WhatsApp Promotion</h6>
<Link to="/customGroup">
            <button className="custoBtnForCrmCall" >
              <a className=" textcolor" href="CreateToken">
              Create Promotion
              </a>
            </button>
            </Link>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input type="text" placeholder="Search here" />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              {/* <div className="entries-wrapper mt-0 cus-mb">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  value={selectedRows}
                  onChange={handleSelectChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div> */}
              <div className="modalProp mb-4">
             
              <input
                required
                // onChange={(e) => {
                //   changeData(e);
                // }}
                name="Date"
                // value={addDataHoliday.Date}
                type="date"
                // min={today}
                // className="date-label-input tasKCategory1"
                className="promotiondate"
                
           
              />
              {/* <Form.Control.Feedback type="invalid">
                Please enter holiday date
              </Form.Control.Feedback> */}
            </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>

          <div>
            <div style={{}} className="row">
              {Array.from(dataMap.values()).map((item) => (
                <div key={item.id} className="col-lg-4 col-md-8 col-sm-12 mb-4 ">
                  <div className="phonebook-dash-item-two">
                    <div>
                      <h7 className="head">Fleet Management messame 28</h7>

                      <div
                      className="outerrowstyle"
                     
                      >
                        <div className="mt-1">
                          <div className="innerrowstyle">
                            <p className="schedule_Date">schedule_Date:</p>
                            <p className="schedule_Date ml-1"> 28-JUN-2024</p>
                          </div>
                          <div className="innerrowstyle">
                            <p className="schedule_Date">schedule_Time:</p>
                            <p className="schedule_Date ml-1"> 21:17:00</p>
                          </div>
                          <div className="innerrowstyle">
                            <p className="schedule_Date">Status:</p>
                            <p className="schedule_Date ml-1"> Send</p>
                          </div>
                        </div>

                        <img src={ GroundAmbualnce} />
                      </div>

                      <div  className="resett mt-1" style={{height:"25px"}}><p>Repeat</p></div>
                      {/* <div className="col-6  align-items-center ">
                        <img src={item.img} />
                      </div>
                      <div className="col-6 align-items-center">
                        <p className="item-title">{item.name}</p>
                        <p className="item-count">{item.Count}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this WhatsApp ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default WhatsappPromotion;
