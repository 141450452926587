import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  Form,
} from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Nav from "react-bootstrap/Nav";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import ic_filter_chat from "../../assets/images/chat/ic_filter_chat.svg";
import ic_plus_msg from "../../assets/images/chat/ic_plus_msg.svg";
import ic_dropdown from "../../assets/images/chat/ic_dropdown.svg";
import profile_img from "../../assets/images/profile_img.svg";
import user_pic2 from "../../assets/images/profile_img.svg";
import "../../assets/css/main.scss";
import ChatHistory from "./ChatHistory";
import ic_online from "../../assets/images/chat/ic_online.svg";
import ic_come_back from "../../assets/images/chat/ic_come_back.svg";
import ic_on_call from "../../assets/images/chat/ic_on_call.svg";
import ic_minus_red from "../../assets/images/chat/ic_minus_red.svg";
import ic_offline from "../../assets/images/chat/ic_offline.svg";
import arrow_right from "../../assets/images/chat/arrow_right.svg";
import ic_video_call from "../../assets/images/chat/ic_video_call.svg";
import { SpeedDial } from "primereact/speeddial";
import bx_phone_call from "../../assets/images/chat/bx_phone_call.svg";
import more_vertical from "../../assets/images/chat/more_vertical.svg";
import double_tick from "../../assets/images/chat/double_tick.svg";
import hr_three_dot from "../../assets/images/chat/hr_three_dot.svg";
import send_btn from "../../assets/images/chat/send_btn.svg";
import ic_blueUP_arrow from "../../assets/images/sidebar/ic_blueUP_arrow.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { countMessage } from "../../common";

import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { useMediaQuery } from "react-responsive";
import ChatBox from "./ChatBox";
import ApiConfig, { BASE_URL_CHAT_Image } from "../../api/ApiConfig";
import moment from "moment";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import Multiselect from "multiselect-react-dropdown";
import { notifyError, notifySuccess } from "../../toaster/notify";
import { ClipLoader } from "react-spinners";

const Teams = () => {
  const toast = useRef(null);
  const currentRoute = useLocation().pathname;
  const [show, setShow] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState("");
  const [showsubChannelModal, setShowsubChannelModal] = useState(false);
  const [subChannelDetails, setSubChannelDetails] = useState({
    name: "",
    description: "",
    members: [],
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const [currentTeam, setCurrentTeam] = useState(null);
  const [members, setMembers] = useState([]);

  const {
    currentSChannel,
    getTeams,
    teamMembers,
    loading,
    setLoading,
    sidebar,
    membersArray,
    setSidebar,
    socket,
    Dark,
    setDark,
    projects,
    teams,
    setProjects,
    setTeams,
    activeChat,
    setActiveChat,
    setCurrentSChannel,
    userData,
    permissions,
  } = useContext(AppContext);

  const navigate = useNavigate("");

  const createSubChannel = () => {
    let body = JSON.stringify({
      schannel_name: subChannelDetails.name,
      schannel_description: subChannelDetails.description,
      channel_id: currentTeam,
      members: subChannelDetails.members.map((mem) => mem.id),
    });
    if (
      subChannelDetails?.name === "" ||
      subChannelDetails?.description === "" ||
      subChannelDetails?.members.length === 0
    ) {
      notifyError("Please fill all fields");
    } else {
      setLoading(true);
      PostCallWithErrorResponse(ApiConfig.CREATE_SUB_CHANNEL, body)
        .then((res) => {
          setLoading(false);
          if (res.json.status) {
            notifySuccess(res.json.message);
            setShowsubChannelModal(false);
            setSubChannelDetails({ name: "", description: "", members: [] });
            getTeams();
          } else notifyError(res.json.message);
        })
        .catch((err) => {
          setLoading(false);
          notifyError(err);
        });
    }
  };

  function onSelect(selectedList, selectedItem) {
    setSubChannelDetails({ ...subChannelDetails, members: selectedList });
  }
  function onRemove(selectedList, removedItem) {
    setSubChannelDetails({ ...subChannelDetails, members: selectedList });
  }
  const joinRoom = (schannel_id) => {
    socket.emit("join-room", {
      schannel_id: schannel_id,
    });
  };

  useEffect(() => {
    let newArr =
      Array.isArray(teams) &&
      teams &&
      teams.map((element) => {
        return element?.channel_name === activeChat?.project &&
          activeChat?.project
          ? {
              ...element,
              submenu: true,
            }
          : {
              ...element,
              submenu: false,
            };
      });
    setTeams(newArr);
  }, [activeChat]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <Toast ref={toast} />

          <Tab.Container id="left-tabs-example">
            <div className="row justify-content-around">
              <div className="col-lg-3 left-chat-section p-0">
                <div className="left-heading-chat">
                  <div className="left-head">
                    <label>Teams</label>
                  </div>
                  <div className="right-icon">
                    <Link to="#">
                      <img src={ic_filter_chat} alt="" />
                    </Link>
                    {/* <Link to="#" className="ms-4">
                      <img src={ic_plus_msg} alt="" />
                    </Link> */}
                  </div>
                </div>
                <div className="chat-lineseparater"></div>
                <div className="teams-user-lists mt-3">
                  <div className="pinned-section mx-2 px-2">
                    <div className="left">
                      <label htmlFor="">Your Teams</label>
                    </div>
                    {/* <div className="right">
                      <Link to="#">
                        <img src={ic_dropdown} alt="" className="pe-2" />
                      </Link>
                    </div> */}
                  </div>

                  {teams &&
                    teams.map((project, index) => {
                      return (
                        <>
                          <div
                            className="team-user"
                            key={"project" + project.channel_id}
                            onClick={() => {
                              socket &&
                                socket.emit("get-general-members", {
                                  schannel_id:
                                    project.subchannel &&
                                    project.subchannel[0].Team_Channel_Id,
                                });
                              setCurrentTeam(
                                project.subchannel &&
                                  project.subchannel[0].Team_Channel_Id
                              );
                              joinRoom(
                                project.subchannel &&
                                  project.subchannel[0].Team_Channel_Id
                              );
                            }}
                          >
                            <div className="menus-items with-subMenu">
                              <Link
                                to="#"
                                className="icon-menu-name-arrow"
                                onClick={() => {
                                  setTeams(
                                    teams.map((proj, innerIndex) => {
                                      return {
                                        ...proj,
                                        submenu: proj.submenu
                                          ? !proj.submenu
                                          : innerIndex === index,
                                      };
                                    })
                                  );
                                }}
                              >
                                <div className="menu-left">
                                  <div className="team-image">
                                    <Link to="#">
                                      <img src={user_pic2} alt="" />
                                    </Link>
                                  </div>
                                  <div
                                    className={
                                      currentRoute === "/" ||
                                      currentRoute === "/"
                                        ? `menu-name ${currentSChannel}`
                                        : "menu-name"
                                    }
                                  >
                                    {project.channel_name}{" "}
                                    {countMessage(
                                      project.subchannel,
                                      "unread_count"
                                    )}
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <Dropdown className="ActionDropdown float-right right-icon-chat">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                className="ActionToggle"
                              >
                                <img src={more_vertical} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="ActionMenu">
                                <ul className="actionnmenulist">
                                  <li
                                    onClick={() => setShowsubChannelModal(true)}
                                  >
                                    Add Sub-channel
                                  </li>
                                  <li onClick={() => setShow(true)}>
                                    View members
                                  </li>
                                  {/* <li>Leave the Team</li>
                                <li>Add Members</li>
                                <li>Manage Tags</li> */}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {project.submenu ? (
                            <div className="sub-menus flex-column justify-content-between ">
                              {project.subchannel.map(
                                (channel, channel_index) => {
                                  return (
                                    <>
                                      <Link
                                        to="/teams"
                                        className="sub-menue-items mb-0 p-2"
                                        onClick={() => {
                                          isMobile
                                            ? setSidebar(false)
                                            : setSidebar(true);

                                          setActiveChat({
                                            pin: false,
                                            ...channel,
                                            project: project.channel_name,
                                            channel_name:
                                              channel.Team_Channel_Name,
                                            channel_id: channel.channel_id,
                                            //   channel_id: channel.Team_Channel_Id,
                                          });
                                          setCurrentSChannel(
                                            channel.Team_Channel_Id
                                          );
                                        }}
                                        key={
                                          "Subchannel" + channel.Team_Channel_Id
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            className={
                                              currentRoute === "/teams"
                                                ? `menu-name ${
                                                    currentSChannel ==
                                                      channel.Team_Channel_Id &&
                                                    "activeColorBlue"
                                                  }`
                                                : "menu-name"
                                            }
                                          >
                                            {channel.Team_Channel_Name}{" "}
                                            {console.log(channel)}
                                          </div>
                                          <div className="date">
                                            <span
                                              class="badge mx-2"
                                              style={{ position: "unset" }}
                                            >
                                              {channel.unread_count > 0
                                                ? channel.unread_count
                                                : ""}
                                            </span>
                                          </div>
                                        </div>
                                      </Link>
                                      {/* <Dropdown className="ActionDropdown teams-dropdown right-icon-chat ">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="ActionToggle"
                                      >
                                        <img src={more_vertical} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="ActionMenu">
                                        <ul className="actionnmenulist">
                                          <li>Add channel</li>
                                          <li onClick={() => {  setCurrentTeam(channel.Team_Channel_Id) }}>View Mebers</li>
                                          <li>Add Members</li>
                                          <li>Manage Tags</li>
                                        </ul>
                                      </Dropdown.Menu>
                                    </Dropdown> */}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                </div>
                <div className="chat-lineseparater mx2"></div>
                <div className="pinned-section px-3" id="invite-people">
                  <div className="left">
                    <label
                      style={{ color: "var(--gray_05_LightMode)" }}
                      htmlFor=""
                    >
                      Invite People
                    </label>
                  </div>
                  <div className="right">
                    <Link to="#">
                      <img src={arrow_right} alt="" className="pe-1" />
                    </Link>
                  </div>
                </div>
                <div className="pinned-section px-3" id="invite-people">
                  {permissions && permissions["Create Team"] && (
                    <div className="left">
                      <label
                        htmlFor=""
                        onClick={() => {
                          navigate("/CreateTeam");
                        }}
                      >
                        Join or Create Team
                      </label>
                    </div>
                  )}
                  <div className="right">
                    <Link to="#">
                      <img src={arrow_right} alt="" className="pe-1" />
                    </Link>
                  </div>
                </div>
              </div>
              <ChatBox />
              {/* <div className="new-conversation-btn">
                <button>New Conversation</button>
              </div> */}
            </div>
          </Tab.Container>
        </div>
      </div>
      {/* View memebers Modal */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setCurrentTeam(null);
          setMembers([]);
        }}
        animation={false}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Genral Channel Members</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Table size="sm">
            <thead>
              <tr>
                <th></th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Lst Seen</th>
              </tr>
            </thead>
            <tbody>
              {teamMembers.map((member, index) => {
                return (
                  <tr key={"member" + index}>
                    <td>
                      {member.user_status !== null ? (
                        <>
                          <img src={ic_online} />{" "}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </td>
                    <td className="d-flex ">
                      <img
                        className="memberImage"
                        src={
                          ApiConfig.BASE_URL_FOR_IMAGES + member.PhotoPath ||
                          profile_img
                        }
                      ></img>
                      <div className="ml-3">{member.FirstName}</div>
                    </td>
                    <td> {member.LastName}</td>
                    <td>
                      {member.last_seen !== null
                        ? moment(member.last_seen).fromNow()
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      {/* Create Sub channel  Modal */}
      <Modal
        show={showsubChannelModal}
        onHide={() => {
          setShowsubChannelModal(false);
        }}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Sub Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 pe-0 mb-2">
              <label for="" class="mb-2 text-grey">
                Channel Name
              </label>
              <input
                value={subChannelDetails.name}
                onChange={(e) =>
                  setSubChannelDetails({
                    ...subChannelDetails,
                    name: e.target.value,
                  })
                }
                class="form-control tasKCategory1"
                type="text"
                placeholder="Enter Channel Name"
              />
            </div>
            {/* <div className="col-lg-6 pe-0 mb-2 custom-updale-logo">
              <label for="" class="mb-2 text-grey">
                Channel Logo
              </label>
              <input
                class="form-control tasKCategory1"
                onChange={(e)=> setSubChannelDetails({...subChannelDetails, name: e.target.value})}
                type="file"
                placeholder="Enter Channel Name"
              />
            </div> */}

            <div className="col-lg-12 mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="mb-2 text-grey"
              >
                Channel Description
              </label>
              <textarea
                value={subChannelDetails.description}
                onChange={(e) =>
                  setSubChannelDetails({
                    ...subChannelDetails,
                    description: e.target.value,
                  })
                }
                className="form-control tasKCategory1"
                id="exampleFormControlTextarea1"
                rows={3}
                defaultValue={""}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <Multiselect
                options={membersArray
                  ?.filter((emp) => emp?.User_Id !== Number(userData?.EmpId))
                  ?.map((suggetion) => {
                    return {
                      id: suggetion.User_Id,
                      name: suggetion.FirstName + " " + suggetion.LastName,
                    };
                  })}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="name"
                closeOnSelect="false"
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mainBtnsSub py-3 px-3">
              <button
                className="btn-width cancelBtn mx-3"
                type="button"
                onClick={() => {
                  setShowsubChannelModal(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={loading ? true : false}
                className="btn-width saveBtn"
                type="button"
                onClick={() => {
                  createSubChannel();
                }}
              >
                {loading ? <ClipLoader color="#fff" size={18} /> : "Create"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Teams;
