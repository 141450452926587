import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import redbytes from "../../assets/images/custom-rb-logo.png";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const QueryaCases = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [showPersonal, setShowPersonal] = useState(false);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const agentdata = [
    {
      id: 1,
      name: "Mark",
      email: "Otto@xyz.com",
      phone: "+90999999999",
      snapshot: redbytes,
      action: "2023-Dec-28",
    },
    {
      id: 2,
      name: "Mark",
      email: "Otto@xyz.com",
      phone: "+90999999999",
      snapshot: redbytes,
      action: "2023-Dec-28",
    },
    {
      id: 3,
      name: "Mark",
      email: "Otto@xyz.com",
      phone: "+90999999999",
      snapshot: redbytes,
      action: "2023-Dec-28",
    },
    {
      id: 4,
      name: "Mark",
      email: "Otto@xyz.com",
      phone: "+90999999999",
      snapshot: redbytes,
      action: "2023-Dec-28",
    },
    {
      id: 5,
      name: "Mark",
      email: "Otto@xyz.com",
      phone: "+90999999999",
      snapshot: redbytes,
      action: "2023-Dec-28",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Cases</h6>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search "
                // onChange={(e) => {
                //   setSearchValue(e.target.value);
                //   setCurrentPage(0);
                // }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                // onChange={(e) => {
                //   setUsersPerPage(e.target.value);
                //   setCurrentPage(0);
                // }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </div>
          </div>

          <div className="import-export-btn-wrapper mt-4">
            <div className="booksIcon">
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>

        <div id="crm-call-form">
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th scope="col">ID</th>
                  <th scope="col">Username</th>

                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Snapshot</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {agentdata.map((item, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{item.id}</td>
                    <td>{item.name}</td>

                    <td> {item.email}</td>
                    <td>{item.phone}</td>
                    <td>
                      <img src={item.snapshot} />
                    </td>

                    <td>
                      <div
                        className="reset"
                        onClick={() => setShowPersonal(true)}
                      >
                        <p>View Snapshot</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
          // data={EmployeeList}
          // pageChangeHandler={pageChangeHandler}
          // usersPerPage={usersPerPage}
          // currentPage={currentPage}
          // searchValue={searchValue}
          // searchedDataPageCounts={searchedDataPageCounts}
          />
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={showPersonal}
        onHide={() => setShowPersonal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Snapshot Name
            </label>
          </Modal.Title>
          {/* <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShowPersonal(false)} />
          </div> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <img src={redbytes} />
        </Modal.Body>

        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QueryaCases;
