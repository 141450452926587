

import { useEffect, useState } from "react";

import Select from 'react-select'
import { ToastMsg } from "../../TostMsg";

import { useNavigate } from "react-router-dom";
import CommonButton from "../../../pages/ChatAdmin/button";
import { AppContext } from "../../../context/AppContext";
import { PostCallWithErrorResponse, putMultipartWithAuthCallWithErrorResponse, simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { useContext } from "react";

const actions = [
    {
        label: 'Pricing',
        value: 'Pricing'
    },
    {
        label: 'Demo',
        value: 'Demo'
    },
    {
        label: 'Meeting',
        value: 'Meeting'
    },
    {
        label: 'Presentation / brouchure',
        value: 'Presentation / brouchure'
    },
    {
        label: 'Download App Links',
        value: 'Download App Links'
    },
]

function AddConversationFlow() {

    const [chats, setChats] = useState([]);

    const [selectedChat, setSelectedChat] = useState(undefined);

    const { isDrawerOpen, toggleDrawer, search, sidebar, domainName, selectedDomainId, editKewordDtls, setEditKeyWordDtls } = useContext(AppContext);

    const [moduleNameList, setModuleNameList] = useState([])

    const [isAdd, setIsAdd] = useState(false)

    const [subModule, setSubModules] = useState([])

    const navigate = useNavigate()

    const [keyworddtls, setKeywordDtls] = useState(
        {
            module_relation: "",
            pr_module: "",
            pr_action: "",
            module_id: null
        })

    const [keywordErr, setKeywordErr] = useState({
        module_relation_err: "",
        pr_module_err: "",
        pr_action_err: ""
    })


    useEffect(() => {

        getModuleList()

        if (editKewordDtls) {

            console.log(editKewordDtls);

            setKeywordDtls({
                module_relation: editKewordDtls?.module_relation,
                pr_module: editKewordDtls?.module_name,
                pr_action: editKewordDtls?.module_action,
                module_id: editKewordDtls?.module_id
            })

        }

        return () => setEditKeyWordDtls(null)

    }, [selectedDomainId])

    const handleSubmit = () => {

        setKeywordErr({
            module_relation_err: keyworddtls?.module_relation.length > 0 ? "" : "Please Select Releted Module",
            pr_module_err: keyworddtls?.pr_module?.length > 0 ? "" : "Please Select Module",
            pr_action_err: keyworddtls?.pr_action.length > 0 ? "" : "Please Select Action"
        })

        if (keyworddtls?.module_relation.length != 0 && keyworddtls?.pr_module.length != 0 && keyworddtls.pr_action?.length != 0) {

            setIsAdd(true)

            keyworddtls.domain_id = selectedDomainId

            if (keyworddtls?.module_id) {

                putMultipartWithAuthCallWithErrorResponse(ApiConfig?.UPDATE_KEYWORD, JSON.stringify(keyworddtls)).then(res => {


                    if (res?.success) {

                        ToastMsg('success', 'Update Successfully')

                        navigate('/converstionflow')

                    } else {

                        ToastMsg('error', 'Somethingbwent wrong try again')

                    }


                })

                return

            }

            PostCallWithErrorResponse(ApiConfig.ADD_KEYWORD, JSON.stringify(keyworddtls)).then((res) => {


                if (res?.success) {

                    ToastMsg('success', 'Add Successfully')


                    navigate('/converstionflow')

                } else {

                    ToastMsg('error', 'Something went wrong btry again')
                }

            }).catch((err) => {

                ToastMsg('error', 'Something went wrong btry again')
            })


        }

    }

    const getModuleList = () => {

        simpleGetCall(ApiConfig?.GET_MODULE_LIST_DROP_DOWN + "?domain_id=" + selectedDomainId).then((res) => {

            if (res?.success) {

                let moduleOptions = res?.data?.map((module) => {
                    return {
                        value: module?.pr_module,
                        label: module?.pr_module
                    }
                })



                setModuleNameList(moduleOptions)



            } else {

            }

        }).catch(() => {

            console.log('something went wrong');

        })

    }

    const handleChatClick = (selectedChat) => {
        setSelectedChat(selectedChat);
        // setSelectedChatDetails(undefined);


        // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
        // });
    };


    return (
        <div className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main">
            
            <div
            
            >
                {" "}
              
                <div >
                    <div className="role-content">
                        <div className="sub-header-role d-flex justify-content-between">
                            <h6 className="mt-2">Add Keyword</h6>
                            <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
                        </div>
                        <div className="mt-5">
                            <div className="input_wraper mt-1">
                                <label htmlFor="">Module Name</label>
                                <Select
                                    //isMulti
                                    isClearable
                                    options={moduleNameList}
                                    value={moduleNameList?.filter((i) => i?.value == keyworddtls?.pr_module)}
                                    onChange={(e) => {

                                        setKeywordDtls(prev => ({
                                            ...prev,
                                            pr_module: e?.value ? e.value : ''
                                        }))
                                    }}
                                    classNamePrefix="custom-select"
                                />
                                <p className="text-danger">{keywordErr?.pr_module_err}</p>
                            </div>
                            <div className="input_wraper mt-1">
                                <label htmlFor="">Releted Module Name</label>
                                {/* <input placeholder='Write assistant name' className='w-100' type="text" /> */}
                                <Select
                                    //isMulti
                                    isClearable
                                    options={moduleNameList}
                                    value={moduleNameList?.filter((i) => i?.value == keyworddtls?.module_relation)}
                                    onChange={(e) => {

                                        setKeywordDtls(prev => ({
                                            ...prev,
                                            module_relation: e?.value ? e.value : ''
                                        }))
                                    }}
                                    NamePrefix="custom-select"
                                />
                                <p className="text-danger">{keywordErr?.module_relation_err}</p>
                            </div>
                            <div className="input_wraper mt-1">
                                <label htmlFor="">Action</label>
                                {/* <input placeholder='Write assistant name' className='w-100' type="text" /> */}
                                <Select
                                    //isMulti
                                    isClearable
                                    options={actions}
                                    value={actions?.filter((i) => i?.value == keyworddtls?.pr_action)}
                                    onChange={(e) => {

                                        setKeywordDtls(prev => ({
                                            ...prev,
                                            pr_action: e?.value ? e.value : ''
                                        }))
                                    }}
                                    classNamePrefix="custom-select"
                                />
                                <p className="text-danger">{keywordErr?.pr_action_err}</p>
                            </div>
                        </div>
                        <div className="w-100 d-flex align-items-end justify-content-end">
                            <CommonButton
                                title={keyworddtls?.module_id ? 'Update' : 'Submit'}
                                isLoading={isAdd}
                                handlerFunction={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )


}

export default AddConversationFlow