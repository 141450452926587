import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.scss";
import AbortTask from "./pages/TaskManagement/AbortTask";
import AboutOrgan from "./pages/Organisation/AboutOrgan";
import Achivements from "./pages/Achivements/Achivements";
import AddEmployee from "./pages/Employee/AddEmployee";
import AddEmployeeAttendanceReport from "./pages/attendence/AddEmployeeAttendance";
import AddProductStock from "./pages/Employee_Wallet/AddProductStock";
import Addwebsite from "./pages/CRMOfficeCaller/Addwebsite";
import Admin from "./pages/CRMOfficeCaller/Admin";
import AdminEdit from "./pages/CRMOfficeCaller/AdminEdit";
import Adminpanel from "./pages/CRMOfficeCaller/Adminpanel";
import AgentAssignedCalls from "./pages/CRMphonebook/Agentassignedcalls";
import AgentCallSummary from "./pages/CRMphonebook/AgentCallSummary";
import AgentCallSummaryDetails from "./pages/CRMphonebook/AgentCallSummaryDetails";
import AgentList from "./pages/CRMphonebook/AgentList";
import AgentcallList from "./pages/CRMphonebook/AgentcallList";
import AllAnnouncement from "./pages/Notifications/AllAnnouncement";
import AssignCallUsers from "./pages/CRMphonebook/AssignCallUsers";
import AssignRole from "./pages/userManagement/AssignRole";
import BenchList from "./pages/complaince/BenchList";
import BulkCallView from "./pages/CRMphonebook/BulkCallView";
import BulkUpload from "./pages/CRMOfficeCaller/BulkUpload";
import CRMCalls from "./pages/CRMphonebook/CRMCalls";
import CRMDashboard from "./pages/CRMOfficeCaller/CRMDashboard";
import Call from "./pages/Communication/Call";
import CallHistory from "./pages/CRMphonebook/CallHistory";
import CallReport from "./pages/CRMphonebook/CallReport";
import Calling from "./sharedComponent/Calling";
import CallingScreen from "./pages/Communication/Calling";
import Campaign from "./pages/CRMOfficeCaller/Campaign";
import CandidatesData from "./pages/CRMphonebook/CandidatesData";
import Canteen from "./pages/Employee_Wallet/Canteen";
import Categories from "./pages/Employee_Wallet/Categories";
import CategoriesOfficeCaller from "./pages/CRMOfficeCaller/CategoriesOfficeCaller";
import ChangePassword from "./pages/AuthPages/ChangePassword";
import Chat from "./pages/Communication/Chat";
import ChatHistory from "./pages/Communication/ChatHistory";
import ClientManagement from "./pages/ClientManagement/ClientManagement";
import CompleteTask from "./pages/TaskManagement/CompleteTask";
import ComplianceSetting from "./pages/complaince/ComplianceSetting";
import CreateAccount from "./pages/AuthPages/CreateAccount";
import CreateCategories from "./pages/Employee_Wallet/CreateCategories";
import CreateProducts from "./pages/Employee_Wallet/CreateProducts";
import CreateTeam from "./pages/TeamsPreference/CreateTeam";
import CreateToken from "./pages/CRMphonebook/CreateToken";
import CrmApp from "./pages/CRMOfficeCaller/CrmApp";
import CrmPushNotification from "./pages/CRMOfficeCaller/CrmPushNotification";
import CrmSendgrid from "./pages/CRMOfficeCaller/CrmSendgrid";
import CrmWebsite from "./pages/CRMOfficeCaller/Crmwebsite";
import Dashboard from "./pages/Dashboard/Dashboard";
import DevicePunchHistory from "./pages/attendence/DevicePunchHistory";
import Domains from "./pages/CRMOfficeCaller/Domains";
import DownloadAgentReport from "./pages/CRMphonebook/DownloadAgentReport";
import EditEmployeeProfile from "./pages/Employee/EditEmployeeProfile";
import EmailReport from "./pages/CRMOfficeCaller/EmailReport";
import EmployeeAttendence from "./pages/attendence/EmployeeAttendence";
import EmployeeAttendenceReport from "./pages/attendence/EmployeeAttendenceReport";
import EmployeeCode from "./pages/complaince/EmployeeCode";
import EmployeeTimesheetDetails from "./pages/Document/EmployeeTimesheetDetails";
import EmpoloyeeWallet from "./pages/Employee_Wallet/EmpoloyeeWallet";
import EnquiryDetails from "./pages/CRMOfficeCaller/EnquiryDetails";
import FaceDetection from "./pages/attendence/FaceDetection";
import Features from "./pages/CRMOfficeCaller/Features";
import Files from "./pages/Communication/Files";
import ForgotPassword from "./pages/AuthPages/ForgotPassword";
import FrequentCaller from "./pages/CRMphonebook/FrequentCaller";
import GeneralSetting from "./pages/complaince/GeneralSetting";
import GenerateSalarySlip from "./pages/ProjectCosting/GenerateSalarySlip";
import SalaryMasterRoll from "./pages/ProjectCosting/SalaryMasterRoll";
import GoogleAnalytics from "./pages/CRMOfficeCaller/GoogleAnalytics";
import Header from "./sharedComponent/Header";
import HoldTask from "./pages/TaskManagement/HoldTask";
import Holidays from "./pages/Organisation/Holidays";
import HolidaysAndEvents from "./pages/Notifications/HolidaysAndEvents";
import InvalidLeads from "./pages/CRMOfficeCaller/InvalidLeads";
import IssueCard from "./pages/Employee_Wallet/IssueCard";
import LeadListCount from "./pages/CRMOfficeCaller/LeadListCount";
import LeadManagement from "./pages/CRMOfficeCaller/LeadManagement";
import LeadManagementListEdit from "./pages/CRMOfficeCaller/LeadManagementListEdit";
import Leave from "./pages/leave/Leave";
import LeaveApprove from "./pages/leave/LeaveApprove";
import List from "./pages/Employee/List";
import Login from "./pages/AuthPages/Login";
import ManageDepartments from "./pages/Organisation/ManageDepartments";
import ManageDesignations from "./pages/Organisation/ManageDesignations";
import ManageLocations from "./pages/Organisation/ManageLocations";
import ManageShift from "./pages/Organisation/ManageShift";
import ManageTeam from "./pages/TeamsPreference/ManageTeam";
import Meetings from "./pages/Communication/Meetings";
import Modules from "./pages/CRMOfficeCaller/Modules";
import MyAnnouncement from "./pages/Notifications/MyAnnouncement";
import MyAttendence from "./pages/attendence/MyAttendence";
import MyDiary from "./pages/Timesheet/MyDiary";
import NotCallClassification from "./pages/CRMphonebook/NotCallClassification";
import OngoingCalls from "./pages/Communication/OngoingCalls";
import Organisational from "./pages/Document/Organisational";
import OrganizationDetails from "./pages/Organisation/OrganizationDetails";
import Permission from "./pages/userManagement/Permission";
import Personal from "./pages/Document/Personal";
import PrivacyPolicy from "./pages/Organisation/PrivacyPolicy";
import Product from "./pages/Employee_Wallet/Product";
import ProductStock from "./pages/Employee_Wallet/ProductStock";
import Profile from "./pages/Dashboard/Profile";
import ProjectAssigned from "./pages/complaince/ProjectAssigned";
import ProjectAssignment from "./pages/Document/ProjectAssignment";
import ProjectChannels from "./pages/Communication/ProjectChannels";
import ProjectCost from "./pages/ProjectCosting/ProjectCost";
import ProjectCostEnd from "./pages/ProjectCosting/ProjectCostEnd";
import ProjectCostReport from "./pages/ProjectCosting/ProjectCostReport";
import ProjectDeAssign from "./pages/complaince/ProjectDeAssign";
import ProjectDetails from "./pages/Document/ProjectDetails";
import ProjectDocumentation from "./pages/Document/ProjectDocumentation";
import ProjectNotAssigned from "./pages/complaince/ProjectNotAssigned";
import ProjectResources from "./pages/Document/ProjectResources";
import ProjectSchedule from "./pages/Document/ProjectSchedule";
import ProjectTask from "./pages/TaskManagement/ProjectTask";
import ProjectTimesheetDetails from "./pages/Document/ProjectTiemsheetDetails";
import Projects from "./pages/Document/Projects";
import ProposalAttachement from "./pages/CRMOfficeCaller/ProposalAttachement";
import QueryCases from "./pages/CRMOfficeCaller/QueryaCases";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import RechargeWallet from "./pages/Employee_Wallet/RechargeWallet";
import RegisterUserDetails from "./pages/CRMphonebook/RegisterUserDetails";
import Role from "./pages/userManagement/Role";
import SalaryStructure from "./pages/ProjectCosting/SalaryStructure";
import SalesInternationalcall from "./pages/CRMphonebook/SalesInternationalcall";
import ServiceRequest from "./pages/CRMphonebook/ServiceRequest";
import ShortListTask from "./pages/Timesheet/ShortListTask";
import Sidebar from "./sharedComponent/Sidebar";
import StandardTask from "./pages/TaskManagement/StandardTask";
import Store from "./pages/Employee_Wallet/Store";
import Task from "./pages/TaskManagement/Task";
import TaskAllocation from "./pages/TaskManagement/TaskAllocation";
import TaskApprove from "./pages/TaskManagement/TaskApprove";
import TaskList from "./pages/TaskManagement/TaskList";
import TaskNotAddedAgainstProject from "./pages/complaince/TaskNotAddedAgainstProject";
import TaskNotApproved from "./pages/complaince/TaskNotApproved";
import TeamChart from "./pages/Team-Chart/TeamChart";
import TeamNotAssigned from "./pages/complaince/TeamNotAssigned";
import TeamTask from "./pages/TaskManagement/TeamTask";
import Teams from "./pages/Communication/Teams";
import TearmsAmdCondiation from "./pages/Organisation/TearmsAmdCondiation";
import TechCount from "./pages/CRMphonebook/TechCount";
import TimePeriodOver from "./pages/complaince/TimePeriodOver";
import Timesheet from "./pages/Timesheet/Timesheet";
import TimesheetApproval from "./pages/Timesheet/TimesheetApproval";
import TimesheetHistory from "./pages/Timesheet/TimesheetHistory";
import TimesheetNotApproved from "./pages/complaince/TimesheetNotApproved";
import TimesheetNotEntered from "./pages/complaince/TimesheetNotEntered";
import TransactionReceipt from "./pages/Employee_Wallet/TransactionReceipt";
import TwoPlusProjectsAssigned from "./pages/complaince/TwoPlusProjectsAssigned";
import UpdateUserDetails from "./pages/CRMphonebook/UpdateUserDetails";
import Updatewhatsapp from "./pages/CRMphonebook/Updatewhatsapp";
import UploadCandidateOld from "./pages/CRMphonebook/UploadCandidateOld";
import UserActivity from "./pages/complaince/UserActivity";
import UserDetails from "./pages/Organisation/UserDetails";
import UserDetailsForm from "./pages/CRMphonebook/UserDetailsForm";
import UserLastLogin from "./pages/complaince/UserLastLogin";
import UserNotLogin from "./pages/complaince/UserNotLogin";
import UserTracking from "./pages/attendence/UserTracking";
import UserdetailsAgent from "./pages/CRMphonebook/UserdetailsAgent";
import Users from "./pages/CRMphonebook/Users";
import ViewCategory from "./pages/CRMOfficeCaller/ViewCategory";
import ViewDomain from "./pages/CRMOfficeCaller/ViewDomain";
import ViewFeatures from "./pages/CRMOfficeCaller/ViewFeature";
import ViewModule from "./pages/CRMOfficeCaller/ViewModule";
import WalletRecharge from "./pages/Employee_Wallet/WalletRecharge";
import WalletSales from "./pages/Employee_Wallet/WalletSales";
import WhatsappSetting from "./pages/CRMphonebook/WhatsappSetting";
import ZeroCallList from "./pages/CRMphonebook/ZeroCallList";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppContext } from "./context/AppContext";
import NonFunctionalFeatures from "./pages/CRMOfficeCaller/NonFunctionalFeatures";
import ViewNonFunctionalFeatures from "./pages/CRMOfficeCaller/ViewNonFunctionalFeatures";
import ConsultantandSupplier from "./pages/CRMphonebook/Consultantandsupplier";
import Consultant from "./pages/CRMphonebook/Consultant";
import Supplier from "./pages/CRMphonebook/Supplier";
import CreateGroup from "./pages/CRMOfficeCaller/CreateGroup";
import EditGroup from "./pages/CRMOfficeCaller/EditGroup";
import ContactCustomGroup from "./pages/CRMOfficeCaller/ContactCustomGroup";
import DefaultGroup from "./pages/CRMOfficeCaller/DefaultGroup";
import ContactCustomGroupDetails from "./pages/CRMOfficeCaller/ContactCustomGroupDetails";
import DefaultGroupDetails from "./pages/CRMOfficeCaller/DefaultGroupDetails";
import OrganizationRegistration from "./pages/AuthPages/organizationRegistration";
import ShiftTime from "./pages/Organisation/shiftTime";
import WhatsappPromotion from "./pages/CRMphonebook/WhatsappPromotion";
import Academic from "./pages/Organisation/Academic";
import CalendarSettings from "./pages/Organisation/CalendarSettings";

import CustomGroup from "./pages/CRMphonebook/CustomGroup";
import OrganizationInfo from "./pages/Organisation/OrganizationInfo";
import OrgDetails from "./pages/Organisation/OrgInfo/OrgDetails";
import ManageDept from "./pages/Organisation/OrgInfo/ManageDept";
import ManageDesig from "./pages/Organisation/OrgInfo/ManageDesig";
import ManageLoc from "./pages/Organisation/OrgInfo/ManageLocations";
import LeaveSetting from "./pages/Organisation/LeaveSetting";
import PaymentGateway from "./pages/Organisation/Paymentgateway";
import SMSGateway from "./pages/Organisation/SMSgateway";
import IVRSetting from "./pages/Organisation/IVRSetting";
import CreateIVR from "./pages/Organisation/CreateIVR";
import UpdateIVR from "./pages/Organisation/UpdateIVR";
import Setting from "./pages/ChatAdmin/setting/Setting";
import AccessCode from "./pages/ChatAdmin/Accesscode/AccessCode";
import AddQuestions from "./pages/ChatAdmin/AddQuestions/AddQuestions";
import QuestionListing from "./pages/ChatAdmin/QuestionListing/QuestionListing";
import AddSettings from "./pages/ChatAdmin/setting/AddSetting";
import UnAnsweredListing from "./pages/ChatAdmin/unanswered/unanswerd";
import TraineeQues from "./pages/ChatAdmin/traineeques/TraineeQues";
import ConversationFlow from "./pages/ChatAdmin/conversationflow/conversationflow";
import AddConversationFlow from "./pages/ChatAdmin/conversationflow/AddConversationflow";
import AdminChat from "./pages/ChatAdmin/chat/AdminChat";
import DataStat from "./pages/ChatAdmin/Data Statistics/DataStat";
import SubModuleDataStat from "./pages/ChatAdmin/Data Statistics/SubmoduleDataStat";



// import Header from "./sharedComponent/Header";
function App() {
  const {
    sidebar,
    setSidebar,
    backdrop,
    setbackdrop,
    profile,
    setprofile,
    temp,
    settemp,
    userCalling,
    setOpenSessionLogoutModal,
    permissions,
    setPremissions,
  } = useContext(AppContext);

  const currentRoute = useLocation().pathname;
  const navigate = useNavigate();
  const [theme, setTheme] = useState(
    JSON.parse(
      localStorage.getItem("theme") ? localStorage.getItem("theme") : null
    )
  );
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );
  const token = localStorage?.Token && localStorage?.Token;
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token && token) {
        const decode = JSON.parse(atob(token && token?.split(".")[1]));
        if (decode.exp * 1000 < new Date().getTime()) {
          setOpenSessionLogoutModal(true);
        }
      }
    };

    checkTokenExpiration();

    const interval = setInterval(checkTokenExpiration, 10000);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (!logedIn) {
      if (currentRoute === "/ForgotPassword") {
        navigate("/ForgotPassword");
        return;
      }
      if (currentRoute === "/ChangePassword") {
        navigate("/ChangePassword");
        return;
      }
      if (currentRoute === "/CreateAccount") {
        navigate("/CreateAccount");
        return;
      }
      if (currentRoute === "/OrganizationRegistration") {
        navigate("/OrganizationRegistration");
        return;
      }

      navigate("/");
    } else {
      if (currentRoute === "/") {
        // Dashboard
        if (permissions["Employee Wallet"] == true) {
          navigate("/EmpoloyeeWallet");
        } else {
          navigate("/Dashboard");
        }
      }
    }
  }, [logedIn]);

  return (
    <>
      <ToastContainer />
      <div className="App  background_main_wrapper" id="max-width">
        <div
          className={backdrop ? "backdrop-main" : ""}
          onClick={() => {
            setprofile(false);
            settemp(false);
            setbackdrop(false);
          }}
        ></div>

        {currentRoute !== "/" &&
          !currentRoute.includes("/jitsi") &&
          currentRoute !== "/CreateAccount" &&
          currentRoute !== "/ForgotPassword" &&
          currentRoute !== "/ChangePassword" &&
          currentRoute !== "/OrganizationRegistration" && (
            <>
              <Header setLoggedIn={setLoggedIn} />
              <Sidebar setLoggedIn={setLoggedIn} />
            </>
          )}
        {userCalling && <Calling />}

        <Routes>
          <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
          <Route path="CreateAccount" element={<CreateAccount />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="ChangePassword" element={<ChangePassword />} />
          <Route
            path="OrganizationRegistration"
            element={<OrganizationRegistration />}
          />
        </Routes>

        <Routes>
          {/* ================================ Time Sheet ============================ */}
          <Route path="timesheet" element={<Timesheet />} />
          <Route path="timesheethistory" element={<TimesheetHistory />} />
          <Route path="Achivements" element={<Achivements />} />
          <Route path="MyDiary" element={<MyDiary />} />
          {/* ================================ Role MANAGEMENT ROUTING ============================ */}
          <Route path="Role" element={<Role />} />
          <Route path="AssignRole" element={<AssignRole />} />
          <Route path="Permission" element={<Permission />} />
          {/* ================================ TASK MANAGEMENT   ROUTING ============================ */}
          <Route path="tasklist" element={<TaskList />} />
          <Route path="Task" element={<Task />} />
          <Route path="leave" element={<Leave />} />
          <Route path="LeaveApprove" element={<LeaveApprove />} />
          <Route path="TaskApprove" element={<TaskApprove />} />
          <Route path="TaskAllocation" element={<TaskAllocation />} />
          <Route path="AbortTask" element={<AbortTask />} />
          <Route path="HoldTask" element={<HoldTask />} />
          <Route path="StandardTask" element={<StandardTask />} />
          <Route path="CompleteTask" element={<CompleteTask />} />
          <Route path="LeaveSetting333" element={<LeaveSetting />} />
          {/* ================================ TASK MANAGEMENT   ROUTING ============================ */}
          <Route path="ClientManagement" element={<ClientManagement />} />
          <Route path="teamtask" element={<TeamTask />} />
          <Route path="projecttask" element={<ProjectTask />} />
          {/* <Route path="salesReport" element={<SalseReport />} /> */}
          {/* <Route path="purchaseReport" element={<PurchaseReport />} /> */}

          {/* ================================ Notification ROUTING ============================ */}
          <Route path="allAnnouncement" element={<AllAnnouncement />} />
          <Route path="OrgDetails" element={<OrgDetails />} />
          <Route path="ManageDept" element={<ManageDept />} />
          <Route path="ManageDesig" element={<ManageDesig />} />
          <Route path="ManageLoc" element={<ManageLoc />} />
          <Route path="myAnnouncement" element={<MyAnnouncement />} />
          <Route path="holidaysAndEvents" element={<HolidaysAndEvents />} />
          <Route path="Academic" element={<Academic />} />
          <Route path="CalendarSettings" element={<CalendarSettings />} />

          {/* ================================ Notification ROUTING ============================ */}
          {permissions && permissions["View Chat"] && (
            <Route path="chat" element={<Chat />} />
          )}
          {permissions && permissions["View Communication Projects"] && (
            <Route path="projects" element={<ProjectChannels />} />
          )}
          {permissions && permissions["View Teams"] && (
            <Route path="teams" element={<Teams />} />
          )}
          {/* Teams */}
          <Route path="chathistory" element={<ChatHistory />} />
          <Route path="TimesheetApproval" element={<TimesheetApproval />} />
          <Route path="ShortListTask" element={<ShortListTask />} />

          {/* ================================ Attendence ROUTING ============================ */}
          <Route path="attendance" element={<MyAttendence />} />
          <Route path="FaceDetection" element={<FaceDetection />} />
          <Route path="UserTracking" element={<UserTracking />} />
          <Route path="employeeAttendence" element={<EmployeeAttendence />} />
          <Route
            path="EmployeeAttendenceReport"
            element={<EmployeeAttendenceReport />}
          />
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="/Profile/" element={<Profile />}>
            <Route path=":id" element={<Profile />} />
          </Route>
          <Route path="DevicePunchHistory" element={<DevicePunchHistory />} />
          <Route path="teamChart" element={<TeamChart />} />
          {permissions && permissions["View Calls"] && (
            <Route path="Call" element={<Call />} />
          )}
          {permissions && permissions["View Ongoing Calls"] && (
            <Route path="ongoing-calls" element={<OngoingCalls />} />
          )}
          <Route path="Files" element={<Files />} />
          <Route path="achivements" element={<Achivements />} />
          <Route
            path="jitsi/:call_id/:room_id/:username"
            element={<CallingScreen />}
          />
          {/* ================================ DOCUMENT ROUTING ============================ */}
          <Route path="personal" element={<Personal />} />
          <Route
            path="projectdocumentation"
            element={<ProjectDocumentation />}
          />
          <Route path="projectschedule" element={<ProjectSchedule />} />
          <Route path="projectresources" element={<ProjectResources />} />
          <Route path="Project" element={<Projects />} />
          <Route path="ProjectAssignment" element={<ProjectAssignment />} />
          <Route path="organisational" element={<Organisational />} />
          <Route path="projectdetails" element={<ProjectDetails />} />
          <Route path="TearmsAmdCondiation" element={<TearmsAmdCondiation />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="Holidays" element={<Holidays />} />
          <Route path="AboutOrganziation" element={<AboutOrgan />} />
          {permissions && permissions["View Meetings"] && (
            <Route path="meetings" element={<Meetings />} />
          )}
          <Route path="OrganizationDetails" element={<OrganizationDetails />} />
          <Route path="ManageDepartments" element={<ManageDepartments />} />
          <Route path="ManageDesignations" element={<ManageDesignations />} />
          <Route path="ManageLocations" element={<ManageLocations />} />
          <Route path="UserDetails" element={<UserDetails />} />
          <Route path="ManageShift" element={<ManageShift />} />
          <Route path="ShiftMaster" element={<ShiftTime />} />
          <Route path="OrgainsationInfo" element={<OrgDetails />} />
          <Route path="PaymentGateway" element={<PaymentGateway />} />
          <Route path="SMSgateway" element={<SMSGateway />} />
          <Route path="LeaveSetting" element={<LeaveSetting />} />
          <Route path="IVRSetting" element={<IVRSetting />} />

          <Route
            path="EmployeeTimesheetDetails"
            element={<EmployeeTimesheetDetails />}
          />
          <Route
            path="ProjectTimesheetDetails"
            element={<ProjectTimesheetDetails />}
          />

          {/* Complaince */}
          <Route path="TeamNotAssigned" element={<TeamNotAssigned />} />
          <Route path="ProjectNotAssigned" element={<ProjectNotAssigned />} />
          <Route path="ProjectAssigned" element={<ProjectAssigned />} />
          <Route path="ProjectDeAssign" element={<ProjectDeAssign />} />
          <Route
            path="TwoPlusProjectsAssigned"
            element={<TwoPlusProjectsAssigned />}
          />
          <Route
            path="TaskNotAddedAgainstProject"
            element={<TaskNotAddedAgainstProject />}
          />
          <Route path="TaskNotApproved" element={<TaskNotApproved />} />
          <Route path="TimesheetNotEntered" element={<TimesheetNotEntered />} />
          <Route
            path="TimesheetNotApproved"
            element={<TimesheetNotApproved />}
          />
          <Route path="TimePeriodOver" element={<TimePeriodOver />} />
          <Route path="BenchList" element={<BenchList />} />
          <Route path="ComplianceSetting" element={<ComplianceSetting />} />
          <Route path="UserNotlogin" element={<UserNotLogin />} />
          <Route path="UserLastLogin" element={<UserLastLogin />} />
          <Route path="UserActivity" element={<UserActivity />} />

          {/* Teams Preference */}
          <Route path="CreateTeam" element={<CreateTeam />} />
          <Route path="ManageTeam" element={<ManageTeam />} />
          {/* Project Costing */}
          <Route path="ProjectCost" element={<ProjectCost />} />
          <Route path="ProjectCostReport" element={<ProjectCostReport />} />
          <Route path="/ProjectCostEnd/:id" element={<ProjectCostEnd />} />

          {/* Salary Structure */}
          <Route path="SalaryStructure" element={<SalaryStructure />} />
          <Route path="GenerateSalarySlip" element={<GenerateSalarySlip />} />
          {/* Employee Structure */}
          <Route path="List" element={<List />} />
          <Route path="AddEmployee" element={<AddEmployee />} />
          <Route
            path="/EditEmployeeProfile/:id"
            element={<EditEmployeeProfile />}
          />
          <Route
            path="/AllEmployeeAttendanceReport"
            element={<AddEmployeeAttendanceReport />}
          /> 
          <Route path="GeneralSetting" element={<GeneralSetting />} />
          <Route path="SalaryMasterRoll" element={<SalaryMasterRoll />} />
        
          <Route path="/EmployeeCode" element={<EmployeeCode />} />

          {/* Employee Wallet module */}
          <Route path="EmpoloyeeWallet" element={<EmpoloyeeWallet />} />
          <Route path="RechargeWallet" element={<RechargeWallet />} />
          <Route path="WalletSales" element={<WalletSales />} />
          <Route path="TransactionReceipt" element={<TransactionReceipt />} />
          <Route path="WalletRecharge" element={<WalletRecharge />} />
          <Route path="Store" element={<Store />} />
          <Route path="Categories" element={<Categories />} />
          <Route path="CreateCategories" element={<CreateCategories />} />
          <Route path="Product" element={<Product />} />
          <Route path="ProductStock" element={<ProductStock />} />
          <Route path="AddProductStock" element={<AddProductStock />} />
          <Route path="Canteen" element={<Canteen />} />
          <Route path="IssueCard" element={<IssueCard />} />
          <Route path="CreateProducts" element={<CreateProducts />} />
          {/* <Route
            path="/EditEmployeeProfile/:id"
            element={<EditEmployeeProfile />}
          /> */}
          {/* ================================ CRM PhoneBook ============================ */}

          {/* ================================ CRM OfficeCaller ============================ */}
          <Route path="CRMDashboard" element={<CRMDashboard />} />
          <Route path="CRMCalls" element={<CRMCalls />} />
          <Route path="LeadManagement" element={<LeadManagement />} />
          <Route
            path="NotCallClassification"
            element={<NotCallClassification />}
          />
          <Route path="RegisterUserDetails" element={<RegisterUserDetails />} />
          <Route path="WhatsappSetting" element={<WhatsappSetting />} />
          <Route path="CreateToken" element={<CreateToken />} />
          <Route path="CreateIVR" element={<CreateIVR />} />
          <Route path="editIVR/:id" element={<UpdateIVR />} />
          <Route path="UserDetailsForm" element={<UserDetailsForm />} />
          <Route path="UpdateUserDetails" element={<UpdateUserDetails />} />
          <Route path="AgentList" element={<AgentList />} />
          <Route path="AgentAssignedCalls" element={<AgentAssignedCalls />} />
          <Route path="CallHistory" element={<CallHistory />} />
          <Route path="Users" element={<Users />} />
          <Route path="UploadCandidateOld" element={<UploadCandidateOld />} />
          <Route path="Domains" element={<Domains />} />
          <Route path="BulkUpload" element={<BulkUpload />} />
          <Route path="ViewDomain" element={<ViewDomain />} />

          <Route path="AssignCallUsers" element={<AssignCallUsers />} />
          <Route path="BulkCallView" element={<BulkCallView />} />
          <Route path="TechCount" element={<TechCount />} />
          <Route path="DownloadAgentReport" element={<DownloadAgentReport />} />
          <Route
            path="LeadManagementListEdit"
            element={<LeadManagementListEdit />}
          />
          <Route path="LeadListCount" element={<LeadListCount />} />

          <Route
            path="SalesInternationalcall"
            element={<SalesInternationalcall />}
          />

          <Route path="Updatewhatsapp/:id" element={<Updatewhatsapp />} />

          <Route path="CrmPushNotification" element={<CrmPushNotification />} />
          <Route path="CrmSendgrid" element={<CrmSendgrid />} />
          <Route path="Addwebsite" element={<Addwebsite />} />

          <Route path="CrmWebsite" element={<CrmWebsite />} />
          <Route path="GoogleAnalytics" element={<GoogleAnalytics />} />

          <Route path="UserdetailsAgent" element={<UserdetailsAgent />} />
          <Route
            path="AgentCallSummaryDetails"
            element={<AgentCallSummaryDetails />}
          />

          <Route path="Adminpanel" element={<Adminpanel />} />

          <Route path="CrmApp" element={<CrmApp />} />

          <Route path="AgentCallSummary" element={<AgentCallSummary />} />
          <Route path="AgentcallList" element={<AgentcallList />} />
          <Route path="EnquiryDetails" element={<EnquiryDetails />} />
          <Route path="FrequentCaller" element={<FrequentCaller />} />
          <Route path="ZeroCallList" element={<ZeroCallList />} />
          <Route path="ServiceRequest" element={<ServiceRequest />} />
          <Route path="CallReport" element={<CallReport />} />
          <Route path="CandidatesData" element={<CandidatesData />} />
          <Route path="InvalidLeads" element={<InvalidLeads />} />
          <Route path="ProposalAttachement" element={<ProposalAttachement />} />

          <Route path="EmailReport" element={<EmailReport />} />
          <Route path="Campaign" element={<Campaign />} />

          <Route path="QueryCases" element={<QueryCases />} />
          <Route path="Admin" element={<Admin />} />
          <Route path="AdminEdit" element={<AdminEdit />} />
          <Route
            path="CategoriesOfficeCaller"
            element={<CategoriesOfficeCaller />}
          />
          <Route
            path="NonFunctionalFeatures"
            element={<NonFunctionalFeatures />}
          />
          <Route path="ViewCategory" element={<ViewCategory />} />
          <Route path="Modules" element={<Modules />} />
          <Route path="Features" element={<Features />} />
          <Route path="ViewModule" element={<ViewModule />} />
          <Route path="ViewFeatures" element={<ViewFeatures />} />
          <Route
            path="ViewNonFunctionalFeatures"
            element={<ViewNonFunctionalFeatures />}
          />
          <Route path="Consultant" element={<Consultant />} />
          <Route path="Supplier" element={<Supplier />} />
          <Route path="CreateGroup" element={<CreateGroup />} />
          <Route path="EditGroup" element={<EditGroup />} />
          <Route path="ContactCustomGroup" element={<ContactCustomGroup />} />
          <Route path="DefaultGroup" element={<DefaultGroup />} />
          <Route
            path="ContactCustomGroupDetails"
            element={<ContactCustomGroupDetails />}
          />
          <Route path="DefaultGroupDetails" element={<DefaultGroupDetails />} />
          <Route path="WhatsappPromotion" element={<WhatsappPromotion />} />

          <Route path="CustomGroup" element={<CustomGroup />} />

            <Route path="/adminChat" element={<AdminChat />} />
            {/* ChatBot Admin New Added */} 

            <Route path="/ChatUSer" element={<Chat />} />

          <Route
              path="/setting"
              element={<Setting theme={theme} setTheme={setTheme} />}
            />
            <Route path="/accesscode" element={<AccessCode />} />
            <Route path="/addQuestion" element={<AddQuestions />} />
            <Route path="/editQuestion/:id" element={<AddQuestions />} />
            <Route path="/questionListing" element={<QuestionListing />} />
            <Route
              path="/addSetting"
              element={<AddSettings theme={theme} setTheme={setTheme} />}
            />
            <Route
              path="/addSetting/:id"
              element={<AddSettings theme={theme} setTheme={setTheme} />}
            />
            <Route path="/unAnsweredListing" element={<UnAnsweredListing />} />
            <Route path="/traineeQues" element={<TraineeQues />} />
            <Route path="/converstionflow" element={<ConversationFlow />} />
            <Route path="/addconverstioflow" element={<AddConversationFlow />} />
            <Route path="/dataStatistics" element={<DataStat/>} />
            <Route path="/submoddataStat" element={<SubModuleDataStat/>} />
        </Routes>
      </div>
    </>
  );
}

export default App;
