import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import shareIcon from "../../assets/images/ic_export1.svg";
import BookIcon from "../../assets/images/ic_excel.svg";
import searchicon from "../../assets/images/ic_search.svg";
import optionIcon from "../../assets/images/bx_edit.svg";
import downArrow from "../../assets/images/downArrow.png";
import upArrow from "../../assets/images/upArrow.png";
import DelectAc from "../../assets/images/DelectAc.svg";
import { Button, Carousel } from "react-bootstrap";
import Editac from "../../assets/images/Editac.svg";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import disabled_delete from "../../assets/images/disabled_delete.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Accordion, Dropdown } from "react-bootstrap";
import addBtn from "../../assets/images/addBtn.svg";
import "../../assets/css/Timesheet.scss";
import Modal from "react-bootstrap/Modal";
import redCross from "../../assets/images/redCross.svg";
import watch from "../../assets/images/watch.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import Loader from "../Loader";
import Pagination from "../../sharedComponent/Pagination";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const ProjectResources = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [dropdownacc, setDropdownacc] = useState(false);
  const [resorceList, setResorceList] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;

  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };
  const [projectData, setProjectData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [excelLoading, setExcelLoading] = useState(false);
  const [excelLoading2, setExcelLoading2] = useState(false);

  const searchedDataPageCounts =
    resorceList &&
    resorceList.length &&
    resorceList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    PostCallWithErrorResponse(ApiConfig.PROJRESOURCE_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadExcelFilePList = (e, ProjectId) => {
    console.log("Project ID---------", ProjectId);
    setExcelLoading2(true);
    e.preventDefault();
    PostCallWithErrorResponse(
      ApiConfig.PROJRESOURCE_LIST_DOWNLOAD_EXCEL,
      JSON.stringify({
        ProjectId: Number(ProjectId),
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading2(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading2(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    PostCallWithErrorResponse(
      ApiConfig.PROJECT_RESOURCE_LIST,
      JSON.stringify({
        EmployeeId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setResorceList(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getProjectTaskApi = (id) => {
    PostCallWithErrorResponse(
      ApiConfig.GETPROJECT_TEAM_TASK,
      JSON.stringify({
        ProjectId: id,

        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setTaskList(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Project Resources</h6>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper m-0">
                <input
                  type="text"
                  placeholder="Search Project"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper-main ">
                <p>Entries per page</p> &nbsp;
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
              <div className="entries-wrapper-main ">
                <p>Team List</p> &nbsp;
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              {permissions && permissions["Project Resources Bulk Upload"] && (
                <div className="shareIcon mx-3">
                  <img src={shareIcon} className="innerIconsBtn" alt="" />
                </div>
              )}
              {permissions && permissions["Project Resources Export"] && (
                <div className="booksIcon">
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={BookIcon}
                      onClick={(e) => downloadExcelFile(e)}
                      className="innerIconsBtn"
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {/* <div className="table-wrapper">
                        <table>
                            <tr className="firstTabelRow">
                                <th>Sr. no</th>
                                <th>Project</th>
                                <th>Total Budget Hrs</th>
                                <th>Remark</th>
                            </tr>
                        </table>
                    </div> */}

          {resorceList && resorceList.length < 0 ? (
            <Loader />
          ) : (
            <Accordion id="scrol-tbl" className="table-wrapper-af">
              <Accordion.Item
                eventKey=""
                className="topAccordiance"
                id="no-scrol-tbl"
              >
                <Accordion.Header>
                  <div className="table-wrapper project-resources">
                    <table id="tbl-rowBld">
                      <tr className="firstTabelRow">
                        <th className="serialnumber2">Sr.no</th>
                        <th className="projectname">Project Name</th>
                        <th className="noofres">No. of Resources</th>
                        <th className="startdate">Start Date</th>
                        <th className="enddate">End Date</th>
                      </tr>
                    </table>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
              {resorceList && resorceList.length > 0 ? (
                resorceList
                  .filter((i, index) =>
                    i.ProjectName.toLowerCase().includes(
                      searchValue.toLowerCase()
                    )
                  )
                  .slice(startIndex, endIndex)
                  .map((resorce, index) => {
                    return (
                      <Accordion.Item eventKey={("resorce", index)}>
                        {console.log("Resource--------", resorce)}
                        <Accordion.Header>
                          <div className="table-wrapper table-row-custom-padding2">
                            <table>
                              <tr
                                className="table-row-custom bodypadding"
                                onClick={() => {
                                  setDropdownacc(!dropdownacc);
                                  getProjectTaskApi(resorce.ProjectId);
                                }}
                              >
                                <td>{index + 1} </td>
                                <td>{resorce.ProjectName}</td>
                                <td>{resorce.Count}</td>
                                <td> {resorce.startdate}</td>
                                <td>{resorce.enddate}</td>
                                <td>
                                  {dropdownacc == true ? (
                                    <img src={upArrow} alt="" />
                                  ) : (
                                    <img src={downArrow} alt="" />
                                  )}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="AccordianBody">
                            <table className="project-resources-table">
                              <tr className="table-row-custom bodypadding">
                                <td>Sr.No.</td>
                                <td>Employee Name</td>
                                {/* <td>Task Name</td> */}
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Total Working Hrs</td>
                                <td>
                                  {permissions &&
                                  permissions["Project Resources Excel"] ? (
                                    excelLoading2 ? (
                                      <div
                                        class="spinner-border"
                                        role="status"
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                          //marginTop: "5px",
                                          //marginLeft: "5px",
                                          color: "#5ac8fa",
                                        }}
                                      ></div>
                                    ) : (
                                      <img
                                        className="downld-exl "
                                        src={BookIcon}
                                        alt=""
                                        style={{
                                          paddingRight: 1,
                                          backgroundColor: "#EEF8FD",
                                        }}
                                        onClick={(e) =>
                                          downloadExcelFilePList(
                                            e,
                                            resorce.ProjectId
                                          )
                                        }
                                      />
                                    )
                                  ) : null}
                                </td>
                              </tr>
                              <tbody>
                                {taskList && taskList ? (
                                  taskList.map((pro, index) => {
                                    return (
                                      <tr
                                        className="tabel-body-acordince"
                                        key={"pro" + index}
                                      >
                                        <td>{index + 1}</td>
                                        <td>{pro.EmployeeName}</td>
                                        {/* <td className="break-ln-spaces">{pro.TaskName}</td> */}
                                        <td>{pro.Startdate}</td>
                                        <td>{pro.Enddate}</td>
                                        <td>{pro.ActualHours}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr className="ErrorDataNotFound">
                                    <td colSpan={6}>Data Not Found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
              ) : (
                <tr className="ErrorDataNotFound">
                  <td colSpan={6}>Data Not Found</td>
                </tr>
              )}
            </Accordion>
          )}

          {/* pagination */}
          <Pagination
            data={resorceList}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={searchedDataPageCounts}
          />
          {/* <div className="ligthpagination">
            <div className="pagination_btn">
              <button>&lt;&lt;</button>
              <button className="backgroundPagination">1</button>
              <button>2</button>
              <button>3</button>
              <button>4</button>
              <button>&gt;&gt;</button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ProjectResources;
