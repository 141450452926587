import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Form from "react-bootstrap/Form";
const Domains = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);
  const [showPersonal, setShowPersonal] = useState(false);
  const [showedit, setShowedit] = useState(false);

  const [showdelete, setDelete] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const domaindata = [
    {
      srno: "1",
      domain: "CRM",
      icon: "",
      complexity: "3",
    },
    {
      srno: "2",
      domain: "Chatbot App",
      icon: "",
      complexity: "1",
    },
    {
      srno: "3",
      domain: "Non-governmental Oranganization",
      icon: "",
      complexity: "1",
    },
    {
      srno: "4",
      domain: "Services",
      icon: "",
      complexity: "1",
    },
    {
      srno: "5",
      domain: "Ecommerce",
      icon: "",
      complexity: "1",
    },
    {
      srno: "6",
      domain: "Accounting",
      icon: "",
      complexity: "1",
    },
    {
      srno: "7",
      domain: "Travel Booking",
      icon: "",
      complexity: "1",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="main-chat">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Domains</h6>

          <button
            type="button"
            className="create-btn-sub-header"
            variant="primary"
            onClick={() => setShowPersonal(true)}
          >
            + Add Domains
          </button>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search "
                // onChange={(e) => {
                //   setSearchValue(e.target.value);
                //   setCurrentPage(0);
                // }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                // onChange={(e) => {
                //   setUsersPerPage(e.target.value);
                //   setCurrentPage(0);
                // }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </div>
          </div>

          <div className="import-export-btn-wrapper mt-4">
            <div className="booksIcon">
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr No.</th>
                <th scope="col">Domain</th>

                <th scope="col">Icon</th>
                <th scope="col">Domain Complexity</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {domaindata.map((item, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{item.srno}</td>
                  <td>{item.domain}</td>

                  <td> {item.icon}</td>
                  <td>{item.complexity}</td>
                  <td>
                    <div>
                      <Link onClick={() => setShowedit(true)}>
                        <img src={edit} alt="Image 1" className="cell-image" />
                      </Link>
                      <Link to="/ViewDomain">
                        <img
                          src={eye}
                          alt="Image 2"
                          className="cell-image ml-1"
                        />
                      </Link>

                      <Link onClick={() => setDelete(true)}>
                        <img
                          src={deleteicon}
                          alt="Image 2"
                          className="cell-image ml-1"
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>

      {/* Add Domain Modal*/}

      <Modal
        size="xl"
        show={showPersonal}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Domain
          </Modal.Title>
          <div className="rightAxe" onClick={() => setShowPersonal(false)}>
            <img src={Axe} alt="" />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Domain *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Icon
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Domain Complexity *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="12">1</option>
                    <option value="13">2</option>
                    <option value="14">3</option>
                    <option value="15">4</option>
                    <option value="16">5</option>
                    <option value="17">6</option>
                    <option value="18">7</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button className="btn-width saveBtn">Save</button>
          </div>
        </Form>
      </Modal>

      {/* Edit  Modal*/}

      <Modal
        size="xl"
        show={showedit}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Domain
          </Modal.Title>
          <div className="rightAxe" onClick={() => setShowedit(false)}>
            <img src={Axe} alt="" />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Domain *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Icon *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Domain Complexity *
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default Select Priority"
                  >
                    <option value="12">1</option>
                    <option value="13">2</option>
                    <option value="14">3</option>
                    <option value="15">4</option>
                    <option value="16">5</option>
                    <option value="17">6</option>
                    <option value="18">7</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => setShowedit(false)}
            >
              Cancel
            </button>
            <button className="btn-width saveBtn">Update</button>
          </div>
        </Form>
      </Modal>

      {/* Delete Modal*/}
      <Modal
        className="delete-news-modal"
        show={showdelete}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this Domain ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
            onClick={() => setDelete(false)}
          >
            Cancel
          </button>
          <button className="btn-width saveBtn">Delete</button>
        </div>
      </Modal>
    </div>
  );
};

export default Domains;
