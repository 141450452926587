import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import BarChart from "../../component/Barchart.js";
import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Table } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";

const SalesInternationalcall = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const dataArrayLocation = [
    {
      id: 1,
      WHATSAPP: "27",

      Sources: "738374847893",
      DATE: "12/02/2002",
    },

    {
      id: 2,
      WHATSAPP: "2",

      Sources: "738374847893",
      DATE: "12/02/2002",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataArrayLocation.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(dataArrayLocation.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2">Call Assigning International</h6>
            <button className="custoBtnForCrmCall" onClick={handleShow}>
              <a className=" textcolor">Assign</a>
            </button>
          </div>

          <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input type="text" placeholder="Search here" />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper mt-0 cus-mb">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  value={selectedRows}
                  onChange={handleSelectChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>

          <div
            className="  d-flex align-items-center justify-content-center "
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          ></div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead className="firstTabelRow">
                <tr classname="row-hover modal-footer-table-body-tr">
                  <th></th>
                  <th>SR.NO</th>
                  <th>MOBILE NUMBER</th>
                  <th>CALL ADDED</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item.id} className="table-row-custom">
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>{item.WHATSAPP}</td>
                    <td>{item.Sources}</td>
                    <td>{item.DATE}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Call Assigning International
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="modalProp mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Client Name
            </label>
            <select
              className="tasKshowusser"
              // onChange={(e) => setService(e.target.value)}
            >
              <option>Agent List</option>
              <option>Thushara</option>
              <option>sobhisha</option>
            </select>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button className="custoBtnForCrmCall" onClick={handleClose}>
              Close
            </button>
            <button className=" ml-5 custoBtnForCrmCall" onClick={handleClose}>
              Save Changes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SalesInternationalcall;
