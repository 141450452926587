import React from "react";

const BootstrapSpinner = () => {
  return (
    <div
      class="spinner-border text-info"
      role="status"
      style={{ marginLeft: "50%" }}
    ></div>
  );
};

export default BootstrapSpinner;
