import BarChart from "../../component/Barchart.js";
import Pagination from "../../sharedComponent/Pagination.js";
import React, { useContext, useEffect, useState } from "react";
import ic_excel from "../../assets/images/ic_excel.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import phone from "../../assets/images/phone.svg";
import phonetwentyfour from "../../assets/images/phonetwentyfour.svg";
import profileblue from "../../assets/images/profileblue.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Form, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const Phonebookdash = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [key, setKey] = useState("all");
  console.log("Key data >>>>>>>>>>", key);
  const dataArray = [
    {
      id: 1,
      name: "Call",
      Count: "23232",
      img: phone,
    },
    { id: 2, name: "Users", Count: "23232", img: profileblue },
    { id: 3, name: "Candidates", Count: "23232", img: profileblue },
    { id: 4, name: "Agents", Count: "23232", img: profileblue },
    {
      id: 5,
      name: "Not Call Classification",
      Count: "23232",

      img: phone,
    },
    { id: 6, name: "Zero Call List", Count: "23232", img: phone },

    { id: 7, name: "Services Request", Count: "23232", img: phonetwentyfour },
  ];

  const dataArrayLocation = [
    { id: 1, name: "Maharashtra", Count: "23232" },
    { id: 2, name: "Karnataka", Count: "23232" },
    { id: 3, name: "Gujarat", Count: "23232" },
    { id: 1, name: "Maharashtra", Count: "23232" },
    { id: 2, name: "Karnataka", Count: "23232" },
    { id: 3, name: "Gujarat", Count: "23232" },
  ];
  const dataMap = new Map(dataArray.map((item) => [item.id, item]));

  return (
    <>
      <div className="phonebook-dash-container">
        <div className="phonebook-dash-row">
          {Array.from(dataMap.values()).map((item) => (
            <div key={item.id} className="phonebook-dash-item">
              <div className="row align-items-center">
                <div className="col-6  align-items-center ">
                  <img src={item.img} />
                </div>
                <div className="col-6 align-items-center">
                  <p className="item-title">{item.name}</p>
                  <p className="item-count">{item.Count}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="WeeklyMain">
        <div className="rowstyle">
          <div>
            <p className="summarybld"> Weekly call summary bar chart</p>
          </div>
          <select
            className="form_bgnd option"
            // onChange={(e) => setService(e.target.value)}
          >
            <option>Last 7 Days</option>
            <option>Weekly</option>
            <option>monthly</option>
          </select>
        </div>

        <BarChart />
      </div>

      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header">
          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Search "
              // onChange={(e) => {
              //   setSearchValue(e.target.value);
              //   setCurrentPage(0);
              // }}
            />
            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
          </div>
          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              // onChange={(e) => {
              //   setUsersPerPage(e.target.value);
              //   setCurrentPage(0);
              // }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
          </div>
        </div>

        <div className="import-export-btn-wrapper mt-4">
          <button>
            {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
            <img
              src={ic_excel}
              // onClick={(e) => downloadExcelFile(e)}
              alt=""
            />
            {/* )} */}
          </button>
        </div>
      </div>

      <div className="role-content">
        <Form noValidate>
          <div id="tabsBelow" className="mt-4 ">
            <div className="registerbutton mb-3">
              <div></div>
              <div>
                <button className="sml-btn-sub-header">
                  <Link to="" className="details-link ">
                    Domestic call Count: 3594
                  </Link>
                </button>
                <button className="sml-btn-sub-header resdom">
                  <Link to="" className="details-link">
                    International call Count: 18481
                  </Link>
                </button>
              </div>
            </div>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              // className="mb-0"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="all" title="DOMESTIC">
                <table id="tabledash">
                  <thead className="">
                    <tr className="col-5">
                      <th>Location</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataArrayLocation.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.Count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-5 lefttg">
                  <Pagination />
                </div>
              </Tab>

              <Tab eventKey="inboundCalls" title="INTERNATIONAL">
                <table id="tabledash">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataArrayLocation.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.Count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-5 lefttg">
                  <Pagination />
                </div>
              </Tab>
            </Tabs>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Phonebookdash;
