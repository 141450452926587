import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
//import Editac from "../../assets/images/Editac.svg";
import Editac from "../../assets/images/bx_edit.svg";
import DelectAc from "../../assets/images/DelectAcBlc.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
  DeleteCallWithErrorResponseWithTokenQuery,
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import moment from "moment";
import { notifySuccess } from "../../toaster/notify";
import { Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";

const Holidays = () => {
  const { sidebar, Dark, permissions } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [location, setLocation] = useState([]);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [deletecnfm, setdeletecnfm] = useState("");

  const [addDataHoliday, setAddDataHoliday] = useState({
    HolidayName: "",
    Date: "",
    LocationId: '',
  });

  console.log("addDataHoliday,", addDataHoliday);
  const [holidayData, setHolidayData] = useState([]);

  const changeData = (e) => {
    setAddDataHoliday({ ...addDataHoliday, [e.target.name]: e.target.value });
    const { name, value } = e.target;
    setAddDataHoliday({
      ...addDataHoliday,
      [name]: value,
    });
  };
  const today = new Date().toISOString().split("T")[0];
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Get Location
  const getLocation = () => {
    simpleGetCallWithToken(ApiConfig.GET_LOCATION)
      .then((res) => {
        if (res.Success === true) {
          setLocation(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    holidayData &&
    holidayData.length &&
    holidayData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const fetchHolidays = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_HOLIDAYS_LIST)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setHolidayData(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchHolidays();
    getLocation();
  }, []);
  const [btnLoader, setbtnLoader] = useState(false);

  const createHoliday = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setbtnLoader(true);  // Start button loader
      PostCallWithErrorResponse(
        ApiConfig.CREATE_HOLIDAY,
        JSON.stringify(addDataHoliday)
      )
        .then((res) => {
          setShow(false);  // Close modal after success
  
          if (res.json.Success) {
            fetchHolidays();  // Refresh the holiday list
            notifySuccess("Holiday added successfully");
          } else {
            console.log("error", res);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setbtnLoader(false);  // Stop button loader
        });
    }
  };
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const getSingleHoliday = (id) => {
    simpleGetCallWithToken(ApiConfig.GET_HOLIDAY + `?id=${id}`)
      .then((res) => {
        if (res.Success == true) {
          let dateStr = res.Data.HolidayDate;
          let formattedDate = formatDate(dateStr);
          setAddDataHoliday({
            HolidayId: res?.Data?.Id,
            HolidayName: res?.Data?.HolidayName,
            Date: formattedDate,
            LocationId: res?.Data?.LocationId,
          });
          console.log("LocationId--->", Number(addDataHoliday?.LocationId));
        }
        console.log("Edit Data", res);
      })
      .catch((err) => {
        throw err;
      });
  };

  const updateHoliday = (e) => {
    e.preventDefault();
    setbtnLoader(true);
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_HOLIDAY,
      JSON.stringify(addDataHoliday)
    )
      .then((res) => {
        setShowEdit(false);
        if (res.json.Success) {
          fetchHolidays();
          notifySuccess("Holiday Updated successfully");
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setbtnLoader(false);
      });
  };

  const deleteHoliday = (id) => {
    DeleteCallWithErrorResponseWithTokenQuery(
      ApiConfig.DELETE_HOLIDAY + `?holidayid=${id}`
    )
      .then((res) => {
        console.log("Delete Selected Holiday", res);
        if (res.json.Success) {
          fetchHolidays();
          notifySuccess("Record deleted Successfully");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Calendar & Holiday</h6>
            {permissions && permissions["Add Holiday"] && (
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  setShow(true);
                  setAddDataHoliday({
                    HolidayName: "",
                    Date: "",
                    LocationId: 0,
                  });
                }}
              >
                + Add Holiday{" "}
              </button>
            )}
          </div>
          <div className="role-table-header">
            {/* <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(0);
                }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div> */}
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>
                  25
                </option>
              </select>
            </div>
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {holidayData && holidayData.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>Holiday Name</th>
                          <th>Holiday Date</th>
                          <th>Location Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {holidayData
                          .slice(startIndex, endIndex)
                          .map((data, index) => {
                            return (
                              <tr className="table-row-custom" key={index}>
                                <td>{startIndex + index + 1}</td>
                                <td>{data.HolidayName}</td>
                                <td>
                                  {data.HolidayDate
                                    ? moment(data.HolidayDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {data.LocationId ? (
                                    location &&
                                    location.length > 0 &&
                                    location.find(
                                      (item) =>
                                        item?.LocationId === data?.LocationId
                                    )?.LocationName
                                  ) : (
                                    <span className="text-danger">{"-"}</span>
                                  )}
                                </td>
                                <td>
                                  {permissions &&
                                    permissions["Edit Holiday"] && (
                                      <img
                                        src={Editac}
                                        className="mx-2 editBtn"
                                        onClick={() => {
                                          setShowEdit(true);
                                          getSingleHoliday(data.Id);
                                          setAddDataHoliday({
                                            ...addDataHoliday,
                                            HolidayId: data.Id,
                                          });
                                        }}
                                        alt=""
                                      />
                                    )}
                                  {permissions &&
                                    permissions["Delete Holiday"] && (
                                      <img
                                        onClick={() => {
                                          handleShow4();
                                          setdeletecnfm(data.Id);
                                          //deleteHoliday(data.Id);
                                        }}
                                        src={DelectAc}
                                        alt=""
                                      />
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        </div>
        <Pagination
          data={holidayData}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>

      {/* Add Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form noValidate validated={validated} onSubmit={createHoliday}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Add Holiday
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShow(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Name
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="HolidayName"
                value={addDataHoliday.HolidayName}
                type="text"
                className="date-label-input tasKCategory1 focusBlc"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter holiday name
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Date
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="Date"
                value={addDataHoliday.Date}
                type="date"
                min={today}
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter holiday date
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label 
                htmlFor=""
               className="enter-role-text mt-2"
               style={{ fontSize: "14px" }}
               >
                Location Name
              </label>
              <select
                required
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setAddDataHoliday({
                    ...addDataHoliday,
                    LocationId: Number(e.target.value),
                  });
                }}
                name="LocationId"
                value={addDataHoliday?.LocationId}
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              >
                <option value="">Select City</option>
                {location &&
                  location.length > 0 &&
                  location.map((item, idx) => {
                    return (
                      <option value={item?.LocationId} key={"location" + idx}>
                        {item?.LocationName}
                      </option>
                    );
                  })}
              </select>
              <Form.Control.Feedback type="invalid">
                Please select location
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
  <button
    className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
    onClick={() => setShow(false)}
    type="button"
    disabled={btnLoader}  // Disable cancel button while loading
  >
    Cancel
  </button>
  <button
    className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
    type="submit"
    disabled={btnLoader}  // Disable add button while loading
  >
    {btnLoader ? (
      <div className="d-flex justify-content-center align-items-center">
        {/* <span className="me-2">Loading...</span> */}
        <ClipLoader color="#fff" size={18} />  
      </div>
    ) : (
      "Add"
    )}
  </button>
</Modal.Footer>

        </Form>
      </Modal>

      {/* Edit Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showEdit}
        onHide={() => setShowEdit(false)}
      >
        <Form noValidate validated={validated} onSubmit={updateHoliday}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Edit Holiday
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShowEdit(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Name
              </label>
              <input
                name="HolidayName"
                value={addDataHoliday?.HolidayName}
                onChange={(e) =>
                  setAddDataHoliday({
                    ...addDataHoliday,
                    HolidayName: e.target.value,
                  })
                }
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Date
              </label>
              <input
                onChange={(e) => {
                  changeData(e);
                }}
                name="Date"
                value={formatDate(addDataHoliday.Date)}
                format="dd-mm-yyyy"
                type="date"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>
            <div className="modalProp mb-4">
              <label htmlFor="onPriority" className="enter-role-text ms-2">
                Location Name
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setAddDataHoliday({
                    ...addDataHoliday,
                    LocationId: Number(e.target.value),
                  });
                }}
                name="LocationId"
                value={Number(addDataHoliday?.LocationId)}
              >
                <option value={""}>Select City</option>
                {location &&
                  location.length > 0 &&
                  location.map((item, idx) => {
                    return (
                      <option value={item?.LocationId} key={"location" + idx}>
                        {item?.LocationName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            {/* <div className="Bottom-btn-arbaz-responsive"> */}
            <button
              type="button"
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setShowEdit(false)}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              // onClick={addTaskByAdmin}
              type="submit"
            >
              Update
            </button>
            {/* </div> */}
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Confirmation popup Announcement */}

      <Modal
        className="delete-news-modal"
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Confirmation</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure, You want to delete?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose4}>
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={() => {
              deleteHoliday(deletecnfm);
              handleClose4();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Holidays;
