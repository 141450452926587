import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import plus from "../../assets/images/plus_officecaller.svg";
import { Link, Navigate } from "react-router-dom";
import BookIcon from "../../assets/images/ic_excel.svg";

import { useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";

const categories = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const months = [
  "Name",
  "Email",
  "Phone",
  "City",
  "Country",
  "CreatedOn",
  "Action",
];
const data = [
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Total
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // IOT
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Services
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Booking
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Social
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Commerce
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Health
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Education
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Donation
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Game
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Transport
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Food Delivery
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Real Estate
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Productivity
  [
    "Glenn",
    "glenndclowney@gmail.com",
    "6786992324",
    "Not Available",
    "India",
    "05-06-24",
    "",
  ], // Suggestion
];

const InvalidLeads = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);

  const navigate = useNavigate();

  return (
    <div>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Invalid Leads</h6>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search "
                  // onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //   setUsersPerPage(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>

            <div className="import-export-btn-wrapper mt-4">
              <div className="reset mr-2">
                <p>Reset</p>
              </div>
              <div className="booksIcon">
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>
          <h6 className="mt-2 align-self-end d-flex justify-content-end">
            Invalid Phone Numbers Count: 0
          </h6>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th className="header">Sr.No</th>
                  {months.map((month, index) => (
                    <th key={index} className="header">
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {categories.map((category, catIndex) => (
                  <tr key={catIndex} className="table-row-custom">
                    <td>{category}</td>
                    {data[catIndex].map((value, monthIndex) => (
                      <td key={monthIndex}>
                        {monthIndex === data[catIndex].length - 1 ? (
                          <div>
                            <Link to="/LeadManagementListEdit">
                              <img
                                src={edit}
                                alt="Image 1"
                                className="cell-image"
                              />
                            </Link>
                            <Link to="/LeadListCount">
                              <img
                                src={eye}
                                alt="Image 2"
                                className="cell-image ml-1"
                              />
                            </Link>

                            <img
                              src={plus}
                              alt="Image 2"
                              className="cell-image"
                            />
                          </div>
                        ) : (
                          value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
      // data={EmployeeList}
      // pageChangeHandler={pageChangeHandler}
      // usersPerPage={usersPerPage}
      // currentPage={currentPage}
      // searchValue={searchValue}
      // searchedDataPageCounts={searchedDataPageCounts}
      />
    </div>
  );
};

export default InvalidLeads;
