import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import ic_excel from "../../assets/images/ic_excel.svg";
import ic_export1 from "../../assets/images/ic_export1.svg";


const FaceDetection = () => {
    const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
        useContext(AppContext);
    const [excelLoading, setExcelLoading] = useState(false);

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <div className="textHeader">
                            <h6 className="mt-2">Face Detection</h6>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center role-searchbar-and-btns RspSalary">
                        <div className="role-table-header">
                            <div className="entries-wrapper">
                                <select
                                    className="form-select select-drop-icon"
                                    aria-label="Default select example"
                                // onChange={(e) => {
                                //     setUsersPerPage(e.target.value);
                                //     setCurrentPage(0);
                                // }}
                                >
                                    <option value="">Employee Name</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                            <div className="entries-wrapper">
                                <select
                                    className="form-select select-drop-icon"
                                    aria-label="Default select example"
                                // onChange={(e) => {
                                //     setUsersPerPage(e.target.value);
                                //     setCurrentPage(0);
                                // }}
                                >
                                    <option value="">Location</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                            <div className="entries-wrapper">
                                <input type="date" className="form-control bg-white" style={{color: "gray"}} />
                            </div>
                        </div>
                        <div className="import-export-btn-wrapper" style={{ marginTop: "24px" }}>
                            {
                                permissions&&permissions['Face Detection Excel']&&
                            <button>
                                {excelLoading ? (
                                    <ExcelLoader />
                                ) : (
                                    <img
                                        src={ic_excel}
                                        // onClick={(e) => downloadExcelFile(e)}
                                        alt=""
                                    />
                                )}
                            </button>
                             }
                            {/* <label htmlFor="selectFile" className="file-input-btn">
                                    <img src={ic_export1} alt="" />
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        // onChange={handleFileChange}
                                    // id="selectFile"
                                    />
                                </label> */}
                        </div>
                    </div>
                    <div className="table-wrapper" id="scrol-tbl" style={{ marginBottom: "55px" }}>
                        <table>
                            <tr className="firstTabelRow">
                                <th>Sr. no.</th>
                                <th>Name</th>
                                <th>Id</th>
                                <th>Emotion</th>
                                <th>Time Stamp</th>
                                <th>Camera Id</th>
                                <th>Action</th>
                            </tr>
                            {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaceDetection;
