import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import { ToastMsg } from "../TostMsg";

const ChangePassword = () => {
  const navigate = useNavigate();
  const previousPageData = useLocation().state;
  const [changePass, setChangePass] = useState({
    Password: "",
    ConfirmPassword: "",
    CodeVerify:""
  });
  const [errMsg, setErrMsg] = useState({
    Password: "",
    ConfirmPassword: "",
    CodeVerify:"",
  });
  const [state, setState] = useState({
    ...previousPageData,
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
  });
  const [errotpMsg, setErrOtpMsg] = useState({
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
  });
  console.log("state", state);
  const handleChange = (event) => {
    const { name, value } = event.target;
    const usercopy = { ...changePass };
    const errMsgcopy = { ...errMsg };
    usercopy[name] = value;
    errMsgcopy[name] = "";
    setErrMsg(errMsgcopy);
    setChangePass(usercopy);
  };

  const handleEnter = (event) => {
    var validkeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    if (validkeys.includes(event.key)) {
      event.target.value = event.key;
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1] && form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    if (changePass.Password.length < 6) {
      setErrMsg({ ...errMsg, Password: "Please enter password" });
      return;
    }
    if (changePass.ConfirmPassword.length < 6) {
      setErrMsg({
        ...errMsg,
        ConfirmPassword: "Please enter confirm password",
      });
      return;
    }
    if (changePass.ConfirmPassword != changePass.Password) {
      setErrMsg({
        ...errMsg,
        ConfirmPassword: "Confirm password is not same password",
      });
      return;
    }
    if (state.otpDigit4.length > 0 === "") {
      setErrOtpMsg({
        ...errotpMsg,
        otpDigit4: "Please enter Otp",
      });
      return;
    } else {
      var otp =
        state.otpDigit1 + state.otpDigit2 + state.otpDigit3 + state.otpDigit4;
      const body = {
        Email: state.email,
        CodeVerify: otp,
        Password: changePass.Password,
        ConfirmPassword: changePass.ConfirmPassword,
      };
      PostCallWithErrorResponse(ApiConfig.CHANGE_PASSWORD, JSON.stringify(body))
        .then((res) => {
          if (res.json && res.json?.Success === true) {
            ToastMsg("success", res.json.Message);
            navigate("/");
          } else {
            ToastMsg("error", res.json.Message);
          }
        })
        .catch((err) => console.log(err));
      // }
    }
  };

  const handleClickShowPassword = () => {
    setChangePass({ ...changePass, showPassword: !changePass.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword2 = () => {
    setChangePass({ ...changePass, showPassword2: !changePass.showPassword2 });
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  return (
    <main>
      <div className="main-auth createAccount" id="change-password-main">
        {/* <div className="left-top-logo">
          <img src={icon_logo} alt="" />
        </div> */}
        <div className="create-ac-form">
          <form action="">
            <div className="top-heading">
              <div>
                <label className="heading">Code Verification</label>
              </div>
            </div>
            <div className="otp-section-main">
              <div className="text">
                <label htmlFor="">
                  Enter the code sent to <span> {state.email}</span> or{" "}
                </label>
              </div>
              <div className="input-otp">
                <input
                  type="text"
                  className="form-control"
                  id="otp-input"
                  maxLength={1}
                  placeholder="0"
                  value={state.otpDigit1}
                  onKeyDown={(e) => {
                    if (String(e.key).match("^[0-9]+$")) {
                      setState({ ...state, otpDigit1: e.key });
                      handleEnter(e);
                    }
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  id="otp-input"
                  maxLength={1}
                  placeholder="0"
                  value={state.otpDigit2}
                  onKeyDown={(e) => {
                    if (String(e.key).match("^[0-9]+$")) {
                      setState({ ...state, otpDigit2: e.key });
                      handleEnter(e);
                    }
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  id="otp-input"
                  maxLength={1}
                  placeholder="0"
                  value={state.otpDigit3}
                  onKeyDown={(e) => {
                    if (String(e.key).match("^[0-9]+$")) {
                      setState({ ...state, otpDigit3: e.key });
                      handleEnter(e);
                    }
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  id="otp-input"
                  maxLength={1}
                  placeholder="0"
                  value={state.otpDigit4}
                  onKeyDown={(e) => {
                    if (String(e.key).match("^[0-9]+$")) {
                      setState({ ...state, otpDigit4: e.key });
                      handleEnter(e);
                    }
                  }}
                />
              </div>
              {errotpMsg.otpDigit4.length > 0 && (
                <span className="text-danger text-center">
                  {errotpMsg.otpDigit4}
                </span>
              )}
            </div>
            <div className="changePassPaddin">
              <div className="form-control-div">
                <div className="pass-main">
                  <div className="left-pass">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Create New Password
                    </label>
                  </div>
                  <div
                    className="right-pass"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <img
                      src={
                        changePass.showPassword == true ? ic_show : ic_hide_eyes
                      }
                      alt="ic_hide_eyes"
                    />

                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      {changePass.showPassword == true ? "Show" : "Hide"}
                    </label>
                  </div>
                </div>

                <input
                  type={changePass.showPassword ? "text" : "Password"}
                  name="Password"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  value={changePass.Password}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter your password"
                />
                {errMsg.Password.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.Password}
                  </span>
                )}
                <div className="below-text">
                  <label>
                    Use 8 or more characters with a mix of letters, numbers &
                    symbols
                  </label>
                </div>
              </div>
              <div className="form-control-div">
                <div className="pass-main">
                  <div className="left-pass">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Confirm New Password
                    </label>
                  </div>
                  <div
                    className="right-pass"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    <img
                      src={
                        changePass.showPassword2 == true
                          ? ic_show
                          : ic_hide_eyes
                      }
                      alt="ic_hide_eyes"
                    />

                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      {changePass.showPassword2 == true ? "Show" : "Hide"}
                    </label>
                  </div>
                </div>

                <input
                  type={changePass.showPassword2 ? "text" : "Password"}
                  name="ConfirmPassword"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  value={changePass.ConfirmPassword}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter your password"
                />
                {errMsg.ConfirmPassword.length > 0 && (
                  <span className="text-danger text-center">
                    {errMsg.ConfirmPassword}
                  </span>
                )}
              </div>
              <div className="ac-button">
                <Link to="">
                  <button type="submit" onClick={handleSubmit}>
                    Change Password
                  </button>
                </Link>
              </div>
              <div className="need">
                <p>
                  <label htmlFor="">Do you need help?</label>
                </p>
                <p>
                  <Link to="#">Customer support</Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ChangePassword;
