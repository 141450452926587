import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Editac from "../../assets/images/Editac.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
  DeleteCallWithErrorResponseWithTokenQuery,
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import moment from "moment";
import { notifyError, notifySuccess } from "../../toaster/notify";
import { Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";

const UserDetails = () => {
  const { sidebar } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [location, setLocation] = useState([]);
  const [addDataHoliday, setAddDataHoliday] = useState({
    HolidayName: "",
    Date: "",
    LocationId: 0,
  });
  console.log("addDataHoliday,", addDataHoliday);
  const [userDetails, setUserDetails] = useState([]);

  const changeData = (e) => {
    const { name, value } = e.target;
    setAddDataHoliday({
      ...addDataHoliday,
      [name]: value,
    });
  };
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Get Location
  const getLocation = () => {
    // setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_LOCATION)
      .then((res) => {
        if (res.Success === true) {
          // setLoading(false);
          setLocation(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    userDetails &&
    userDetails.length &&
    userDetails.filter((item) =>
      item.Name?.toLowerCase().includes(searchValue)
    );

  const fetchUsers = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_USER_DETAILS)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setUserDetails(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUsers();
    getLocation();
  }, []);
  const [btnLoader, setbtnLoader] = useState(false);

  const createHoliday = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setbtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.CREATE_HOLIDAY,
        JSON.stringify(addDataHoliday)
      )
        .then((res) => {
          setShow(false);

          if (res.json.Success) {
            fetchUsers();
            notifySuccess("Holiday added successfully");
          } else {
            console.log("error", res);
          }
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setbtnLoader(false);
        });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const getSingleHoliday = (id) => {
    simpleGetCallWithToken(ApiConfig.GET_HOLIDAY + `?id=${id}`)
      .then((res) => {
        if (res.Success == true) {
          let dateStr = res.Data.HolidayDate;
          let formattedDate = formatDate(dateStr);
          setAddDataHoliday({
            HolidayId: res?.Data?.Id,
            HolidayName: res?.Data?.HolidayName,
            Date: formattedDate,
            LocationId: res?.Data?.LocationId,
          });
          console.log("addDate", addDataHoliday);
        }
        console.log("Edit Data", res);
      })
      .catch((err) => {
        throw err;
      });
  };

  const updateHoliday = (e) => {
    e.preventDefault();
    setbtnLoader(true);
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_HOLIDAY,
      JSON.stringify(addDataHoliday)
    )
      .then((res) => {
        setShowEdit(false);
        if (res.json.Success) {
          fetchUsers();
          notifySuccess("Holiday Updated successfully");
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setbtnLoader(false);
      });
  };

  const deleteHoliday = (id) => {
    DeleteCallWithErrorResponseWithTokenQuery(
      ApiConfig.DELETE_HOLIDAY + `?holidayid=${id}`
    )
      .then((res) => {
        console.log("Delete Selected Holiday", res);
        if (res.json.Success) {
          fetchUsers();
          notifySuccess("Record deleted Successfully");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const changeUserStatus = async (id) => {
    let dataObj = {
      Id: id,
      // data.RoleId,
      // status: !data.IsActive,
    };
    try {
      const response = await PostCallWithErrorResponse(
        ApiConfig.CHANGE_USER_STATUS,
        JSON.stringify(dataObj)
      );
      if (!response.error) {
        notifySuccess(response.json.Message);
        // getUserRoleList("")
        fetchUsers();
      } else {
        notifyError(response.json.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayUserList =
    userDetails &&
    userDetails.slice(startIndex, endIndex).map((data, index) => {
      return (
        <tr className="table-row-custom">
          <td>{startIndex + index + 1}</td>
          <td>
            <b>{data.Name}</b>
            <br />
            {data.WorkEmail}
          </td>
          <td>{moment(data.DateOfJoining).format("L")}</td>
          <td>{data.Roles}</td>
          <td>{data.LocationName}</td>
          <td
            onClick={() => changeUserStatus(data.EmployeeId)}
            className={data?.IsActive ? "red-deactive" : "green-active"}
          >
            {data?.IsActive ? "De-active" : "Active"}
          </td>
        </tr>
      );
    });

  const displaySearchedUsers =
    userDetails &&
    userDetails
      ?.filter((data) => data.Name?.toLowerCase().includes(searchValue))
      .slice(startIndex, endIndex)
      .map((data, index) => {
        return (
          <tr className="table-row-custom">
            <td>{startIndex + index + 1}</td>
            <td>
              <b>{data.Name}</b>
              <br />
              {data.WorkEmail}
            </td>
            <td>{moment(data.DateOfJoining).format("L")}</td>
            <td>{data.Roles}</td>
            <td>{data.LocationName}</td>
            <td
              onClick={() => changeUserStatus(data.EmployeeId)}
              className={data?.IsActive ? "red-deactive" : "green-active"}
            >
              {data?.IsActive ? "De-active" : "Active"}
            </td>
          </tr>
        );
      });

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role ">
            <h6 className="mt-2">User List</h6>
          </div>
          <div className="role-table-header">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(0);
                }}
              />
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div>
          {/* <div className="table-wrapper">
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {userDetails && userDetails.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>Users</th>
                          <th>Joining Date</th>
                          <th>Roles</th>
                          <th>Work Location</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDetails
                          .slice(startIndex, endIndex)
                          .map((data, index) => {
                            return (
                              <tr className="table-row-custom" key={index}>
                                <td>{startIndex + index + 1}</td>
                                <td>
                                  <b>{data.Name}</b>
                                  <br />
                                  {data.WorkEmail}
                                </td>
                                <td>
                                  {moment(data.DateOfJoining).format("L")}
                                </td>
                                <td>{data.Roles}</td>
                                <td>{data.LocationName}</td>
                                <td
                                  onClick={() =>
                                    changeUserStatus(data.EmployeeId)
                                  }
                                  className={
                                    data?.IsActive
                                      ? "red-deactive"
                                      : "green-active"
                                  }
                                >
                                  {data?.IsActive ? "De-active" : "Active"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div> */}

          <div className="table-wrapper">
            {loading ? (
              <Loader />
            ) : userDetails && userDetails.length > 0 ? (
              <table>
                <tr className="firstTabelRow">
                  <th>Sr.No</th>
                  <th>Users</th>
                  <th>Joining Date</th>
                  <th>Roles</th>
                  <th>Work Location</th>
                  <th>Action</th>
                </tr>

                <tbody>
                  {searchValue === ""
                    ? displayUserList
                    : displaySearchedUsers}
                </tbody>
              </table>
            ) : (
              <>
                <NoData />
              </>
            )}

            {!loading && searchedDataPageCounts.length > usersPerPage && (
              <Pagination
                data={userDetails}
                pageChangeHandler={pageChangeHandler}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                searchValue={searchValue}
                searchedDataPageCounts={searchedDataPageCounts}
              />
            )}
          </div>
        </div>
        {/* <Pagination
          data={userDetails}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        /> */}
      </div>

      {/* Add Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form noValidate validated={validated} onSubmit={createHoliday}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Add Task
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShow(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Name
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="HolidayName"
                value={addDataHoliday.HolidayName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter holiday name
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Date
              </label>
              <input
                required
                onChange={(e) => {
                  changeData(e);
                }}
                name="Date"
                value={addDataHoliday.Date}
                type="date"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter holiday date
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label htmlFor="onPriority" className="enter-role-text ms-2">
                Location Name
              </label>
              <select
                required
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setAddDataHoliday({
                    ...addDataHoliday,
                    LocationId: Number(e.target.value),
                  });
                }}
                name="LocationId"
                value={addDataHoliday?.LocationId}
              >
                <option value="">Select City</option>

                {/* <option value={1}>Aurangabad</option>
                            <option value={2}>Pune</option>
                            <option value={3}>Trivendram</option> */}
              </select>
              <Form.Control.Feedback type="invalid">
                Please select location
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div className="Bottom-btn-arbaz-responsive">
              <button
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={createHoliday}
                type="submit"
              >
                {btnLoader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="me-2">Loading...</span>
                    <ClipLoader color="#fff" size={18} />
                  </div>
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showEdit}
        onHide={() => setShowEdit(false)}
      >
        <Form noValidate validated={validated} onSubmit={updateHoliday}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Edit Task
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShowEdit(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Name
              </label>
              <input
                // onChange={(e) => {
                //     changeData(e)
                // }}
                name="HolidayName"
                // value={adminPostData.budgethours}
                value={addDataHoliday?.HolidayName}
                onChange={(e) =>
                  setAddDataHoliday({
                    ...addDataHoliday,
                    HolidayName: e.target.value,
                  })
                }
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Holiday Date
              </label>
              <input
                onChange={(e) => {
                  changeData(e);
                }}
                // name="budgethours"
                name="Date"
                // value={adminPostData.budgethours}
                value={formatDate(addDataHoliday.Date)}
                format="dd-mm-yyyy"
                type="date"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
            </div>
            <div className="modalProp mb-4">
              <label htmlFor="onPriority" className="enter-role-text ms-2">
                Location Name
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  // changeData(e)
                  setAddDataHoliday({
                    ...addDataHoliday,
                    LocationId: Number(e.target.value),
                  });
                }}
                name="LocationId"
                value={addDataHoliday?.LocationId}
              >
                <option selected>Select City</option>
                <option value="1">Aurangabad</option>
                <option value="2">Pune</option>
                <option value="3">Trivendram</option>
              </select>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div className="Bottom-btn-arbaz-responsive">
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={addTaskByAdmin}
                type="submit"
              >
                Update
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UserDetails;
