import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import optionIcon from "../../assets/images/bx_edit.svg";
import shareIcon from "../../assets/images/ic_export1.svg";
import BookIcon from "../../assets/images/ic_excel.svg";
import "../../assets/css/myAttendence.scss";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";

const ProjectSchedule = () => {
  const { sidebar, setSidebar, Dark, setDark , userData,permissions } = useContext(AppContext);

  const [scheduleList, setScheduleList] = useState([]);
  const [schedulePayload, setSchedulePayload] = useState({
    EmployeeId:Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    Month: 0,
    year: 0,
    pagesize: 5,
  });
  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  });

  const minOffset = 0;
  const maxOffset = 1;
  let crryear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(crryear - x);
  }

  // const series = [
  //   //data on the y-axis
  //   {
  //     name: "Projects",
  //     data: [],
  //   },
  // ];
  // const options = {
  //   //data on the x-axis
  //   chart: { id: "bar-chart" },
  //   xaxis: {
  //     categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
  //   },
  // };

  useEffect(() => {
    PostCallWithErrorResponse(
      ApiConfig.PROJECT_SCHEDUILE_LIST,
      JSON.stringify(schedulePayload)
    )
      .then((res) => {
        setScheduleList(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [schedulePayload]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Project Schedule</h6>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper-main">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSchedulePayload({
                      ...schedulePayload,
                      year: Number(e.target.value),
                    });
                  }}
                  value={schedulePayload.year}
                >
                  <option value="">Select Year</option>
                  {allYears.map((year, i) => {
                    console.log(year);
                    return (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="search-input-wrapper-main mx-4">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSchedulePayload({
                      ...schedulePayload,
                      Month: Number(e.target.value),
                    });
                  }}
                  value={schedulePayload.Month}
                >
                  <option>Select Month</option>
                  <option value="1">Janaury</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="entries-wrapper-main ">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSchedulePayload({
                      ...schedulePayload,
                      pageSize: e.target.value,
                    });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              {
                permissions&&permissions['Project Schedule Bulk Upload']&&
              <div className="shareIcon mx-3">
                <img src={shareIcon} className="innerIconsBtn" alt="" />
              </div>
              }
              {
                permissions&&permissions['Project Schedule Excel']&&
              <div className="booksIcon">
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
             }
            </div>
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div className="row p-0 m-0">
            <div className="col-lg-6">
              <div className="table-wrapper-main">
                <table>
                  <thead>
                    <tr className="firstTabelRow">
                      <th>Sr. No.</th>
                      <th>Task Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  {scheduleList && scheduleList.length < 0 ? (
                    <Loader />
                  ) : (
                    <tbody>
                      {scheduleList && scheduleList.length > 0 ? (
                        scheduleList.map((shdedule, index) => {
                          return (
                            <tr
                              className="table-row-main"
                              key={"sadfsad" + index}
                            >
                              <td> {index + 1}</td>
                              <td>{shdedule.ProjectName} </td>
                              <td> {shdedule.startdate}</td>
                              <td> {shdedule.enddate}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="ErrorDataNotFound">
                        <td colSpan={6}>Data Not Found</td>
                      </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="chart-section">
                <div className="mixed-chart">
                  <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="520"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSchedule;
