import React, { useEffect,useContext, useState } from "react";





import ApiConfig from "../../../api/ApiConfig";
import { getWithAuthCall, getWithRequestBodyWithErrorResponse, postWithAuthCall } from "../../../api/ApiServices";


import Loader from "../../Loader";
import { AppContext } from "../../../context/AppContext";
import { ToastMsg } from "../../TostMsg";

const AccessCode = () => {
  const { isDrawerOpen, selectedDomainId,domainName,sidebar } = useContext(AppContext);

  const [selectedChat, setSelectedChat] = useState(undefined);

  const [permissionList, setPermissionList] = useState([]);
  console.log("permissionList",permissionList);
  

  const [isLoading,setIsLoading] = useState(true)

  const getPermissionList=()=>{
    setIsLoading(true)
    getWithAuthCall(ApiConfig.GET_PERMISSIONS_LIST+selectedDomainId).then((res)=>{
         if(res?.success){
          setPermissionList(res?.data)
         }else{
          ToastMsg('error',res?.message)
         }
         setIsLoading(false)
      })
  }
  useEffect(()=>{
    getPermissionList();
  },[selectedDomainId])

  const updatePermission=(id,status,index)=>{

    postWithAuthCall(ApiConfig.UPDATE_PERMISSION,JSON.stringify({
      "permission_id":id,
      "domain_id":selectedDomainId,
      "status":status
    })).then((res)=>{

      if(res?.success){
        ToastMsg('success',res?.message)

        let updatePermission= permissionList.map((i,indx)=>{

          return indx===index?{...i,permission_status:status}:i

        })

        setPermissionList(updatePermission)

      }else{

        ToastMsg('error',res?.message)

      }

    }).catch((err)=>{

      console.log(err);
      

    })

  }


  return (
    <div  
      className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
    >
   
      <div
        
      >
      
       
        <div >
          <div className="role-content">
            <div className="sub-header-role d-flex justify-content-between ">
              <h6 className="mt-2">Access</h6>
              <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
            </div>
            <div className="table-wrapper table-wrapper-3">
              {
                isLoading?<Loader/>:            
              <table>
                <thead className="">
                  <tr className="firstTabelRow">
                    <th>Name</th>
                    <th className="act-deac">Active/Deactive</th>
                  </tr>
                </thead>
                <tbody>     
                  {
                    permissionList?.map((i,index)=>{
                    return <tr className=" table-row-custom" key={index}>
                      <td>{i?.name}Demo</td>
                      <td className="move-right">
                      <div className="form-check form-switch ms-5" style={{scale:"1.3"}}>
                          <input
                            type="checkbox"
                            checked={i.permission_status}
                            onChange={(e)=>{
                              updatePermission(i?.permission_id,e.target.checked,index)
                            }}
                            className="form-check-input"
                            style={{cursor:'pointer'}}
                          ></input>
                        </div>
                      </td>
                    </tr>

                    })
                  }
                </tbody>
              </table> 
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccessCode;
