import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";

const CandidatesData = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const crmCandidatesData = [
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
  ];
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Candidates Data</h6>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary mb-0">
          <div className="search-input-wrapper">
            <input type="text" placeholder="Search here" />

            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
          </div>

          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              value={selectedRows}
              onChange={handleSelectChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary mb-0">
          <div className="role-table-header accoridance">
            <div className="entries-wrapper">
              <p>Select Technology</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
              >
                <option value="" disabled selected>
                  Select
                </option>
                <option value="android">Android</option>
                <option value="flutter">flutter</option>
                <option value="iOS">iOS</option>
                <option value=".net">.Net</option>
              </select>
            </div>
            <div className="entries-wrapper">
              <p>Select Location</p>

              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
              >
                <option value="" disabled selected>
                  Select
                </option>
                <option value="agra">Agra</option>
                <option value="ahmedabad">Ahmedabad</option>
                <option value="ahmednagar">Ahmednagar</option>
                <option value="aurangabad">Aurangabad</option>
              </select>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header accoridance">
            <div className="search-input-wrapper">
              <button className="sml-btn-sub-header">Submit</button>
            </div>
            <div className="search-input-wrapper">
              <button className="sml-btn-sub-header">Reset</button>
            </div>
            <div className="search-input-wrapper">
              <button className="sml-btn-sub-header">Download</button>
            </div>
          </div>
          <div className="entries-wrapper myAccordiance">
            <p>
              <b className="me-2">CANDIDATE COUNT : 9317</b>
            </p>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">NAME</th>
                <th scope="col">MOBILE NUMBER</th>
                <th scope="col">EMAIL</th>
                <th scope="col">CURRENT PROFESSION</th>
                <th scope="col">UPDATED DATE</th>
                <th scope="col">LOCATION</th>
                <th scope="col">TECHNOLOGY</th>
                <th scope="col">CURRENT CTC</th>
                <th scope="col">LOOKING JOB ROLE</th>
                <th scope="col">EXPERIENCE</th>
                <th scope="col">NOTICE PERIOD</th>
                <th scope="col">VIEW DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {crmCandidatesData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.name}</td>
                  <td>{data.mobileNumber}</td>
                  <td>{data.email}</td>
                  <td>{data.currentProfession}</td>
                  <td>{data.updatedDate}</td>
                  <td>{data.loation}</td>
                  <td>{data.technology}</td>
                  <td>{data.currentCtc}</td>
                  <td>{data.lookingJobRole}</td>
                  <td>{data.experience}</td>
                  <td>{data.noticePeriod}</td>
                  <td>
                    <button className="custoBtnForCrmCall">
                      <Link
                        to="/UserDetailsForm"
                        className="custoBtnForCrmCallLink"
                      >
                        Details
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default CandidatesData;
