import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Form, Tab, Tabs } from "react-bootstrap";

const UpdateUserDetails = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">User Details</h6>
        </div>
        <div className="settingsCard">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  First Name
                </label>
                <input
                  name="firstName"
                  type="text"
                  className="date-label-input tasKCategory1 ps-2"
                  placeholder="First Name"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Last Name
                </label>
                <input
                  name="lastName"
                  type="text"
                  className="date-label-input tasKCategory1 ps-2"
                  placeholder="Last Name"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Email
                </label>
                <input
                  name="email"
                  type="text"
                  className="date-label-input tasKCategory1 ps-2"
                  placeholder="Email"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Contact Number
                </label>
                <input
                  name="contactNumber"
                  type="text"
                  className="date-label-input tasKCategory1 ps-2"
                  placeholder="Contact Number"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Gender
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected value="0">
                    Select Gender
                  </option>
                  <option value="279">Male</option>
                  <option value="279">Female</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Type
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected value="0">
                    Select Type
                  </option>
                  <option value="279">Candidate</option>
                  <option value="279">Spam</option>
                  <option value="279">Enquiry</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Country
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected value="0">
                    Select Country
                  </option>
                  <option value="1">United States</option>
                  <option value="2">Canada</option>
                  <option value="3">United Kingdom</option>
                  <option value="4">Australia</option>
                  <option value="5">India</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  State
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  {/* <option value="0"></option> */}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  City
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  {/* <option value="0"></option> */}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-end mt-2">
            <button className="impButton">Update</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserDetails;
