import React, { useState, useRef, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { BASE_URL_CHAT_Image } from '../api/ApiConfig'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import user_pic2 from '../assets/images/profile_img.svg'
export default function MensionUsers ({ users, text, index }) {
  let mensionUsersWithEveryoneArr = users && [
    ...users,
    
  ]

  useEffect(()=>{


    if(users?.length>1){
      mensionUsersWithEveryoneArr?.push({
        User_Id: 'everyone',
        FirstName: 'Everyone',
        LastName: ''
      })
    }

  },[])

  const [currentUser, setCurrentUser] = useState(null)

  let resText =
    text &&
    text
      .split(' ')
      .map(item => {
        if (item[0] === '@') {
          return item?.replace('@', '')
        } else {
          return item
        }
      })
      .join(' ')

  text = resText

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const handleClick = event => {
    setShow(!show)
    setTarget(event.target)
    let innerText = event.target.outerText
    if (users && users.length)
      setCurrentUser(
        users.filter(
          user => innerText && innerText.includes(user.LastName, user.FirstName)
        )[0]
      )
  }
  return (
    <div ref={ref} onClick={handleClick} className='msg-text'>
      <Highlighter
        highlightClassName='HighlightClass'
        onMouseLeave={e => {
          setShow(false)
        }}
        searchWords={
          mensionUsersWithEveryoneArr
            ? mensionUsersWithEveryoneArr.map(
                user => user.FirstName + ' ' + user.LastName
              )
            : []
        }
        autoEscape={true}
        // textToHighlight={text.replace('@', '')}
        textToHighlight={text}
      />
      <Overlay
        show={show}
        target={target}
        placement='bottom'
        container={ref}
        containerPadding={20}
      >
        <Popover id={'popover-contained' + index}>
          <Popover.Body>
            <div className='d-flex'>
              <img
                src={
                  (currentUser &&
                    BASE_URL_CHAT_Image + currentUser.PhotoPath) ||
                  user_pic2
                }
                alt=''
              />
              <strong>
                {currentUser &&
                  currentUser.FirstName + ' ' + currentUser.LastName}
              </strong>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  )
}
