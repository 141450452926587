import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

import "../../assets/css/crmphonebook/Agent.scss";

import { Link } from "react-router-dom";

export default function UserDetailsForm() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const {} = useContext(AppContext);
  const agentdetails = [
    {
      FirstName: "None",
      LastName: "None",
      Email: "pankaj.p@coudxperte.com",
      ContactNumber: "+919307776804",
      Gender: "NA",
      Type: "Agent",
      City: "NA",
      State: "NA",
      ExpectedCTC: " NA",
      ExpectedJobRole: " NA",
      Offer: "NA",
      PreferredLocation: "NA",
      AgreeForRelocation: "NA",
      Negotiable: "NA",
      JobRoleDescription: "",
      CurrentCompany: "NA",
      Projects: "",
      JobChangeReason: "",
      ResumeTitle: "",
      Communication: "NA",
      Remark: "NA",
      AdditionalNotes: "NA",
      ECTCNegotiable: "NA",
      ECTCMarketStandard: "NA",
      ECTCHike: "NA",
      RelevantExp: "NA",
      Lastorganization: "NA",
      ScheduleDay: "NA",
      ScheduleTime: "NA",
    },
  ];

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">User Details</h6>
          </div>
          <div id="tabsBelow" className="mt-4">
            <div className="button-maindiv">
              <div className="button-subdiv">
                <button className="sml-btn-sub-header">
                  <Link to="/UpdateUserDetails" className="details-link">
                    Edit User Details
                  </Link>
                </button>

                <button className="sml-btn-sub-header">
                  <Link to="/CallHistory" className="details-link">
                    Call History
                  </Link>
                </button>
              </div>
            </div>
            <div className="tabledata1">
              {agentdetails.map((item) => (
                <div className="tabledata">
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      First Name :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.FirstName}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Last Name :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.LastName}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">Email :</label>
                    <label className="enter-role-text col-md">
                      {item.Email}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Contact Number :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ContactNumber}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">Gender :</label>
                    <label className="enter-role-text col-md">
                      {item.Gender}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">Type :</label>
                    <label className="col-md enter-role-text">
                      {item.Type}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">City :</label>
                    <label className="enter-role-text col-md">
                      {item.City}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">State :</label>
                    <label className="enter-role-text col-md">
                      {item.State}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Expected CTC :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ExpectedCTC}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Expected Job Role :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ExpectedJobRole}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">Offer :</label>
                    <label className="enter-role-text col-md">
                      {item.Offer}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Preferred Location :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.PreferredLocation}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Agree For Relocation :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.AgreeForRelocation}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Negotiable :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.Negotiable}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Job Role Description :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.JobRoleDescription}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Resume Title :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ResumeTitle}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Communication :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.Communication}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">Remark :</label>
                    <label className="enter-role-text col-md">
                      {item.Remark}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Additional Notes :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.AdditionalNotes}
                    </label>
                  </div>
                  <div className=" row  tablerowdata">
                    <label className="enter-role-text col-md">
                      ECTC Market Standard :
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ECTCMarketStandard}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">ECTCHike :</label>
                    <label className="enter-role-text col-md">
                      {item.ECTCHike}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Relevant Exp:
                    </label>
                    <label className="enter-role-text col-md">
                      {item.RelevantExp}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Last day in organization:
                    </label>
                    <label className="enter-role-text col-md">
                      {item.Lastorganization}
                    </label>
                  </div>
                  <div className="row tablerowdata">
                    <label className="enter-role-text col-md">
                      Schedule Meeting Day:
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ScheduleDay}
                    </label>
                  </div>
                  <div className=" row tablerowdata">
                    <label className="enter-role-text col-md">
                      Schedule Meeting Time:
                    </label>
                    <label className="enter-role-text col-md">
                      {item.ScheduleTime}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
