import ApiConfig from "../../api/ApiConfig";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import VectorImg from "../../assets/images/icon-user-default.png";
import { add } from "lodash";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { ToastMsg } from "../TostMsg";

import {
  multipartPostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";

const AdminEdit = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [departmentlist, setDepartmentlist] = useState([]);
  const [designationsname, setDesignationsname] = useState([]);
  const [role, setRole] = useState([]);
  const [employeeattlist, setEmployeeattlist] = useState([]);
  const [location, setLocation] = useState([]);
  const [shift, setShift] = useState([]);
  const [validated, setValidated] = useState(false);
  const [showPersonal, setShowPersonal] = useState(false);
  // const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  // const [errorMessage2, setErrorMessage2] = useState('');
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");
  const [errorMessage5, setErrorMessage5] = useState("");
  const [errorMessage6, setErrorMessage6] = useState("");
  const [errorMessage7, setErrorMessage7] = useState("");
  const [addEmply, setAddEmply] = useState({
    EmployeePhoto: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    FatherName: "",
    MothersName: "",
    Religion: "",
    Nationality: "",
    Gender: "",
    BloodGroup: "",
    IdentificationMark: "",
    MaritalStatus: "",
    EmployeeCode: 0,
    GSMIId: "",
    TranscardId: "",
    WorkEmail: "",
    OtherEmail: "",
    MobilePhone: "",
    EmergencyContact: "",
    EmergencyContactPerson: "",
    HomePhone: "",
    Extension: "",
    Address: "",
    City: "",
    State: "",
    ZipCode: "",
    PermanentAddress: "",
    Country: "",
    DepartmentId: "",
    DesignationId: "",
    LocationId: "",
    ShiftId: "",
    Grade: "",
    NatureOfPost: "",
    DateOfJoining: "",
    ReportingId: "",
    RoleId: "",
    WorkTelephone: "",
    TeamId: 0,
  });
  console.log(addEmply, "addemployee");
  const [error, setError] = useState({
    EmployeePhoto: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    DateOfBirth: "",
    FatherName: "",
    MothersName: "",
    Religion: "",
    Nationality: "",
    Gender: "",
    BloodGroup: "",
    IdentificationMark: "",
    MaritalStatus: "",
    EmployeeCode: "",
    GSMIId: "",
    TranscardId: "",
    WorkEmail: "",
    OtherEmail: "",
    MobilePhone: "",
    EmergencyContact: "",
    EmergencyContactPerson: "",
    HomePhone: "",
    Extension: "",
    Address: "",
    City: "",
    State: "",
    ZipCode: "",
    PermanentAddress: "",
    Country: "",
    DepartmentId: "",
    DesignationId: "",
    LocationId: "",
    ShiftId: "",
    Grade: "",
    NatureOfPost: "",
    DateOfJoining: "",
    ReportingId: "",
    RoleId: "",
    WorkTelephone: "",
    TeamId: "",
  });
  //   useEffect(()=>{
  //     simpleGetCallWithToken(ApiConfig.GET_OWNER_NAMES_MODEL_OPTIONS)
  //     .then(res=>{
  // console.log("eee success")
  // setEmployeeattlist(res?.Data)
  // console.log('eeeee list : ', employeeattlist)
  //     })
  //   },[])

  useEffect(() => {
    Promise.all([
      simpleGetCallWithToken(ApiConfig.GETDEPARTMENT_LIST),
      simpleGetCallWithToken(ApiConfig.DESIGNATION_NAME),
      simpleGetCallWithToken(ApiConfig.ROLES_LIST),
      // postWithAuthCallWithErrorResponse(ApiConfig.TEAM_HEAD_lIST),
      simpleGetCallWithToken(ApiConfig.GET_OWNER_NAMES_MODEL_OPTIONS),
      simpleGetCallWithToken(ApiConfig.GET_LOCATION),
      simpleGetCallWithToken(ApiConfig.GET_SHIFT_LIST),
    ])
      .then(
        ([
          responses1,
          response2,
          response3,
          response4,
          response5,
          response6,
        ]) => {
          setDepartmentlist(responses1.Data);
          setDesignationsname(response2.Data);
          setRole(response3.Data);
          setEmployeeattlist(response4.Data);
          setLocation(response5.Data);
          setShift(response6.Data);
        }
      )
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleScreenNextTab = (e) => {
    e.preventDefault();
    if (key === "personalDetails") {
      if (addEmply.EmployeePhoto === "") {
        setError({
          ...error,
          EmployeePhoto: "Please upload your profile picture",
        });
        return;
      }
      if (addEmply.FirstName.length === 0) {
        setError({
          ...error,
          FirstName: "Enter first Name",
        });
        return;
      }
      // if (addEmply.MiddleName.length === 0) {
      //   setError({
      //     ...error,
      //     MiddleName: "Enter Middle Name",
      //   });
      //   return;
      // }
      if (addEmply.LastName.length === 0) {
        setError({
          ...error,
          LastName: "Enter Last Name ",
        });
        return;
      }
      if (addEmply.DateOfBirth.length === 0) {
        setError({
          ...error,
          DateOfBirth: "Enter Date Of Birth",
        });
        return;
      }
      if (addEmply.FatherName.length === 0) {
        setError({
          ...error,
          FatherName: "Enter Father Name",
        });
        return;
      }
      if (addEmply.MothersName.length === 0) {
        setError({
          ...error,
          MothersName: "Enter Mother Name ",
        });
        return;
      }
      if (addEmply.Religion.length === 0) {
        setError({
          ...error,
          Religion: "Please Enter Religion",
        });
        return;
      }
      if (addEmply.Nationality.length === 0) {
        setError({
          ...error,
          Nationality: " Please Enter Nationality",
        });
        return;
      }
      if (addEmply.Gender.length === 0) {
        setError({
          ...error,
          Gender: "Please Enter Gender",
        });
        return;
      }
      if (addEmply.MaritalStatus.length === 0) {
        setError({
          ...error,
          MaritalStatus: "Please Enter MaritalStatus",
        });
        return;
      } else {
        setKey("contactDetails");
      }
    }
    if (key === "contactDetails") {
      const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9.]+\.)+[A-Za-z]+$/;

      if (!regex.test(addEmply.WorkEmail)) {
        setError({ ...error, WorkEmail: "Enter Valid Email ID " });
        return;
      }

      if (!regex.test(addEmply.OtherEmail)) {
        setError({ ...error, OtherEmail: "Enter Valid Email ID " });
        return;
      }
      if (addEmply.MobilePhone.length === 0) {
        setError({
          ...error,
          MobilePhone: "Please Enter Mobile Number",
        });
        return;
      }
      if (addEmply.Address.length === 0) {
        setError({
          ...error,
          Address: "Please Enter Address",
        });
        return;
      }
      if (addEmply.City.length === 0) {
        setError({
          ...error,
          City: "Please Enter City",
        });
        return;
      }
      if (addEmply.State.length === 0) {
        setError({
          ...error,
          State: "Please Enter State",
        });
        return;
      }

      if (addEmply.Country.length === 0) {
        setError({
          ...error,
          Country: "Please Enter Country",
        });
        return;
      } else {
        setKey("professionalDetails");
      }
    }
    if (key === "professionalDetails") {
      if (addEmply.DepartmentId.length === 0) {
        setError({
          ...error,
          DepartmentId: "Please Select Department",
        });
        return;
      }
      if (addEmply.DesignationId.length === 0) {
        setError({
          ...error,
          DesignationId: "Please Select Designation ",
        });
        return;
      }
      if (addEmply.LocationId.length === 0) {
        setError({
          ...error,
          LocationId: "Please Select Location",
        });
        return;
      }
      if (addEmply.ShiftId.length === 0) {
        setError({
          ...error,
          ShiftId: "Please Select Shift",
        });
        return;
      }
      if (addEmply.Grade.length === 0) {
        setError({
          ...error,
          Grade: "Please Select Grade",
        });
        return;
      }
      if (addEmply.NatureOfPost.length === 0) {
        setError({
          ...error,
          NatureOfPost: "Please Select Nature Of Post ",
        });
        return;
      }
      if (addEmply.DateOfJoining.length === 0) {
        setError({
          ...error,
          DateOfJoining: "Please Select Date Of Joining ",
        });
        return;
      }
      if (addEmply.ReportingId.length === 0) {
        setError({
          ...error,
          ReportingId: "Please Select Reporting",
        });
        return;
      }
      if (errorMessage === "") {
        return;
      } else {
        AddEmployeeApi();
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z ]*$/; // Regular expression to allow only alphabets

    const usercopy = { ...addEmply };
    const errMsgcopy = { ...error };
    usercopy[name] = value;
    errMsgcopy[name] = "";
    setError(errMsgcopy);
    setAddEmply(usercopy);
    if (name == "FirstName") {
      if (!regex.test(value)) {
        setErrorMessage("Only alphabets are allowed.");
      } else {
        setErrorMessage("");
        setInputValue(value);
      }
    }

    // if (name == 'MiddleName') {
    //   if (!regex.test(value)) {
    //     setErrorMessage2('Only alphabets are allowed.');
    //   } else {
    //     setErrorMessage2('');
    //     setInputValue(value);
    //   }
    // };

    if (name == "LastName") {
      if (!regex.test(value)) {
        setErrorMessage3("Only alphabets are allowed.");
      } else {
        setErrorMessage3("");
        setInputValue(value);
      }
    }

    if (name == "FatherName") {
      if (!regex.test(value)) {
        setErrorMessage4("Only alphabets are allowed.");
      } else {
        setErrorMessage4("");
        setInputValue(value);
      }
    }

    if (name == "MothersName") {
      if (!regex.test(value)) {
        setErrorMessage5("Only alphabets are allowed.");
      } else {
        setErrorMessage5("");
        setInputValue(value);
      }
    }

    if (name == "Religion") {
      if (!regex.test(value)) {
        setErrorMessage6("Only alphabets are allowed.");
      } else {
        setErrorMessage6("");
        setInputValue(value);
      }
    }

    if (name == "Nationality") {
      if (!regex.test(value)) {
        setErrorMessage7("Only alphabets are allowed.");
      } else {
        setErrorMessage7("");
        setInputValue(value);
      }
    }
  };

  const AddEmployeeApi = (e) => {
    let fromdata = new FormData();
    fromdata.append("EmployeePhoto", addEmply.EmployeePhoto);
    fromdata.append("FirstName", addEmply.FirstName);
    fromdata.append("MiddleName", addEmply.MiddleName);
    fromdata.append("LastName", addEmply.LastName);
    fromdata.append("DateOfBirth", addEmply.DateOfBirth);
    fromdata.append("FatherName", addEmply.FatherName);
    fromdata.append("MothersName", addEmply.MothersName);
    fromdata.append("Religion", addEmply.Religion);
    fromdata.append("Nationality", addEmply.Nationality);
    fromdata.append("Gender", addEmply.Gender);
    fromdata.append("BloodGroup", addEmply.BloodGroup);
    fromdata.append("IdentificationMark", addEmply.IdentificationMark);
    fromdata.append("MaritalStatus", addEmply.MaritalStatus);
    fromdata.append("EmployeeCode", addEmply.EmployeeCode);
    fromdata.append("GSMIId", addEmply.GSMIId);
    fromdata.append("TranscardId", addEmply.TranscardId);
    fromdata.append("WorkEmail", addEmply.WorkEmail);
    fromdata.append("MobilePhone", addEmply.MobilePhone);
    fromdata.append("EmergencyContact", addEmply.EmergencyContact);
    fromdata.append("EmergencyContactPerson", addEmply.EmergencyContactPerson);
    fromdata.append("HomePhone", addEmply.HomePhone);
    fromdata.append("Extension", addEmply.Extension);
    fromdata.append("Address", addEmply.Address);
    fromdata.append("City", addEmply.City);
    fromdata.append("State", addEmply.State);
    fromdata.append("ZipCode", addEmply.ZipCode);
    fromdata.append("Country", addEmply.Country);
    fromdata.append("DepartmentId", addEmply.DepartmentId);
    fromdata.append("DesignationId", addEmply.DesignationId);
    fromdata.append("PermanentAddress", addEmply.PermanentAddress);
    fromdata.append("LocationId", addEmply.LocationId);
    fromdata.append("ShiftId", addEmply.ShiftId);
    fromdata.append("Grade", addEmply.Grade);
    fromdata.append("NatureOfPost", addEmply.NatureOfPost);
    fromdata.append("DateOfJoining", addEmply.DateOfJoining);
    fromdata.append("ReportingId", addEmply.ReportingId);
    fromdata.append("RoleId", addEmply.RoleId);
    fromdata.append("WorkTelephone", addEmply.WorkTelephone);
    fromdata.append("TeamId", addEmply.TeamId);
    multipartPostCallWithErrorResponse(ApiConfig.EMPLOYE_ADDED, fromdata)
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.Success === true) {
          ToastMsg("success", res.json.Message);
          navigate("/List");
        } else {
          ToastMsg("error", res.json.Message);
        }
      })
      .catch((err) => console.log(err));

    setValidated(true);
  };

  const handlezPrevious = (key) => {
    setKey("contactDetails");
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2"> Edit Admin</h6>
            {/* <button className="impButton" onClick={() => setShowPersonal(true)}>
              Import Employees
            </button> */}
          </div>
          <Form noValidate validated={validated} onSubmit={AddEmployeeApi}>
            <div
              className="row align-item-center justify-content-center"
              // style={{
              //   backgroundColor: "red",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
            >
              <div className="col-md-12 col-lg-6">
                <div className="selectImg">
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      First Name
                    </label>
                    <input
                      onChange={handleChange}
                      value={addEmply.FirstName}
                      name="FirstName"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                    <span className="text-danger ">{errorMessage}</span>
                    {error.FirstName.length > 0 && (
                      <span className="text-danger ">{error.FirstName}</span>
                    )}
                  </div>

                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Last Name
                    </label>
                    <input
                      onChange={handleChange}
                      value={addEmply.FirstName}
                      name="FirstName"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                    <span className="text-danger ">{errorMessage}</span>
                    {error.FirstName.length > 0 && (
                      <span className="text-danger ">{error.FirstName}</span>
                    )}
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Email
                    </label>
                    <input
                      onChange={handleChange}
                      value={addEmply.MiddleName}
                      name="MiddleName"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    />
                    {/* <span className="text-danger ">
                            {errorMessage2}
                          </span>
                          {error.MiddleName.length > 0 && (
                            <span className="text-danger ">
                              {error.MiddleName}
                            </span>
                          )} */}
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Alternate Email
                    </label>
                    <input
                      onChange={handleChange}
                      value={addEmply.MiddleName}
                      name="MiddleName"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                    />
                    {/* <span className="text-danger ">
                            {errorMessage2}
                          </span>
                          {error.MiddleName.length > 0 && (
                            <span className="text-danger ">
                              {error.MiddleName}
                            </span>
                          )} */}
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Phone
                    </label>
                    <input
                      onChange={handleChange}
                      value={addEmply.LastName}
                      name="LastName"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                    <span className="text-danger ">{errorMessage3}</span>
                    {error.LastName.length > 0 && (
                      <span className="text-danger ">{error.LastName}</span>
                    )}
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Alternate Phone
                    </label>
                    <input
                      onChange={handleChange}
                      value={addEmply.LastName}
                      name="LastName"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                    />
                    <span className="text-danger ">{errorMessage3}</span>
                    {error.LastName.length > 0 && (
                      <span className="text-danger ">{error.LastName}</span>
                    )}
                  </div>

                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Role
                    </label>
                    <input
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      onChange={handleChange}
                      name="MothersName"
                      value={addEmply.MothersName}
                      required
                    />
                    <span className="text-danger ">{errorMessage5}</span>
                    {error.MothersName.length > 0 && (
                      <div className="text-center ">{error.MothersName}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <button
                className="reset"
                onClick={(e) => {
                  handleScreenNextTab(e);
                }}
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={showPersonal}
        onHide={() => setShowPersonal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Import Employees
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShowPersonal(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <label>Import your file</label>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="imgHolder"
            required
            className="form-control tasKCategory1"
            name="EmployeePhoto"
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              // Check if a file is selected
              if (selectedFile) {
                console.log("Selected file size:", selectedFile.size);
                // Check if the file size exceeds 4MB
                if (selectedFile.size > 4 * 1024 * 1024) {
                  console.log("File size exceeds 4MB");
                  setError({
                    ...error,
                    EmployeePhoto: "File size should not exceed 4MB.",
                  });
                } else {
                  setAddEmply({
                    ...addEmply,
                    EmployeePhoto: e.target.files[0],
                  });
                  setError({
                    ...error,
                    EmployeePhoto: "",
                  });
                }
              }
            }}

            // required={!addEmply.EmployeePhoto ? true : false}
          />
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive">
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminEdit;
