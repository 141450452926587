import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import { Table } from "react-bootstrap";
import Pagination from "../../sharedComponent/Pagination";
const BulkCallView = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const bulkcalldata = [
    {
      srno: "1",
      name: "Sagar Gajanan Kshirsagar",
      type: "Candidate",
      mobilenumber: "+917378564296",
      email: "sagarkshirsagar1872000@gmail.com",
      updateddate: "03-Jun-2022",
      location: "Pune",
      technology: "Fresher Developer",
    },
    {
      srno: "2",
      name: "Dipmala Likhare",
      type: "Candidate",
      mobilenumber: "+917580969165",
      email: "likharedipmala@gmail.com",
      updateddate: "03-Jun-2022",
      location: "Nagpur",
      technology: "Fresher Developer",
    },
    {
      srno: "3",
      name: "Suvarna Dhole",
      type: "Candidate",
      mobilenumber: "+919146223049",
      email: "sonu.dhole0711@gmail.com",
      updateddate: "13-May-2022",
      location: "Pune Hinjewadi - Maharashtra",
      technology: "Fresher Developer",
    },
    {
      srno: "4",
      name: "Hipparkar Birudeo Bhanudas",
      type: "Candidate",
      mobilenumber: "+917498532631",
      email: "hipparkarbiru1097@gmail.com",
      updateddate: "26-May-2022",
      location: "Pune",
      technology: "Fresher Developer",
    },
    {
      srno: "5",
      name: "Heena Arif Shaikh",
      type: "Candidate",
      mobilenumber: "+917498532631",
      email: "heenashaikh1398@gmail.com",
      updateddate: "26-May-2022",
      location: "Pune",
      technology: "Fresher Developer",
    },
    {
      srno: "6",
      name: "Sudhakar Ashok Avatade",
      type: "Candidate",
      mobilenumber: "+919767479850",
      email: "avatadesudhakar@gmail.com",
      updateddate: "26-May-2022",
      location: "Solapur",
      technology: "Fresher Developer",
    },
    {
      srno: "7",
      name: "Savita Badole",
      type: "Candidate",
      mobilenumber: "+919975203053",
      email: "savitabadole99@gmail.com",
      updateddate: "26-May-2022",
      location: "Pune",
      technology: "Fresher Developer",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="row">
        <div className="col-lg-3">
          <div className="my-3">
            <label htmlFor="" className="enter-role-text mt-2">
              Select Technology
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
            >
              <option selected value="0">
                Select Technology
              </option>
              <option value="279">Male</option>
              <option value="279">Female</option>
            </select>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="my-3">
            <label htmlFor="" className="enter-role-text mt-2">
              Select Location
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
            >
              <option selected value="0">
                Select Location
              </option>
              <option value="279">Candidate</option>
              <option value="279">Spam</option>
              <option value="279">Enquiry</option>
            </select>
          </div>
        </div>
        <div style={{ gap: "10px", display: "flex", marginBottom: "10px" }}>
          <button className="sml-btn-sub-header">
            <Link className="details-link">Submit</Link>
          </button>
          <button className="sml-btn-sub-header">
            <Link className="details-link">Reset</Link>
          </button>
        </div>
      </div>

      <div style={{ display: "flex", gap: "10px" }}>
        <button className="sml-btn-sub-header" onClick={() => setShow(!show)}>
          <Link className="details-link">Assign</Link>
        </button>
        <div style={{ display: "flex", gap: "10px" }}>
          <button className="sml-btn-sub-header">
            <Link to="/TechCount" className="details-link">
              Count
            </Link>
          </button>
          <label style={{ fontSize: "14px" }}>Total: 1194</label>
        </div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Modal Title
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShow(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-2">
                Client Name
              </label>
              <select
                className="tasKshowusser"
                // onChange={(e) => setService(e.target.value)}
              >
                <option>Agent List</option>
                <option>Thushara</option>
                <option>sobhisha</option>
              </select>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div>
              <button
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleClose}
              >
                Save Changes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <div></div>
      </div>

      <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
        <table>
          <thead>
            <tr className="firstTabelRow">
              <th scope="col"></th>
              <th scope="col">Sr No.</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Mobile Number</th>
              <th scope="col">Email</th>
              <th scope="col">Updated Date</th>
              <th scope="col">Location</th>
              <th scope="col">Technology</th>
              <th scope="col">User Details</th>
            </tr>
          </thead>
          <tbody>
            {bulkcalldata.map((item) => (
              <tr key={item.id} className="table-row-custom">
                <td>
                  <input type="checkbox" />
                </td>
                <td>{item.srno}</td>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>{item.mobilenumber}</td>
                <td>{item.email}</td>
                <td>{item.updateddate}</td>
                <td>{item.location}</td>
                <td>{item.technology}</td>
                <td>
                  <button className="imporButton">
                    <Link to="/UserDetailsForm" className="details-link">
                      Details
                    </Link>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
  );
};

export default BulkCallView;
