import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";

const Product = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2">Products</h6>
                        <div className="btnFlex">
                            <button className="CategoryVie">
                                <Link to="/CreateProducts">
                                    Create Product
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="mainTableBox">
                        <div className="insideTable">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Image</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {/* <input type="text" className="form-control" /> */}
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td className="text-end">
                                            <button className="Clearbtn">
                                                Clear
                                            </button>
                                        </td>
                                    </tr>
                                    <tr className="text-center">
                                        <td colSpan={6}>No Data Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Product;
