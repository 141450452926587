import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const ZeroCallList = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const crmZeroCallListData = [
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      type: "Inquiry",
      //   mobileNumber: "",
      email: "shoeb@cloudxperte.com	",
      createDate: "10-Jun-2024",
      gender: "None",
      loation: "None",
      //   viewDetails :""
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Zero Call List</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">NAME</th>
                <th scope="col">TYPE</th>
                <th scope="col">MOBILE NUMBER</th>
                <th scope="col">EMAIL</th>
                <th scope="col">CREATE DATE</th>
                <th scope="col">GENDER</th>
                <th scope="col">LOCATION</th>
                <th scope="col">VIEW DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {crmZeroCallListData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.name}</td>
                  <td>{data.type}</td>
                  <td>
                    <button className="custoBtnForCrmCall">Make Call</button>
                  </td>
                  <td>{data.email}</td>
                  <td>{data.createDate}</td>
                  <td>{data.gender}</td>
                  <td>{data.loation}</td>
                  <td>
                    <button className="custoBtnForCrmCall">
                      <Link
                        to="/UserDetailsForm"
                        className="custoBtnForCrmCallLink"
                      >
                        Details
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default ZeroCallList;
