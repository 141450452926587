import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Editac from "../../assets/images/Editac.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
  DeleteCallWithErrorResponseWithTokenQuery,
  PostCallWithErrorResponse,
  putWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import moment from "moment";
import { notifySuccess } from "../../toaster/notify";
import { Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";

const ManageDesignations = () => {
  const { sidebar } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [location, setLocation] = useState([]);
  const [employeeNames, setEmployeeNames] = useState([]);
  console.log("employeeNames", employeeNames);
  // const [isEdit, setIsEdit] = useState(false);

  const [addDesignation, setAddDesignation] = useState({
    DesignationId: 0,
    DesignationName: ""
  });

  const [updateDesignationObj, setUpdateDesignationObj] = useState({
    DesignationId: 0,
    DesignationName: ""
  });
  console.log("Update Form", updateDesignationObj);

  console.log("addDesignation,", addDesignation);
  const [designationList, setDesignationList] = useState([]);
  console.log("designationList", designationList);

//   const changeData = (e) => {
//     const { name, value } = e.target;
//     setAddDesignation({
//       ...addDesignation,
//       [name]: value,
//     });
//   };
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Get Location
  const getLocation = () => {
    // setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_LOCATION)
      .then((res) => {
        if (res.Success === true) {
          // setLoading(false);
          setLocation(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    designationList &&
    designationList.length &&
    designationList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const getDesignationList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_DESIGNATION_LIST)
      .then((res) => {
        // console.log("res>>>>>>>>>>>>>>>>", res)
        if (res.Success === true) {
          setLoading(false);
          setDesignationList(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeList = async () => {
    try {
      const res = await PostCallWithErrorResponse(ApiConfig.GET_EMPLOYEE_NAMES);
      if (res.json.Success === true) {
        // let userOptions = res.json.Data.map((item) => {
        //     return {
        //         label: item.FullName,
        //         value: item.Id,
        //     }
        // })
        setEmployeeNames(res.json.Data);
      } else {
        console.log("getUserList error response,", res);
      }
    } catch (error) {
      console.log("error response", error);
    }
  };

  useEffect(() => {
    getDesignationList();
    getEmployeeList();
    getLocation();
  }, []);
  const [btnLoader, setbtnLoader] = useState(false);

  const createdesignation = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setbtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.CREATE_DESIGNATION,
        JSON.stringify(addDesignation)
      )
        .then((res) => {
          setShow(false);

          if (res.json.Success) {
            getDesignationList();
            notifySuccess("Designation added successfully");
          } else {
            console.log("error", res);
          }
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setbtnLoader(false);
        });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const getSingleDesignation = (id) => {
    console.log("IDDDDDD", id);
      simpleGetCallWithToken(ApiConfig.GET_DESIGNATION + `?id=${id}`).then((res) => {
        console.log("HHHHHHHHHHHHHHHHHHHHHHHH", res);
          if (res.Success == true) {
              setAddDesignation({
                  DesignationId: res?.Data?.DesignationId,
                  DesignationName: res?.Data?.Name,
              })
              console.log("addDate", addDesignation);
          }
          console.log("Edit Data", res);
      }).catch((err) => {
          throw err
      })
  }

  const updatedesignation = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setbtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.CREATE_DESIGNATION,
        JSON.stringify(updateDesignationObj)
      )
        .then((res) => {
          console.log("Update Haina Younus mera bhai", res);
          setShowEdit(false);
          if (res.json.Success) {
            getDesignationList();
            notifySuccess("Designation Successfully Updated");
          } else {
            console.log("error", res);
          }
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setbtnLoader(false);
        });
    }
  };

  const deleteDesignation = (id) => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.DELETE_DESIGNATION + `?id=${id}`)
      .then((res) => {
        // console.log("res>>>>>>>>>>>>>>>>", res)
        if (res.Success === true) {
          setLoading(false);
          getDesignationList();
          notifySuccess("Designation added successfully");
        //   setDesignationList(res.Data);
        }
        console.log(res.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Manage Designations</h6>
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                setShow(true);
                // setIsEdit(false)
                // handleShow();
                // getTaskName();
                //     setAddTaskPayload({
                //         ProjectId: 0,
                //         TaskName: "",
                //         StartDate: "",
                //         EndDate: "",
                //         BudgetedHours: 0,
                //         Priority: "",
                //         EmpId: Number(userData.EmpId),
                //     });
                //     setValidated(false);
              }}
            >
              + Add New Record{" "}
            </button>
          </div>
          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="table-wrapper">
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {designationList && designationList.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>Designation Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {designationList
                          .slice(startIndex, endIndex)
                          .map((data, index) => {
                            return (
                              <tr className="table-row-custom" key={index}>
                                <td>{startIndex + index + 1}</td>
                                <td>{data.Name}</td>

                                <td>
                                  <img
                                    src={Editac}
                                    className="mx-2 editBtn"
                                    onClick={() => {
                                      // setIsEdit(true)
                                      setShowEdit(true);
                                      getSingleDesignation(data.DesignationId)
                                      setUpdateDesignationObj({
                                        ...updateDesignationObj,
                                        DesignationId: data.DesignationId,
                                        DesignationName: data.Name,
                                      });
                                    }}
                                    alt=""
                                  />
                                  <img
                                    onClick={() => {
                                        deleteDesignation(data.DesignationId);
                                    }}
                                    src={DelectAc}
                                    alt=""
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        </div>
        <Pagination
          data={designationList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>

      {/* Add Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Form noValidate validated={validated} onSubmit={createdesignation}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Add Designation
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShow(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Designation Name
              </label>
              <input
                required
                onChange={(e) => {
                  setAddDesignation({
                    ...addDesignation,
                    DesignationName: e.target.value,
                  });
                  // changeData(e)
                }}
                // name="HolidayName"
                value={addDesignation.DesignationName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Designation name
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div className="Bottom-btn-arbaz-responsive">
              <button
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={createdesignation}
                type="submit"
              >
                {btnLoader ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="me-2">Loading...</span>
                    <ClipLoader color="#fff" size={18} />
                  </div>
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showEdit}
        onHide={() => setShowEdit(false)}
      >
        <Form noValidate validated={validated} onSubmit={updatedesignation}>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Edit Designation
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setShowEdit(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Designation Name
              </label>
              <input
                required
                onChange={(e) => {
                  // changeData(e)
                  setUpdateDesignationObj({
                    ...updateDesignationObj,
                    DesignationName: e.target.value,
                  });
                }}
                // name="HolidayName"
                value={updateDesignationObj.DesignationName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Designation Name
              </Form.Control.Feedback>
            </div>

            {/* <div className="modalProp mb-4">
              <label htmlFor="onPriority" className="enter-role-text">
                Department Head
              </label>
              <select
                required
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setAddDesignation({
                    ...addDesignation,
                    DepartmentHead: Number(e.target.value),
                  });
                }}
                name="LocationId"
                value={addDesignation?.DepartmentHead}
              >
                <option value="">Select...</option>
                {employeeNames && employeeNames.length > 0
                  ? employeeNames.map((employee) => {
                      return (
                        <>
                          <option value={employee.Id}>
                            {employee.FullName}
                          </option>
                        </>
                      );
                    })
                  : null}
              </select>
              <Form.Control.Feedback type="invalid">
                Please select Department Head
              </Form.Control.Feedback>
            </div> */}
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div className="Bottom-btn-arbaz-responsive">
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                // onClick={addTaskByAdmin}
                type="submit"
              >
                Update
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ManageDesignations;
