import React from "react";

function ChatDetailsHeader({ chatHeader }) {
  const senderId = chatHeader?.sender_id;
  return (
    <div className="sub-header-role " style={{ flexDirection: "column" }}>
      <h6 className="mt-2">Chat</h6>
      <p className="m-0">
        {senderId ? `Sender ID: ${senderId}` : "Select a chat"}
      </p>
    </div>
  );
}

export default ChatDetailsHeader;
