import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import VectorImg from "../../assets/images/icon-user-default.png";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";

const EditEmployeeProfile = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [showPersonal, setShowPersonal] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [professionalsShow, setProfessionalsShow] = useState(false);

  const updateTaskByAdmin = () => {};

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="row">
          <div className="col-lg-3 col-md-12 ">
            <div className="proffesionalDetails  d-flex flex-column justify-content-start align-items-center">
              <div className="profileCircle">
                <img src={VectorImg} alt="" />
              </div>
              <ul className="detailslist">
                <li>
                  <label className="nameLbl">Aameer Khan</label>
                </li>
                <li>
                  <span className="nameSpn">Junior Software Engineer</span>
                </li>
                <li>
                  <span className="nameSpn">Aurangabad,India</span>
                </li>
                <li>
                  <a href="mailto:aameer.k@cloudxperte.com">
                    aameer.k@cloudxperte.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9 col-md-12">
            <div className="personalDetails">
              <div className="headingtxt">
                <h3 className="headTxt">PERSONAL DETAILS</h3>
                <button
                  className="actionBtn"
                  onClick={() => setShowPersonal(true)}
                >
                  Edit
                </button>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      First Name :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Aameer
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Middle Name :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Ayub
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Last Name :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Khan
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Date Of Birth:{" "}
                    </label>
                    <label htmlFor="" className="value">
                      1997-11-09
                    </label>
                  </div>
                  {/* <div className="keyValues">
                    <label htmlFor="" className="key">
                      Birth Place :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div> */}
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Fathers Name :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Ayub
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Mother Name :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Religion :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Muslim
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      First Name :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Aameer
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Gender :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Male
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Blood Group :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Identification Mark :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Marital Status :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Single
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Employee Id :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      505
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-9 col-md-12">
            <div className="personalDetails">
              <div className="headingtxt">
                <h3 className="headTxt"> CONTACT DETAILS</h3>
                <button
                  className="actionBtn"
                  onClick={() => setContactShow(true)}
                >
                  Edit
                </button>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Work Email :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      aameer.k@cloudxperte.com
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Other Email :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      none
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Mobile Phone :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      9623907835
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Work Telephone:{" "}
                    </label>
                    <label htmlFor="" className="value">
                      none
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Emergency Contact :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      9579421630
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Emergency Contact Person :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Father
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Home Telephone :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Extension :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Address :{" "}
                    </label>
                    <label htmlFor="" className="value nextLine">
                      shabazar bhoiwada Aurangabad maharashtra 431001
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      City :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Aurangabad
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      State/Province :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Zip/Postal Code :{" "}
                    </label>
                    <label htmlFor="" className="value"></label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Permanent <br />
                      Address :{" "}
                    </label>
                    <label htmlFor="" className="value nextLine">
                      shabazar bhoiwada Aurangabad maharashtra 43100
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Country :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      India
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
          <div className="col-lg-9 col-md-12">
            <div className="personalDetails">
              <div className="headingtxt">
                <h3 className="headTxt">PROFESSIONAL DETAILS</h3>
                <button
                  className="actionBtn"
                  onClick={() => setProfessionalsShow(true)}
                >
                  Edit
                </button>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Department :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Services
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Designation :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Junior Software Engineer
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Location :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Aurangabad
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Shift :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      General
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Grade :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      None
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Nature of post :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Permanent
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Date of Joining :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      2022/09/19
                    </label>
                  </div>
                  <div className="keyValues">
                    <label htmlFor="" className="key">
                      Reporting to :{" "}
                    </label>
                    <label htmlFor="" className="value">
                      Dr. Naziya Pathan
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Personal detail modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={showPersonal}
        onHide={() => setShowPersonal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Personal Details
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShowPersonal(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="selectImg">
                <div className="mb-1 text-center">
                  <label htmlFor="" className="enter-role-text">
                    Employee Photo
                  </label>{" "}
                  <br />
                  <label htmlFor="imgHolder">
                    <img src={VectorImg} className="modalImg" alt="" />
                    <input type="file" id="imgHolder" className="d-none" />
                  </label>
                  <h1 className="enter-role-text mt-2">Click Here...</h1>
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    First Name
                  </label>
                  <input
                    // onChange={(e) => {
                    //     changeData(e)
                    // }}
                    // name="StartDate"
                    // value={adminPostData.StartDate}
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Middle Name
                  </label>
                  <input
                    // onChange={(e) => {
                    //     changeData(e)
                    // }}
                    // name="StartDate"
                    // value={adminPostData.StartDate}
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Last Name
                  </label>
                  <input
                    // onChange={(e) => {
                    //     changeData(e)
                    // }}
                    // name="StartDate"
                    // value={adminPostData.StartDate}
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Date of Birth
                  </label>
                  <input
                    // onChange={(e) => {
                    //     changeData(e)
                    // }}
                    // name="StartDate"
                    // value={adminPostData.StartDate}
                    type="date"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </div>
                {/* <div className="modalProp mb-2">
                                    <label
                                        htmlFor=""
                                        className="enter-role-text mt-2"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Birth Place
                                    </label>
                                    <input
                                        // onChange={(e) => {
                                        //     changeData(e)
                                        // }}
                                        // name="StartDate"
                                        // value={adminPostData.StartDate}
                                        type="text"
                                        className="date-label-input tasKCategory1"
                                        style={{
                                            fontSize: "14px",
                                            height: "38px",
                                            border: "1px solid #ced4da",
                                        }}
                                    />
                                </div> */}
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Father Name
                  </label>
                  <input
                    // onChange={(e) => {
                    //     changeData(e)
                    // }}
                    // name="StartDate"
                    // value={adminPostData.StartDate}
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Mother Name
                  </label>
                  <input
                    // onChange={(e) => {
                    //     changeData(e)
                    // }}
                    // name="StartDate"
                    // value={adminPostData.StartDate}
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="modalProp my-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Religion
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Nationality
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Gender
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Task Name</option>
                  <option value="male">Male</option>
                  <option value="female">Fe-Male</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Blood Group
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Blood Group</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Identification Mark
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Marital Status
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Maritual Status</option>
                  <option value="single">single</option>
                  <option value="Married">Married</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  GSM Id
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Transcard Id
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={updateTaskByAdmin}
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* COntact detail modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={contactShow}
        onHide={() => setContactShow(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Contact Details
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setContactShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Work Email
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Other Email
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Mobile Phone
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Work Phone
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Emergency Contact
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Emergency Contact Person
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Home Phone
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Extension
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Address
                </label>
                <textarea rows="4" className=" tasKCategory1"></textarea>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  City
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  State/Province
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Zip/Postal Code
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Permanent Address
                </label>
                <textarea rows="4" className=" tasKCategory1"></textarea>
              </div>
              <div className="modalProp mt-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Country
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Country</option>
                  <option value="male">Male</option>
                  <option value="female">Fe-Male</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={updateTaskByAdmin}
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Professionals details modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={professionalsShow}
        onHide={() => setProfessionalsShow(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Contact Details
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setProfessionalsShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Department
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Blood Group</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Designation
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Blood Group</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Location
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Location</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Shift
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Shift</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Grade
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Grade</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Nature of post
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Nature of post</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Date of Joining
                </label>
                <input
                  // onChange={(e) => {
                  //     changeData(e)
                  // }}
                  // name="StartDate"
                  // value={adminPostData.StartDate}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Reporting to
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Reporting to</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
              <div className="modalProp mb-2">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Role Name
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     changeData(e);
                  // }}
                  // name="StandardTaskId"
                  // value={adminPostData.standardTaskId}
                >
                  <option selected>Select Role Name</option>
                  <option value="1">A+</option>
                  <option value="2">B+</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setProfessionalsShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={updateTaskByAdmin}
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEmployeeProfile;
