import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  putMultipartWithAuthCallWithErrorResponse,
  putWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

const ComplianceSetting = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [complianceList, setComplianceList] = useState([]);
  const [complianceObj, setComplianceObj] = useState([]);

  const getComplianceSettings = () => {
    simpleGetCallWithToken(ApiConfig.GET_COMPLIANCE_SETTINGS)
      .then((res) => {
        console.log("res", res.Data);
        if (res.Success === true) {
          setComplianceList(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComplianceSettings();
  }, []);

  //   const handleSelect = (val) => {
  //     console.log("handleSelect", val);
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ComplianceSetting: [...complianceObj],
    };
    console.log("data", data);
    putWithAuthCall(ApiConfig.SET_COMPLIANCE_SETTINGS, JSON.stringify(data))
      .then((res) => {
        if (res.Success === true) {
          swal(res.Message);
          // setComplianceObj([]);
          // setComplianceObj([
          //   {
          //     ComplianceType: "",
          //     SettingsId: 0,
          //     SenderId: 0,
          //   },
          // ]);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Compliance Settings</h6>
          </div>
          <div className="settingsCard">
            {complianceList && complianceList.length > 0
              ? complianceList.map((data) => {
                  return (
                    <div className="settingFlex" key={data.SettingsId}>
                      <div className="w-50">
                        <h4 className="demoTxt">{data.ComplianceType}</h4>
                      </div>
                      <div className="w-50 my-3">
                        <label htmlFor="" className="enter-role-text mt-2">
                          Sender
                        </label>
                        <select
                          class="form-select tasKCategory1 select-drop-icon"
                          aria-label="Default select example"
                          onChange={(e) => {
                            // handleSelect(e.target.value);
                            setComplianceObj([]);
                            setComplianceObj([
                              ...complianceObj,
                              {
                                ComplianceType: data?.ComplianceType,
                                SettingsId: data?.SettingsId,
                                SenderId: Number(e.target.value),
                              },
                            ]);
                          }}
                          value={complianceObj.SenderId}
                        >
                          <option selected value={data.SenderId}>
                            {data.SenderId === 279
                              ? "Accounts"
                              : data.SenderId === 5032
                              ? "Human Resource"
                              : data.SenderId === 5033
                              ? "Delivery Escalation"
                              : data.SenderId === 5034
                              ? "Application Support"
                              : data.SenderId === 5035
                              ? "Server Support"
                              : "Select..."}
                          </option>
                          <option value="279">Accounts</option>
                          <option value="5032">Human Resource</option>
                          <option value="5033">Delivery Escalation</option>
                          <option value="5034">Application Support</option>
                          <option value="5035">Server Support</option>
                        </select>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="col-md-12 text-end mt-2">
              <button
                className="impButton"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplianceSetting;
