import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { ToastMsg } from "../TostMsg";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import { notifyError } from "../../toaster/notify";
import Form from "react-bootstrap/Form";

const TaskAllocation = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [allocationData, setAllocationData] = useState([]);
  const [taskId, setTaskId] = useState(0);
  const adminId =
    localStorage?.EmpId && JSON.parse(localStorage.getItem("EmpId"));
  const orgId = localStorage.getItem("OrgId");
  const [allocationDataObj, setAllocationDataObj] = useState({
    Empid: 0,
    status: 0,
    TaskId: 0,
    ProjectId: 0,
  });
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListOutSrc, setEmployeeListOutSrc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleClosed = () => setEdit(false);
  const handleClose = () => setShow(false);

  const [excelLoading, setExcelLoading] = useState(false);

  // const [error, setError] = useState('');

  // const handleSubmit = (e) => {

  //     e.preventDefault();
  //     if (!allocationDataObj.Empid) {
  //       setError('Please select an employee.');
  //     } else {
  //       setError('');
  //       saveTaskAllocation();
  //       console.log('Form submitted successfully:', allocationDataObj);
  //       // Proceed with form submission or other actions
  //     }
  //   };

  //   const handleSelectChange = (e) => {
  //     const selectedValue = Number(e.target.value);
  //     if (isNaN(selectedValue) || selectedValue === 0) {
  //       setError('Please select a valid employee.');
  //     } else {
  //       setError('');
  //       setAllocationDataObj({ ...allocationDataObj, Empid: selectedValue });
  //     }
  //   };

  // Get Task List
  const getAllocationTaskList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_TASK_ALLOCATION_LIST)
      .then((res) => {
        let data = res.Data;
        if (res.Success === true) {
          setAllocationData(data);
        } else {
          console.log("error respnse,", data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getEmpName = (id) => {
    // setTaskId(data)
    let projectId = {
      ProjectId: id,
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_EMPLOYEE_NAME_LIST,
      JSON.stringify(projectId)
    ).then((res) => {
      const data = res.json.Data;
      console.log("Data nameds", data);
      setEmployeeList(data);
    });
  };
  const getOutSrcName = () => {
    simpleGetCallWithToken(ApiConfig.GET_OUTSOURCE_ALLOCATION_LIST)
      .then((res) => {
        let data = res?.Data;
        setEmployeeListOutSrc(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const saveTaskAllocation = (e) => {
    e.preventDefault();
    if (allocationDataObj.Empid) {
      PostCallWithErrorResponse(
        ApiConfig.SAVE_TASK_ALLOCATION,
        JSON.stringify(allocationDataObj)
      ).then((res) => {
        if (res) {
          ToastMsg("success", res.json.Message);
          getAllocationTaskList();
          setShow(false);
        }
      });
    } else {
      notifyError("Please Select a Name");
    }
  };
  const deAllocateTask = (data) => {
    const payloadDeAllocate = {
      Empid: allocationDataObj.Empid,
      status: data?.status,
      TaskId: data?.TaskId,
      " ProjectId": data?.ProjectId,
    };
    console.log("status", payloadDeAllocate);
    PostCallWithErrorResponse(
      ApiConfig.SAVE_TASK_ALLOCATION,
      JSON.stringify(payloadDeAllocate)
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getAllocationTaskList();
      }
      // console.log("DATA OF DE_ALLOCATE", res);
    });
    // console.log("payloadDeAllocate", payloadDeAllocate)
  };
  const allocateCondtionally = (data) => {
    if (data.status === 4 || data.status === 2) {
      setShow(true);
      getEmpName(data.ProjectId);
    } else if (data.status === 1 || data.status === 3) {
      window.confirm("Are you sure you want to de-Allocate?") &&
        deAllocateTask(data);
    }
  };
  const allocateOutSrcCond = (data) => {
    console.log("allocateCondtionally", data);
    if (data.status === 4 || data.status === 2) {
      setEdit(true);
      getOutSrcName();
    } else if (data.status === 1 || data.status === 3) {
      deAllocateTask(data);
    }
  };
  const allocateOutSrcData = () => {
    PostCallWithErrorResponse(
      ApiConfig.SAVE_TASK_ALLOCATION,
      JSON.stringify(allocationDataObj)
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getAllocationTaskList();
        setEdit(false);
      }
    });
  };
  useEffect(() => {
    getAllocationTaskList();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  console.log("searchValue,", searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.TSAKALLOCATION_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    allocationData &&
    allocationData.length &&
    allocationData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const displayAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr. No.</th>
          <th>Project Name</th>
          {/* <th>WorkFlow</th> */}
          <th>Task Name</th>
          <th>Allocated To</th>
          <th>Allocated Task</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Created On</th>
          <th>Outsourcing Consultant</th>
          <th>Allocated Outsource</th>
        </tr>
      </thead>
      <tbody>
        {allocationData.slice(startIndex, endIndex).map((data, index) => {
          return (
            <tr className="table-row-custom">
              <td id="sr-no-padding">{startIndex + index + 1}</td>
              <td>{data.ProjectName}</td>
              {/* <td>{data.Workflow ? data.Workflow : "No Data"}</td> */}
              <td className="break-ln-spaces">
                {data.TaskName ? data.TaskName : "No Data"}
              </td>
              <td>{data.AllocatedName ? data.AllocatedName : "No Data"}</td>
              <td
                onClick={() => {
                  allocateCondtionally(data);
                  setAllocationDataObj({
                    ...allocationDataObj,
                    TaskId: data.TaskId,
                    status: data.status,
                    ProjectId: data.ProjectId,
                  });
                  // console.log("Data Id Check", allocationDataObj)
                }}
                // className={data.status === 4 && "textInfo" ||
                //     data.status === 1 && "textWarning" ||
                //     data.status === 2 && "textcoral" ||
                //     data.status === 3 && "textWarning"}
              >
                <button
                  type="button"
                  className={
                    (data.status === 4 && "btn-infoaf") ||
                    (data.status === 1 && "btn-alert") ||
                    (data.status === 2 && "btn-warning") ||
                    (data.status === 3 && "btn-alert")
                  }
                >
                  {(data.status === 4 && "Allocate") ||
                    (data.status === 1 && "De-Allocate") ||
                    (data.status === 2 && "Change Resource") ||
                    (data.status === 3 && "De-Allocate")}
                </button>
              </td>
              <td className="break-ln-spaces">{data?.TaskDescription}</td>
              <td>{data.StartDate ? data.StartDate : "No Data"}</td>
              <td>{data.EndDate ? data.EndDate : "No Data"}</td>
              <td>{data.CreatedDate ? data.CreatedDate : "No Data"}</td>
              <td> {data.OutsourcingConsultant}</td>
              <td
                onClick={() => {
                  allocateOutSrcCond(data);
                  setAllocationDataObj({
                    ...allocationDataObj,
                    TaskId: data.TaskId,
                    status: data.status,
                    ProjectId: data.ProjectId,
                  });
                  // console.log("Data Id Check", allocationDataObj)
                }}
                // className={data.status === 4 && "textInfo" ||
                //     data.status === 1 && "textWarning" ||
                //     data.status === 2 && "textcoral" ||
                //     data.status === 3 && "textWarning"}
              >
                <button
                  type="button"
                  className={
                    (data.status === 4 && "btn-infoaf") ||
                    (data.status === 1 && "btn-alert") ||
                    (data.status === 2 && "btn-warning") ||
                    (data.status === 3 && "btn-alert")
                  }
                >
                  {(data.status === 4 && "Allocate") ||
                    (data.status === 1 && "De-Allocate") ||
                    (data.status === 2 && "Change Resource") ||
                    (data.status === 3 && "De-Allocate")}
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  const displaySearchAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr. No.</th>
          <th>Project Name</th>
          {/* <th>WorkFlow</th> */}
          <th>Task Name</th>
          <th>Allocated To</th>
          <th>Allocated Task</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Created On</th>
          <th>Outsourcing Consultant</th>
          <th>Allocated Outsource</th>
        </tr>
      </thead>
      <tbody>
        {allocationData
          .filter((item) =>
            item.ProjectName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(startIndex, endIndex)
          .map((data, index) => {
            return (
              <tr className="table-row-custom">
                <td id="sr-no-padding">{index + 1}</td>
                <td>{data.ProjectName}</td>
                {/* <td>{data.Workflow ? data.Workflow : "No Data"}</td> */}
                <td className="break-ln-spaces">
                  {data.TaskName ? data.TaskName : "No Data"}
                </td>
                <td>{data.AllocatedName ? data.AllocatedName : "No Data"}</td>
                <td
                  onClick={() => {
                    allocateCondtionally(data);
                    setAllocationDataObj({
                      ...allocationDataObj,
                      TaskId: data.TaskId,
                      status: data.status,
                      ProjectId: data.ProjectId,
                    });
                    console.log("Data Id Check", allocationDataObj);
                  }}
                  // className={data.status === 4 && "textInfo" ||
                  //     data.status === 1 && "textWarning" ||
                  //     data.status === 2 && "textcoral" ||
                  //     data.status === 3 && "textWarning"}
                >
                  <button
                    type="button"
                    className={
                      (data.status === 4 && "btn-infoaf") ||
                      (data.status === 1 && "btn-alert") ||
                      (data.status === 2 && "btn-warning") ||
                      (data.status === 3 && "btn-alert")
                    }
                  >
                    {(data.status === 4 && "Allocate") ||
                      (data.status === 1 && "De-Allocate") ||
                      (data.status === 2 && "Change Resource") ||
                      (data.status === 3 && "De-Allocate")}
                  </button>
                </td>
                <td className="break-ln-spaces">{data?.TaskDescription}</td>
                <td>{data.StartDate ? data.StartDate : "No Data"}</td>
                <td>{data.EndDate ? data.EndDate : "No Data"}</td>
                <td>{data.CreatedDate}</td>
                <td> {data.OutsourcingConsultant}</td>
                <td
                  onClick={() => {
                    allocateOutSrcCond(data);
                    setAllocationDataObj({
                      ...allocationDataObj,
                      TaskId: data.TaskId,
                      status: data.status,
                      ProjectId: data.ProjectId,
                    });
                    console.log("Data Id Check", allocationDataObj);
                  }}
                  // className={data.status === 4 && "textInfo" ||
                  //     data.status === 1 && "textWarning" ||
                  //     data.status === 2 && "textcoral" ||
                  //     data.status === 3 && "textWarning"}
                >
                  <button
                    type="button"
                    className={
                      (data.status === 4 && "btn-infoaf") ||
                      (data.status === 1 && "btn-alert") ||
                      (data.status === 2 && "btn-warning") ||
                      (data.status === 3 && "btn-alert")
                    }
                  >
                    {(data.status === 4 && "Allocate") ||
                      (data.status === 1 && "De-Allocate") ||
                      (data.status === 2 && "Change Resource") ||
                      (data.status === 3 && "De-Allocate")}
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Task Allocation</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Project"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            {permissions && permissions["Task Allocation Export"] && (
              <div className="import-export-btn-wrapper">
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="table-wrapper-main" id="table-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : allocationData && allocationData.length > 0 ? (
              <>
                {searchValue === ""
                  ? displayAllocationList
                  : displaySearchAllocationList}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {/* {!loading && searchedDataPageCounts.length > usersPerPage && ( */}
        <Pagination
          data={allocationData}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
        {/* )} */}
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Allocate To
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form
        //</Modal>onSubmit={handleSubmit}
        >
          <Modal.Body>
            <div className="mb-4">
              <label htmlFor="" className="enter-role-text mb-1">
                Employee Name
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) =>
                  setAllocationDataObj({
                    ...allocationDataObj,
                    Empid: Number(e.target.value),
                  })
                }
                //onChange={handleSelectChange}
                required
              >
                <option selected>Employee Name</option>
                {employeeList &&
                  employeeList.map((names) => {
                    return (
                      <option value={names.EmployeeId}>
                        {names.EmployeeName}
                      </option>
                    );
                  })}
              </select>
              {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
            </div>
          </Modal.Body>
          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div>
              <button
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={(e) => {
                  saveTaskAllocation(e);
                }}
              >
                Allocate
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* <<<<<<...................modal edit starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={handleClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Allocate Outsource
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setEdit(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="mb-4">
            <label htmlFor="" className="enter-role-text mb-1">
              Outsource Name
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
              onChange={(e) =>
                setAllocationDataObj({
                  ...allocationDataObj,
                  Empid: Number(e.target.value),
                })
              }
            >
              <option selected>Select Outsource Name</option>
              {employeeListOutSrc &&
                employeeListOutSrc.map((names) => {
                  return <option value={names.EmployeeId}>{names.Name}</option>;
                })}
            </select>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleClosed}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              disabled={allocationDataObj.Empid == ""}
              onClick={allocateOutSrcData}
            >
              Allocate
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskAllocation;
