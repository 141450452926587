import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import downArrow from "../../assets/images/downArrow.png";
import upArrow from "../../assets/images/upArrow.png";
import DelectAcBlc from "../../assets/images/DelectAcBlc.svg";
import Editacdisable from "../../assets/images/Editac.svg";
import Editac from "../../assets/images/bx_edit.svg";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";
import { Link } from "react-router-dom";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import { ToastMsg } from "../TostMsg";
import { toast } from "react-toastify";
import Loader from "../Loader";
import {
  DeleteCallWithErrorResponse,
  DeleteCallWithErrorResponseWithToken,
  PostCallWithErrorResponse,
  deleteWithAuthCall,
  simpleGetCallWithToken,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
// import Modal from "react-bootstrap/Modal";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const StandardTask = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [edit, setEdit] = useState(false);

  //Confirmation popup
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [useDeleteTaskList, setDeleteTaskList] = useState("");

  const [excelLoading, setExcelLoading] = useState(false);

  const handleEdit = (StdId) => {
    PostCallWithErrorResponse(
      ApiConfig.STANDARD_TASK_BY_ID + `?standardId=${StdId}`
    ).then((res) => {
      let data = res.json.Data;
      setStoredData({
        ...storedData,
        StandardTaskName: data?.StandardTask,
        TeamId: data.TeamId,
        standardTaskId: StdId,
      });
    });
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.STANDARDTASK_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (delId) => {
    DeleteCallWithErrorResponseWithToken(
      `${ApiConfig.DELETE_STANDARD_TASK}?standardId=${delId}`
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getTaskNames();
      }
    });
  };
  const handleClosed = () => setEdit(false);

  const [addTask, setAddTask] = useState(false);
  const handleAdd = () => setAddTask(true);
  const handleCancel = () => setAddTask(false);
  const [standardList, setStandardList] = useState([]);
  const [taskames, setTaskames] = useState([]);
  const [storedData, setStoredData] = useState({
    TeamId: 0,
    StandardTaskName: "",
    standardId: 0,
    standardTaskId: 0,
  });
  const [loading, setLoading] = useState(false);
  const orgId = localStorage.getItem("OrgId");
  const adminId = localStorage.getItem("EmpId");

  const getStandardTaskList = () => {
    simpleGetCallWithToken(ApiConfig.GET_TEAMS_LIST_STANDARD_TASK).then(
      (res) => {
        const data = res.Data;
        setStandardList(data);
      }
    );
  };

  const getTaskNames = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_TASK_NAME_MODAL).then((res) => {
      const data = res.Data;
      if (res.Success === true) {
        setTaskames(data);
        setLoading(false);
      }
    });
  };

  const createStandTask = () => {
    let data = {
      CreatedBy: JSON.parse(adminId),
      TeamId: JSON.parse(storedData.TeamId),
      StandardTaskName: storedData.StandardTaskName,
    };
    PostCallWithErrorResponse(
      ApiConfig.ADD_STANDARD_TASK,
      JSON.stringify(data)
    ).then((res) => {
      if (res) {
        ToastMsg("success", res.json.Message);
        getTaskNames();
        setStoredData({});
        setShow(false);
      }
    });
  };

  const updateTaskData = () => {
    console.log("Team ID", storedData);
    let payloadData = {
      standardTaskId: storedData.standardTaskId,
      TeamId: storedData.TeamId,
      StandardTaskName: storedData.StandardTaskName,
    };
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_STANDARD_TASK_LIST,
      JSON.stringify(payloadData)
    ).then((res) => {
      if (res) {
        setEdit(false);
        ToastMsg("success", res.json.Message);
        getTaskNames();
      }
    });
  };
  useEffect(() => {
    getTaskNames();
    getStandardTaskList();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  console.log("searchValue,", searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  console.log("Numeric", endIndex);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    taskames &&
    taskames.length &&
    taskames.filter((item) =>
      item.TeamName?.toLowerCase().includes(searchValue)
    );

  const displayAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Created By</th>
          <th>Team Name</th>
          <th>Task Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {taskames.slice(startIndex, endIndex).map((data, index) => {
          return (
            <tr className="table-row-custom">
              <td id="sr-no-padding">{startIndex + index + 1}</td>
              <td>{data.MadeBy}</td>
              <td>{data.TeamName}</td>
              <td className="break-ln-spaces">{data.StandardTaskName}</td>
              <td>
                {/* {dropdownacc == true ? ( */}
                {permissions && permissions["Edit Standard Task"] && (
                  <img
                    onClick={() => {
                      setEdit(true);
                      handleEdit(data.standardTaskId);
                    }}
                    src={Editac}
                    className="editBtn mx-2"
                    alt=""
                  />
                )}

                {/* ) : ( */}
                {permissions && permissions["Delete Standard Task"] && (
                  <img
                    src={DelectAcBlc}
                    className="deleteBtn"
                    onClick={() => {
                      handleShow3();
                      setDeleteTaskList(data.standardTaskId);
                    }}
                    // onClick={() => handleDelete(data.standardTaskId)}
                    alt=""
                  />
                )}
                {/* )} */}
              </td>
            </tr>
          );
        })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  const displaySearchAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Made By</th>
          <th>Team Name</th>
          <th>Task Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {taskames
          .filter((item) =>
            item.TeamName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(startIndex, endIndex)
          .map((data, index) => {
            return (
              <tr className="table-row-custom">
                <td id="sr-no-padding">{startIndex + index + 1}</td>
                <td>{data.MadeBy}</td>
                <td>{data.TeamName}</td>
                <td className="break-ln-spaces">{data.StandardTaskName}</td>
                <td>
                  {/* {dropdownacc == true ? ( */}
                  {permissions && permissions["Edit Standard Task"] && (
                    <img
                      onClick={() => {
                        setEdit(true);
                        handleEdit(data.standardTaskId);
                      }}
                      src={Editac}
                      className="editBtn mx-2"
                      alt=""
                    />
                  )}
                  {/* ) : ( */}
                  {permissions && permissions["Delete Standard Task"] && (
                    <img
                      src={DelectAcBlc}
                      className="deleteBtn"
                      onClick={() => {
                        handleShow3();
                        setDeleteTaskList(data.standardTaskId);
                      }}
                      // onClick={() => handleDelete(data.standardTaskId)}
                      alt=""
                    />
                  )}
                  {/* )} */}
                </td>
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Standard Task</h6>
            {permissions && permissions["Create Standard Task"] && (
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  handleShow();
                }}
              >
                Add Standard Task
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search by Team Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            <div className="import-export-btn-wrapper">
              {permissions && permissions["Standard Task Export"] && (
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : taskames && taskames.length > 0 ? (
              <>
                {searchValue === ""
                  ? displayAllocationList
                  : displaySearchAllocationList}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {/* {!loading && searchedDataPageCounts.length > usersPerPage && ( */}
        <Pagination
          data={taskames}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
        {/* )} */}
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Add Standard Task
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body id="popup-form-af">
          <div className="mb-4">
            <label htmlFor="" className="enter-role-text">
              Team Name
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
              onChange={(e) => {
                setStoredData({
                  ...storedData,
                  TeamId: e.target.value,
                });
              }}
            >
              <option>Select Team</option>
              {standardList &&
                standardList.map((name) => {
                  return <option value={name.TeamId}>{name.TeamName}</option>;
                })}
            </select>
          </div>
          {/* <div className="mb-4">
            <label htmlFor="" className="enter-role-text mt-2">
              Made by
            </label>
            <select
              class="form-select select-drop-icon-1"
              aria-label="Default select example"
              placeholder="Select Eployee"
            >
              <option value="1">Select Eployee</option>
              <option value="2">Performance Manager</option>
              <option value="3">Consultant</option>
              <option value="4">AR Manager</option>
              <option value="5">Counselling Assistant</option>
            </select> 
          </div> */}
          <div className="modalProp mb-4">
            <label htmlFor="" className="enter-role-text mt-2">
              Add Standard Task
            </label>
            <input
              type="text"
              className="form-control date-label-input tasKCategory1"
              //className="label-input"
              style={{ border: "1px solid #ced4da" }}
              onChange={(e) => {
                setStoredData({
                  ...storedData,
                  StandardTaskName: e.target.value,
                });
              }}
              maxlength={65}
            />
          </div>
          {/* <div className="text-end Bottom-btn-arbaz-responsive">
            <button
              className="modal-create-btn ps-4-arbaz-responsive"
              onClick={() => setAddTask(!addTask)}
            >
              Add Task
            </button>
          </div> */}
          {/* {addTask && (
            <div
              className="mt-3"
              style={{
                borderRadius: "10px",
                border: "1px solid #212529",
                height: "30vh",
                overflow: "auto",
                border: "1px solid #ced4da",
              }}
            >
              <div className="text-end mt-2">
                <img
                  src={close}
                  alt=""
                  onClick={handleCancel}
                  className="cr-class"
                />
              </div>
              <div className="table-wrapper">
                <table>
                  <tr>
                    <th>Sr. no</th>
                    <th>Standard Task Name</th>
                    <th>Action</th>
                  </tr>
                  {/* {customerDataTableModal.map((srnumber, index) => {
                    return (
                      <tr className="table-row-custom">
                        <td>{srnumber.serial_no}</td>
                        <td>{srnumber.Task_ID}</td>

                        <td>
                          <button
                            className="approve-btn-customer"
                            onClick={handleEdit}
                          >
                            Add
                          </button>
                          <button className="reject-btn-customer ">
                            Remove
                          </button>
                        </td>

                        {/* <td
                    className={
                      srnumber.status === "Completed"
                        ? ""
                        : ""
                    }
                  >
                    {srnumber.status}
                  </td>
                  <td>
                    {srnumber.outsorce}
                  </td> */}
          {/* </tr>
                );
                  })} */}
          {/* </table>
              </div>
            </div>
          )} */}
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={createStandTask}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={handleClosed}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text ">
              Edit Standard Task
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setEdit(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="mb-4" id="tite-alig">
            <label htmlFor="" className="enter-role-text">
              Team Name
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
              onChange={(e) => {
                setStoredData({
                  ...storedData,
                  TeamId: e.target.value,
                });
              }}
              value={storedData?.TeamId}
            >
              <option selected>xyz</option>
              {standardList &&
                standardList.map((name) => {
                  return <option value={name.TeamId}>{name.TeamName}</option>;
                })}
            </select>
          </div>
          {/* <div className="mb-4">
            <label htmlFor="" className="enter-role-text mt-2">
              Made by
            </label>
            <select
              class="form-select select-drop-icon-1"
              aria-label="Default select example"
              placeholder="Select Eployee"
            >
              <option value="1">Select Eployee</option>
              <option value="2">Performance Manager</option>
              <option value="3">Consultant</option>
              <option value="4">AR Manager</option>
              <option value="5">Counselling Assistant</option>
            </select>
          </div> */}
          <div className="modalProp mb-4" id="tite-alig">
            <label htmlFor="" className="enter-role-text mt-2">
              Edit Standard Task
            </label>
            <input
              type="text"
              className="form-control tasKCategory1"
              style={{ border: "1px solid #ced4da" }}
              value={storedData.StandardTaskName}
              onChange={(e) => {
                setStoredData({
                  ...storedData,
                  StandardTaskName: e.target.value,
                });
              }}
              maxlength={65}
            />
          </div>
          {/* <div className="text-end Bottom-btn-arbaz-responsive">
            <button
              className="modal-create-btn ps-4-arbaz-responsive"
              onClick={() => setAddTask(!addTask)}
            >
              Add Task
            </button>
          </div>
          {addTask && (
            <div
              className="mt-3"
              style={{
                borderRadius: "10px",
                border: "1px solid #212529",
                height: "30vh",
                overflow: "auto",
                border: "1px solid #ced4da",
              }}
            >
              <div className="text-end mt-2">
                <img
                  src={close}
                  alt=""
                  onClick={handleCancel}
                  className="cr-class"
                />
              </div>
              <div className="table-wrapper">
                <table>
                  <tr>
                    <th>Sr. no</th>
                    <th>Standard Task Name</th>

                    <th>Action</th>
                  </tr>
                  {/* {customerDataTableModal.map((srnumber, index) => {
                    return (
                      <tr className="table-row-custom">
                        <td>{srnumber.serial_no}</td>
                        <td>{srnumber.Task_ID}</td>

                        <td>
                          <button className="approve-btn-customer">Add</button>
                          <button className="reject-btn-customer ">
                            Remove
                          </button>
                        </td>

                        {/* <td
                    className={
                      srnumber.status === "Completed"
                        ? ""
                        : ""
                    }
                  >
                    {srnumber.status}
                  </td>
                  <td>
                    {srnumber.outsorce}
                  </td> */}

          {/* </table> */}
          {/* </div> */}
          {/* </div> */}
          {/* // )} */}
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleClosed}
            >
              Cancel
            </button>
            <button
              onClick={updateTaskData}
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete popup Announcement */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleDelete(useDeleteTaskList);
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Delete popup Announcement */}
    </>
  );
};

export default StandardTask;
