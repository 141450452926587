import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';

const Store = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);
    const [isSuccess, setIsSuccess] = useState(false);

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        // setValidated(true);
        submitForm(event);
    };

    const submitForm = (e) => {
        e.preventDefault();
        setIsSuccess(true);
    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main">
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2" onClick={() => setIsSuccess(false)}>Create Store</h6>
                        {
                            isSuccess &&
                            <div className="btnFlex">
                                <button className="Update">Update</button>
                                <button className="Delete">Delete</button>
                            </div>
                        }
                    </div>
                    <div className="createStoreBox">
                        <div className="innerConmtainer">
                            {
                                isSuccess ?
                                    <div className="StoreCreatedBox">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <td className="key">Name</td>
                                                    <td>hello</td>
                                                </tr>
                                                <tr>
                                                    <td className="key">Category</td>
                                                    <td>hello</td>
                                                </tr>
                                                <tr>
                                                    <td className="key">Address</td>
                                                    <td>hello</td>
                                                </tr>
                                                <tr>
                                                    <td className="key">Created By</td>
                                                    <td>hello</td>
                                                </tr>
                                                <tr>
                                                    <td className="key">Created Date</td>
                                                    <td>hello</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Form.Group controlId="validationCustom01" className="mb-3">
                                                    <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group controlId="validationCustom01" className="mb-3">
                                                    <Form.Label>Category <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control required as="select" type="select" name="payment_method"
                                                    >
                                                        <option value="">Select Options</option>
                                                        <option value="cash">Cash</option>
                                                        <option value="online">Online</option>
                                                        <option value="card_machine">Card Machine</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group controlId="validationCustom03" className="mb-3">
                                                    <Form.Label>Address <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        required
                                                        as="textarea"
                                                        rows="7"
                                                        type="text"
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 text-end">
                                                <button className="CreateBtn">Create</button>
                                            </div>
                                        </div>
                                    </Form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Store;
