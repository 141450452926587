import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function TypingLoader() {
  return (
      <BeatLoader color="#1890ff" className="spinner"  size="7" style={{ alignItems: "center",}}/>
  );
}

export default TypingLoader;
