import { useEffect, useState } from "react";
import { useContext } from "react";


import {
  faImage,
  faCar,
  faBus,
  faBicycle,
  faTruck,
  faMotorcycle,
  faTrash

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../pages/ChatAdmin/Pagination";

import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";



import NoDataFound from "../../../pages/ChatAdmin/NodataFound";

import { ToastMsg } from "../../TostMsg";
import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { simpleGetCall ,deleteWithAuthCall, simpleGetCallWithTokenNoNBEARER} from "../../../api/ApiServices";
import Loader from "../loader";

const getChatRs = [
  {
    sender_id: "user1",
    last_message: "Hello, how can I help you?",
    timestamp: "2024-07-21T12:34:56.789Z",
  },
  {
    sender_id: "user2",
    last_message: "I need assistance with my order.",
    timestamp: "2024-07-21T12:35:56.789Z",
  },
  {
    sender_id: "user3",
    last_message: "Can you provide more details on your service?",
    timestamp: "2024-07-21T12:36:56.789Z",
  },
];

const getChatDetails = {
  events: [
    {
      type_name: "user",
      timestamp: 1675970400,
      data: {
        text: "Hello, how can I help you today?",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970460,
      data: {
        text: "I need some details about your issue.",
      },
    },
    {
      type_name: "user",
      timestamp: 1675970520,
      data: {
        text: "I have a problem with my order.",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970580,
      data: {
        text: "Can you provide me with your order number?",
      },
    },
    {
      type_name: "system", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    },
  ],
};

const theme = localStorage.getItem("theme")
  ? JSON.stringify(localStorage.getItem("theme"))
  : null;

const data = [
  {
    srNo: "1",
    logo: <FontAwesomeIcon className="iconHW icon" icon={faBus} />,
    domainName: "vehicle tracking",
    colors: {
      background: "#ffffff",
      primary: "#007bff",
      secondary: "#6c757d",
      text: "#000000",
      textPrimary: "#343a40",
      textSecondary: "#6c757d",
    },
    sessionTime: 20,
  },
  {
    srNo: "2",
    logo: (
      <FontAwesomeIcon className="iconHW icon" icon={faCar}></FontAwesomeIcon>
    ),
    domainName: "car rental",
    colors: {
      background: "#f8f9fa",
      primary: "#28a745",
      secondary: "#17a2b8",
      text: "#212529",
      textPrimary: "#343a40",
      textSecondary: "#6c757d",
    },
    sessionTime: 30,
  },
  {
    srNo: "3",
    logo: (
      <FontAwesomeIcon className="iconHW icon" icon={faImage}></FontAwesomeIcon>
    ),
    domainName: "bus services",
    colors: {
      background: "#fff3cd",
      primary: "#ffc107",
      secondary: "#dc3545",
      text: "#212529",
      textPrimary: "#343a40",
      textSecondary: "#6c757d",
    },
    sessionTime: 25,
  },
  {
    srNo: "4",
    logo: (
      <FontAwesomeIcon
        className="iconHW icon"
        icon={faBicycle}
      ></FontAwesomeIcon>
    ),
    domainName: "bike sharing",
    colors: {
      background: "#e2e3e5",
      primary: "#17a2b8",
      secondary: "#28a745",
      text: "#212529",
      textPrimary: "#343a40",
      textSecondary: "#6c757d",
    },
    sessionTime: 15,
  },
  {
    srNo: "5",
    logo: (
      <FontAwesomeIcon className="iconHW icon" icon={faTruck}></FontAwesomeIcon>
    ),
    domainName: "freight logistics",
    colors: {
      background: "#f8d7da",
      primary: "#dc3545",
      secondary: "#ffc107",
      text: "#212529",
      textPrimary: "#343a40",
      textSecondary: "#6c757d",
    },
    sessionTime: 40,
  },
  {
    srNo: "6",
    logo: (
      <FontAwesomeIcon
        className="iconHW icon"
        icon={faMotorcycle}
      ></FontAwesomeIcon>
    ),
    domainName: "motorcycle rental",
    colors: {
      background: "#d4edda",
      primary: "#28a745",
      secondary: "#17a2b8",
      text: "#212529",
      textPrimary: "#343a40",
      textSecondary: "#6c757d",
    },
    sessionTime: 35,
  },
];

const colorData = [
  {
    type: "User message, icon hover color",
    key: "primaryColor",
    value: theme?.primaryColor ? theme?.primaryColor : "#007bff",
  },
  {
    type: "User message text color",
    key: "textPrimaryColor",
    value: theme?.textPrimaryColor ? theme?.textPrimaryColor : "#212529",
  },
  {
    type: "Bot response, icon Color",
    key: "secondaryColor",
    value: theme?.secondaryColor ? theme?.secondaryColor : "#6c757d",
  },
  {
    type: "Bot response text Color",
    key: "textSecondaryColor",
    value: theme?.textSecondaryColor ? theme?.textSecondaryColor : "#6c757d",
  },
  {
    type: "Background color",
    key: "backgroundColor2",
    value: theme?.backgroundColor2 ? theme?.backgroundColor2 : "#f5f5f5",
  },
  {
    type: "Text  color",
    key: "textColor",
    value: theme?.textColor ? theme?.textColor : "#0000",
  },
  // {
  //     "type": "Tertiary Color",
  //     "key": "tertiaryColor",
  //     "value": "#e9ecef"
  // },
  // {
  //     "type": "Background Color 1",
  //     "key": "backgroundColor",
  //     "value": theme?.backgroundColor ? theme?.backgroundColor : "#f8f9fa"
  // },
  // {
  //     "type": "Surface Color",
  //     "key": "surfaceColor",
  //     "value": "#ffffff"
  // },

  // {
  //     "type": "Text Disabled Color",
  //     "key": "textDisabledColor",
  //     "value": "#adb5bd"
  // },
  // {
  //     "type": "Link Color",
  //     "key": "linkColor",
  //     "value": "#007bff"
  // },
  // {
  //     "type": "Accent Color",
  //     "key": "accentColor",
  //     "value": "#ffc107"
  // },
  // {
  //     "type": "Border Color",
  //     "key": "borderColor",
  //     "value": "#dee2e6"
  // }
];

function Setting({ theme, setTheme }) {

  const navigate = useNavigate();
  const [selectedChat, setSelectedChat] = useState(undefined);

  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);

  const [chats, setChats] = useState([]);

  const [colors, setColors] = useState(colorData);

  const [colorSettingsList, setColorSettingList] = useState([]);

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };

  useEffect(() => {
    // Fetch chat collection
    // RasaAdminAPI.getChats().then(data => setChats(data));
    getColorSettingList()
    setChats(getChatRs);
  }, []);

  const [logo, setLogo] = useState(null);

  const handleColorChange = (color, key, index) => {
    // setShowColorPicker(prev => !prev)
    setTheme({ ...theme, [key]: color.hex });
    setColors((prev) => {
      const array = [...prev];
      const data = { ...array[index] };
      data.value = color.hex;
      array[index] = {
        ...data,
      };
      return array;
    });
  };

  const [showColorPicker, setShowColorPicker] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { sidebar } = useContext(AppContext);

  const [isLoading,setIsLoading] = useState(true)

  const [startIndex,setStartIndex] = useState(0)

  const [totalPage,setTotalPage] = useState(0)
  const [currentPage,setCurrentPage]= useState(0)



  function getColorSettingList() {
    setIsLoading(true)
    simpleGetCallWithTokenNoNBEARER(ApiConfig.GET_COLOR_SETTING_LIST)
      .then((res) => {
        console.log(res)
        if (res.success) {
          setTotalPage(res?.total_pages)
          setColorSettingList(res?.data)
          setStartIndex(currentPage*10)
        }else{
          setColorSettingList([])
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      })
  }

  const deleteColorSetting=(id)=>{

    deleteWithAuthCall(ApiConfig.DELETE_COLOR_SETTING+id).then((res)=>{

      if(res?.success){
        ToastMsg('success',res?.message)
        getColorSettingList()
      }else{
        ToastMsg('error',res?.message)
      }
    })

  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}
    id="cx-main">
    
      <div
      
      >
        
      
        <div >
          <div className="role-content">
            <div className="sub-header-role ">
              <h6 className="mt-2">Settings List</h6>
              <Link to={"/addSetting"} className="addSetting_btn">
                Add settings
              </Link>
            </div>

            <div className="table-wrapper table-wrapper-2">
              {
                isLoading?<Loader/>:
                colorSettingsList?.length>0?
              
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. no</th>
                    <th>Logos</th>
                    <th>Domains</th>
                    <th>Colors</th>
                    <th>Session time</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {colorSettingsList?.map((item, index) => (
                    <tr key={index} className="table-row-custom">
                      <td>{index + 1} </td>
                      <td style={{ verticalAlign: "top" }}>
                        <div className=""><img src={item?.website_logo} width={100} height={100} alt="" /></div>
                      </td>
                      <td style={{ maxWidth: 100, wordWrap: "break-word" }}><p className="w-100 text-wrap" >{item?.domain}</p></td>
                      <td>
                        <div className="colors">
                          <p>Primary Text Color: {item?.primary_text_color || "Not Provided"} </p>
                          <p>Primary Background Color: {item?.primary_background_color || "Not Provided"} </p>
                          <p>User Message Background: {item?.user_message_bg_color || "Not Provided"} </p>
                          <p>User Message Text Color: {item?.user_message_text_color || "Not Provided"} </p>
                          <p>...</p>
                          {/* <p>Bot Message Background: {item?.bot_message_bg_color || "Not Provided"} </p>
                          <p>Bot Message Text Color: {item?.bot_message_text_color || "Not Provided"} </p>
                          <p>Secondary Background Color: {item?.secondary_background_color || "Not Provided"} </p>
                          <p>Secondary Text Color: {item?.secondary_text_color || "Not Provided"} </p>
                          <p>Background Color: {item?.background_color || "Not Provided"}</p>
                          <p>text Color: {item?.text_color || "Not Provided"}</p> */}
                        </div>
                      </td>
                      <td>{item?.session_time} minutes</td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 300 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Edit
                            </Tooltip>
                          )}
                        >
                          <FontAwesomeIcon
                            onClick={() => navigate("/addSetting/" + item?.id)}
                            icon={faPenToSquare}
                            style={{ width: "15px", height: "15px", marginRight:"10px" }}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 300 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            Delete Setting
                          </Tooltip>
                        )}
                      >
                        <FontAwesomeIcon icon={faTrash}
                        onClick={()=>deleteColorSetting(item?.id)}
                        style={{ width: "15px", height: "15px" }}/>
                      </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>:<NoDataFound/>
}
            </div>
            <Pagination  totalPage={totalPage}
            currentPage={currentPage}
            pageChangeHandler={(page)=>setCurrentPage(page)}/>
            {/* <div className="setting-section"></div> */}
          </div>
        </div>
      </div>
      {/* <ColorPickers theme={theme} setTheme={setTheme} /> */}

      {/* <div className="right-panel"></div> */}
    </div>
  );
}

export default Setting;

function getComplementaryColor(hex) {
  // Ensure hex starts with #
  if (hex[0] !== "#") {
    throw new Error("Invalid hex color code");
  }

  // Remove the # if present
  hex = hex.slice(1);

  // Convert hex to RGB
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Calculate the complementary color
  const compR = (255 - r).toString(16).padStart(2, "0");
  const compG = (255 - g).toString(16).padStart(2, "0");
  const compB = (255 - b).toString(16).padStart(2, "0");

  // Combine into hex string and return
  return `#${compR}${compG}${compB}`;
}
