import { AppContext } from "../../../context/AppContext";
import React, { useState } from "react";
import { useContext } from "react";
import Loader from "../loader";
import NoDataFound from "../NodataFound";
import { Link } from "react-router-dom";
import addedit from "../../../assets/images/Editac.svg";
import { Form, Modal } from "react-bootstrap";
import add from "../../../assets/images/check.svg";
import close from "../../../assets/images/close.svg";
import uploadicon from "../../../assets/images/plus_officecaller.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SubModuleDataStat = () => {
  const { sidebar } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [editline, seteditline] = useState(null);
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [showadd, setShowadd] = useState(false);
    const handleCloseadd = () => setShowadd(false);
  const handleShowadd = () => setShowadd(true);

  const [datalist, setDatalist] = useState([
    {
      data: {
        srno: "1",
        modname: "Data Statistics",
        icon: "icon here",
        display: "module name is Data Stat",
        totalint: 200,
        totalque: 50,
        pubint: 50,
        pubQue: 50,
        nonpubint: 50,
        nonpubque: 50,
      },
    },
  ]);
  return (
    <div className={sidebar ? "taskMain" : "cx-active taskMain"} id="cx-main">
      <div>
        <div className="role-content">
          <div className="sub-header-role d-flex justify-content-between">
            <h6 className="mt-2">Sub Module Data Stat</h6>
            <button className="addSetting_btn" onClick={handleShowadd}>
                Add +
              </button>
          </div>
          <div
            className="table-wrapper table-wrapper-2"
            style={{ height: "600px" }}
          >
            {
              // isLoading ? <Loader /> :
              // datalist.length > 0 ?
              <table style={{ marginBottom: "20px" }}>
                <thead className="">
                  <tr className="firstTabelRow">
                    <th>Sr. No</th>
                    <th>Sub Module Name</th>
                    <th>Icons</th>
                    <th>Display Name</th>
                    <th>Total Intents</th>
                    <th>Total Questions</th>
                    <th>Publish Intents</th>
                    <th>Publish Questions</th>
                    <th>Non-Publish Intents</th>
                    <th>Non-Publish Questions</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {datalist.map((x, index) => (
                  <>
                    {
                      editline ? (
                        <tr key={index} className="table-row-custom">
                        <td>{x.data.srno}</td>
                        <td>
                          <Link to="/submoddataStat">{x.data.modname}</Link>
                        </td>
                        <td>
                          <div class="image-upload">
                            <label for="file-input">
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 100, hide: 300 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    Add Icon
                                  </Tooltip>
                                )}
                              >
                                <img src={uploadicon} />
                              </OverlayTrigger>
                            </label>

                            <input id="file-input" type="file" />
                          </div>
                        </td>
                        <td>
                          <input type="text" className="form-control" />
                        </td>
                        <td>{x.data.totalint}</td>
                        <td>{x.data.totalque}</td>
                        <td>{x.data.pubint}</td>
                        <td>{x.data.pubQue}</td>
                        <td>{x.data.nonpubint}</td>
                        <td>{x.data.nonpubque}</td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 300 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                Save
                              </Tooltip>
                            )}
                          >
                            <img src={add} />
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 300 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                cancel
                              </Tooltip>
                            )}
                          >
                            <img
                              src={close}
                              onClick={() => seteditline(false)}
                            ></img>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      ) : (
                        <tr key={index} className="table-row-custom">
                        <td>{x.data.srno}</td>
                        <td>{x.data.modname}</td>
                        <td>{x.data.icon}</td>
                        <td>{x.data.display}</td>
                        <td>{x.data.totalint}</td>
                        <td>{x.data.totalque}</td>
                        <td>{x.data.pubint}</td>
                        <td>{x.data.pubQue}</td>
                        <td>{x.data.nonpubint}</td>
                        <td>{x.data.nonpubque}</td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 300 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                Edit
                              </Tooltip>
                            )}
                          >
                            <img
                              src={addedit}
                              onClick={() => seteditline(true)}
                            />
                          </OverlayTrigger>
                        </td>
                        </tr>
                      )
                 }
                    </>
                ))}
              </table>
              // : <NoDataFound />
            }
          </div>
        </div>
          </div>
          
      <Modal
        className="delete-news-modal"
        show={showadd}
        onHide={handleCloseadd}
        dialogClassName="modal-90w"
        centered
          >
               <Modal.Header closeButton>
          <Modal.Title className="delete-title">Add</Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <div className="p-3">
        <Form.Select className="mb-3">
            <option>Select Module</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
                  <Form.Group className="mb-3">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Display Name"
                autoFocus
              />
                  </Form.Group>
                  
                  <Form.Group className="mb-3">
              <Form.Label>Icon</Form.Label>
              <Form.Control
                type="file"
              />
            </Form.Group>
              </div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
           onClick={handleCloseadd}
          >
            Cancel
          </button>

          <button className="btn-width saveBtn">
            Add
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default SubModuleDataStat;
