import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import "../../assets/css/notifications.scss";
// import Check from "../../assets/images/check.svg";
import { AppContext } from "../../context/AppContext";
import ReactPaginate from "react-paginate";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const HolidaysAndEvents = () => {
  const { sidebar, setSidebar, Dark, setDark , userData} = useContext(AppContext);
  const [holidayList, setHolidayList] = useState([]);
  useEffect(() => {
    PostCallWithErrorResponse(
      ApiConfig.HOLIDAY_EVENT_API,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        Orgid: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setHolidayList(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Holidays & Events</h6>
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th>Sr. no</th>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Events</th>
                </tr>
              </thead>
              <tbody>
                {holidayList && holidayList.length > 0 ? (
                  holidayList.map((holiday, index) => {
                    return (
                      <tr className="table-row-custom" key={"holiday" + index}>
                        <td> {index + 1}</td>
                        <td>{holiday.Date}</td>
                        <td>{holiday.Day} </td>
                        <td>{holiday.Event}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={6}>Data Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="alert alert-success custom-alert">
              <p>
                Holiday Which mark in (**) subject change on sighting of the
                moon
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidaysAndEvents;
