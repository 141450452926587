import React from 'react'
import { AppContext } from "../../context/AppContext";
import { useState, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import placeholder_img from "../../assets/images/placeholder_img.svg";
import user_icon_1 from "../../assets/images/user_icon_1.svg";
import user_icon_2 from "../../assets/images/user_icon_2.svg";
import user_icon_3 from "../../assets/images/user_icon_3.svg";
import user_icon_4 from "../../assets/images/user_icon_4.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Nav, Tabs } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";

const Achivements = () => {
    const percentage = 75;
    const percentage1 = 60;
    const percentage2 = 80;
    const percentage3 = 100;

    const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">Achievements</h6>
                    </div>
                    {/* <div className="row mt-4"> */}
                    {/* <div className="achivement-Main col-7"> */}
                    {/* <Tab.Container
                                defaultActiveKey="1"
                                id="justify-tab-example"
                                className="mb-3 d-flex"
                                justify
                            >
                                <div className="row d-flex align-items-center justify-content-around">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="my-task-heading-tabs-wrapper">
                                            <Nav className="my-task-heading-tabs">
                                                <Nav.Item className="tab-item">
                                                    <Nav.Link eventKey="1" className="d-flex">
                                                        <p className="activeColorBlue">
                                                            Assigned <span> 99</span>
                                                        </p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="tab-item">
                                                    <Nav.Link eventKey="2">
                                                        <p>
                                                            In Progress <span>99</span>
                                                        </p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="tab-item">
                                                    <Nav.Link eventKey="3">
                                                        <p>
                                                            For Approval <span>99</span>
                                                        </p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="tab-item">
                                                    <Nav.Link eventKey="4">
                                                        <p>
                                                            Completed <span>99</span>
                                                        </p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="1">
                                            <div className="project-list-wrapper">
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-start-date">
                                                        <div className="date">22-11-2022</div>
                                                        <div className="label">Start Date</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_4} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-start-date">
                                                        <div className="date">22-11-2022</div>
                                                        <div className="label">Start Date</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-start-date">
                                                        <div className="date">22-11-2022</div>
                                                        <div className="label">Start Date</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-start-date">
                                                        <div className="date">22-11-2022</div>
                                                        <div className="label">Start Date</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="2">
                                            <div className="project-list-wrapper">
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-progress-bar">
                                                        <div className="label">Progress</div>
                                                        <ProgressBar now={10} />
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-progress-bar">
                                                        <div className="label">Progress</div>
                                                        <ProgressBar now={30} />
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-progress-bar">
                                                        <div className="label">Progress</div>
                                                        <ProgressBar now={80} />
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-progress-bar">
                                                        <div className="label">Progress</div>
                                                        <ProgressBar now={50} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="3">
                                            <div className="project-list-wrapper">
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-yellow">Waiting for Approval</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-green">Approved</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-red">Declined</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-yellow">Waiting for Approval</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="4">
                                            <div className="project-list-wrapper">
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-yellow">On Hold</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-green">On Time</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-red">Declined</div>
                                                    </div>
                                                </div>
                                                <div className="project-list mt-2">
                                                    <div className="project-img">
                                                        <img src={placeholder_img} alt="" />
                                                    </div>
                                                    <div className="project-title-wrapper">
                                                        <div className="project-title">
                                                            <h5>Project Name</h5>
                                                            <p>Client Name</p>
                                                        </div>
                                                    </div>
                                                    <div className="project-team-img">
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_2} alt="" />
                                                        <img src={user_icon_3} alt="" />
                                                        <img src={user_icon_1} alt="" />
                                                        <img src={user_icon_4} alt="" />
                                                    </div>
                                                    <div className="project-status">
                                                        <div className="label status-red">Delayed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Tab.Container> */}
                    {/* </div> */}
                    {/* <div className="task-Achiment-main col-3 p-0"> */}
                    {/* <div className="timesheet-progress-wrapper">
                                <div className="timesheet-progress-main-heading">
                                    <h5 className="mt-2">Timesheet</h5>
                                    <Link to="/timesheet" className="" variant="primary">
                                        + Add New{" "}
                                    </Link>
                                </div>

                                <div className="progress-circles-section">
                                    <div className="row align-items-center text-center">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3 mt-3">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        text={`${percentage}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `#FFA903`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label pt-2">
                                                    <p>
                                                        Timesheet <br /> Submitted
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3 mt-3">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage1}
                                                        text={`${percentage1}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `#00D823`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                        Timesheet <br /> Approved
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3 mt-3">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage2}
                                                        text={`${percentage2}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `#FF0000`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                        Timesheet <br /> Rejected
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3 mt-3">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage3}
                                                        text={`${percentage3}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `#11111199`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                        Timesheet For
                                                        <br /> Approval
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    {/* </div> */}
                    {/* </div> */}
                    <div className="row">
                        <div className="empolyDetails mt-4 col-lg-8 ">
                            <div className="employeeDet my-tasks-section autoheightCustom">
                                <div className="mb-2">
                                    <p className="detailHead">My Achievements</p>
                                    {/* <div className="middleLine mt-1"></div> */}
                                </div>
                                <Tab.Container
                                    defaultActiveKey="1"
                                    id="justify-tab-example"
                                    className="mb-3 d-flex"
                                    justify
                                >
                                    <div className="row d-flex align-items-center justify-content-around">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="my-task-heading-tabs-wrapper mb-0">
                                                <Nav className="my-task-heading-tabs">
                                                    <Nav.Item className="tab-item">
                                                        <Nav.Link eventKey="1" className="d-flex">
                                                            <p>
                                                                Task <span> 99</span>
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="tab-item">
                                                        <Nav.Link eventKey="2">
                                                            <p>
                                                                Complete <span>99</span>
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="tab-item">
                                                        <Nav.Link eventKey="3">
                                                            <p>
                                                                Reject <span>99</span>
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="tab-item">
                                                        <Nav.Link eventKey="4">
                                                            <p>
                                                                On Hold <span>99</span>
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="tab-item">
                                                        <Nav.Link eventKey="5">
                                                            <p>
                                                                Aborted <span>99</span>
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="tab-item">
                                                        <Nav.Link eventKey="6">
                                                            <p>
                                                                Delay <span>99</span>
                                                            </p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                        <Tab.Content >
                                            <Tab.Pane eventKey="1">
                                                <div className="table-wrapper table-wrapper-2 box-shdowCustum">
                                                    <table>
                                                        <tr className="firstTabelRow">
                                                            <th>Sr. no</th>
                                                            <th>Project Type</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 1</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 2</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>

                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 3</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-yellow">Delay</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 4</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 5</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-grey">On Hold</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 6</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 7</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-yellow">Delay</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="2">
                                                <div className="table-wrapper table-wrapper-2 box-shdowCustum">
                                                    <table>
                                                        <tr className="firstTabelRow">
                                                            <th>Sr. no</th>
                                                            <th>Project Name</th>
                                                            <td>Task</td>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 1</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete <br /><span className='complete-name'>Good Job ....! You Compele it before time</span></td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 3</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 4</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete <br /><span className='complete-name'>Good Job ....! You Compele it before time</span></td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 5</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 6</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 7</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete <br /><span className='complete-name'>Good Job ....! You Compele it before time</span></td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 8</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete <br /><span className='complete-name'>Good Job ....! You Compele it before time</span></td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 9</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete <br /><span className='complete-name'>Good Job ....! You Compele it before time</span></td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 10</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-green">Complete <br /><span className='complete-name'>Good Job ....! You Compele it before time</span></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="3">
                                                <div className="table-wrapper table-wrapper-2 box-shdowCustum">
                                                    <table>
                                                        <tr className="firstTabelRow">
                                                            <th>Sr. no</th>
                                                            <th>Project Name</th>
                                                            <td>Task</td>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 1</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 3</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 4</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 5</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 6</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 7</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-red">Reject</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="4">
                                                <div className="table-wrapper table-wrapper-2 box-shdowCustum">
                                                    <table>
                                                        <tr className="firstTabelRow">
                                                            <th>Sr. no</th>
                                                            <th>Project Name</th>
                                                            <td>Task</td>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 1</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-grey">On Hold</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 2</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-grey">On Hold</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="5">
                                                <div className="table-wrapper table-wrapper-2 box-shdowCustum">
                                                    <table>
                                                        <tr className="firstTabelRow">
                                                            <th>Sr. no</th>
                                                            <th>Project Name</th>
                                                            <td>Task</td>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 1</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-blue">Aborted</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 3</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-blue">Aborted</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 4</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-blue">Aborted</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 5</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-blue">Aborted</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="6">
                                                <div className="table-wrapper table-wrapper-2 box-shdowCustum">
                                                    <table>
                                                        <tr className="firstTabelRow">
                                                            <th>Sr. no</th>
                                                            <th>Project Name</th>
                                                            <td>Task</td>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 1</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-yellow">Delay</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 3</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-yellow">Delay</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 4</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-yellow">Delay</td>
                                                        </tr>
                                                        <tr className="table-row-custom">
                                                            <td className="table-row-custom-td"> 5</td>
                                                            <td>Project Name <br /> <span className='clint-name'>Clint Name</span></td>
                                                            <td>UI Design</td>
                                                            <td>14-08-2022</td>
                                                            <td>17-08-2022</td>
                                                            <td className="status-yellow">Delay</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </div>

                        </div>

                        <div className="mt-4 col-lg-4 autoheightCustom">
                            <div className="timesheet-progress-wrapper">
                                <div className="mb-2 d-flex justify-content-between">
                                    <p className="detailHead">Task Achievements</p>
                                    <p className="detailHead">November</p>
                                </div>
                                <div className="middleLine mt-1"></div>
                                <div className="progress-circles-section">
                                    <div className="row align-items-center text-center">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mt-2">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage}
                                                        text={`${percentage}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `green`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label pt-2">
                                                    <p>
                                                        Completed
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4 mt-2">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage1}
                                                        text={`${percentage1}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `red`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                       Rejected
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage2}
                                                        text={`${percentage2}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `grey`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                        On Hold
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage3}
                                                        text={`${percentage3}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `#53B7E8`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                      Aborted
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="progress-circle">
                                                <div className="circle">
                                                    <CircularProgressbar
                                                        value={percentage2}
                                                        text={`${percentage2}`}
                                                        styles={buildStyles({
                                                            rotation: 0,
                                                            strokeLinecap: "butt",
                                                            textSize: "16px",
                                                            pathTransitionDuration: 0.5,
                                                            pathColor: `yellow`,
                                                            textColor: "#000",
                                                            trailColor: "#d6d6d6",
                                                            backgroundColor: "#3e98c7",
                                                        })}
                                                    />
                                                </div>
                                                <div className="progress-label">
                                                    <p>
                                                        Delay
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Achivements