import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import plus from "../../assets/images/plus_officecaller.svg";
import { Link, Navigate } from "react-router-dom";
import ic_excel from "../../assets/images/ic_excel.svg";

import { useNavigate } from "react-router-dom";

const categories = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const months = [
  "Lead",
  "Enquiry Id",
  "App Category",
  "App Platform",
  "Cross Platform Status",
  "CreatedOn",
  "Action",
];
const data = [
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Total
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Totalty
  [
    "Santeep Kumar",
    "None",
    "VT Pricing Calc-Contract Based Solution",
    "vehicletracking,qa",
    "None",
    "05-06-24",
    "",
  ], // Totaln
];

const LeadListCount = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);

  const navigate = useNavigate();

  return (
    <div>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Lead List Count</h6>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search "
                  // onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //   setUsersPerPage(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>

            <div className="import-export-btn-wrapper mt-4">
              <button>
                {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
                <img
                  src={ic_excel}
                  // onClick={(e) => downloadExcelFile(e)}
                  alt=""
                />
                {/* )} */}
              </button>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table className="custom-table">
              <thead>
                <tr className="firstTabelRow">
                  <th>Sr.No</th>
                  {months.map((month, index) => (
                    <th key={index} className="header">
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {categories.map((category, catIndex) => (
                  <tr key={catIndex} className="table-row-custom">
                    <td>{category}</td>
                    {data[catIndex].map((value, monthIndex) => (
                      <td key={monthIndex}>
                        {monthIndex === data[catIndex].length - 1 ? (
                          <div>
                            <Link to="/EnquiryDetails">
                              <img
                                src={eye}
                                alt="Image 2"
                                className="cell-image"
                              />
                            </Link>

                            <img
                              src={plus}
                              alt="Image 2 "
                              className="cell-image ml-1"
                            />
                          </div>
                        ) : (
                          value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadListCount;
