import { useContext, useEffect, useState } from "react";

import ChatDetails from "./ChatDetails";
import ChatDetailsHeader from "./ChatDetailsHeader";
import MessageInput from "./MessageInput";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/AppContext";
import ChatList from "./ChatList";



const getChatRs = [
  {
    sender_id: "user1",
    last_message: "Hello, how can I help you?",
    timestamp: "2024-07-21T12:34:56.789Z",
  },
  {
    sender_id: "user2",
    last_message: "I need assistance with my order.",
    timestamp: "2024-07-21T12:35:56.789Z",
  },
  {
    sender_id: "user3",
    last_message: "Can you provide more details on your service?",
    timestamp: "2024-07-21T12:36:56.789Z",
  },
];

const getChatDetails = {
  events: [
    {
      type_name: "user",
      timestamp: 1675970400,
      data: {
        text: "Hello, how can I help you today?",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970460,
      data: {
        text: " test",
      },
    },
    {
      type_name: "user",
      timestamp: 1675970520,
      data: {
        text: "I have a problem with my order.",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970580,
      data: {
        text: "Can you provide me with your order number?",
      },
    },
    {
      type_name: "system", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    },
  ],
};

function AdminChat() {
  const [selectedChat, setSelectedChat] = useState(undefined);
  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);
  // const [chats, setChats] = useState([]);
  

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };

  const handleSendMessage = (text) => {
    const data = {
      type_name: "bot", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    };
    const data2 = {
      type_name: "user", // This type will be filtered out
      timestamp: Math.floor(Math.random() * 100000000),
      data: {
        text: text,
      },
    };

    setSelectedChatDetails((prev) => ({
      ...prev,
      events: [...prev.events, data2, data],
    }));
    // console.log(`send message: ${text} to ${selectedChat.sender_id}`);
    // RasaAdminAPI.sendMessage(selectedChat.sender_id, text);
  };

 

  const navigate = useNavigate();
  const { sidebar, toggleDrawer,chats, search, setSearch } = useContext(AppContext);

  return (
    <div  className={sidebar ? "taskMain " : "cx-active taskMain"}
    id="cx-main">
  
      <div
     
      >
        {" "}
        {/* <ChatList
          selectedChat={selectedChat}
          onSelectChat={handleChatClick}
        /> */}
        <div >
          <div className="d-flex ">
            
   <div className="drawrModules">
          <div onClick={() => navigate("/")} className="chatHdg">
            <h5 className="m-0 text-center">Users</h5>
          </div>

        
            <>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  value={search}
                  placeholder="Search..."
                  className="searchBarStyl"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="searchBarIconStyl"
                />
              </div>
              <ul className="mt-3 list-unstyled">
                {chats.map((chat, index) => (
                  <li
                    key={index}
                    className={`
                      ${selectedChat && chat.sender_id === selectedChat.sender_id
                        ? "selected"
                        : ""} hover-on-user
                    `}
                    onClick={() => {
                      handleChatClick(chat);

                      // navigate("/");
                    }}
                  >
                    {chat.user_name}
                  </li>
                ))}
              </ul>
            </>
     
        </div>
            <div className="d-flex flex-column w-100">
              <ChatDetailsHeader chatHeader={selectedChat} />
              <ChatDetails chat={selectedChatDetails} selectedChat={selectedChat} onSelectChat={handleChatClick} />
          {<MessageInput onSendMessage={handleSendMessage} />}
            </div>
         
          </div>
        
         
          
        </div>
        {/* <ColorPickers theme={theme} setTheme={setTheme} /> */}
        {/* <div className="right-panel"></div> */}
      </div>
    </div>
  );
}

export default AdminChat;
