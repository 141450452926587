import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";

export default function CrmPushNotification() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Push Notification</h6>
        </div>
        <div className="col-12 pushmain ">
          <div className="row  ">
            <div className=" col-md-6   ">
              <div className="WeeklyMain   ">
                <div class="row">
                  <div class="">
                    <h4>Step by step working</h4>
                    <ul class="timeline">
                      <li>
                        <a target="" href="">
                          Access Firebase Console
                        </a>

                        <p>
                          Go to the Firebase website and sign up for an account
                          if you don’t already have one. You can log in with a
                          Google account for easy access
                        </p>
                      </li>
                      <li>
                        <a href="#">CREATE NEW PROJECT</a>

                        <p>
                          When you log in, you should be directed to the
                          Firebase console. You can manage all of your projects
                          here. Go ahead and create a new one by clicking the
                          blue CREATE NEW PROJECT button. Give your new project
                          a name.
                        </p>
                      </li>
                      <li>
                        <a href="#">
                          Create and store a SendGrid API Key with Mail Send
                          Full Access permissions.
                        </a>

                        <p>
                          Unlike a username and password — credentials that
                          allow access to your full account — an API key is
                          authorized to perform a limited scope of actions. If
                          your API key is compromised, you can also cycle it
                          (delete and create another) without changing your
                          other account credentials.
                        </p>
                      </li>

                      <li>
                        <a href="#">Next</a>

                        <p>
                          Once you create your project, you’ll be redirected to
                          your project’s console overview. If you check the URL
                          at the top of your browser, you’ll see something like
                          https://console.firebase.google.com/project/talks-you-should-watch/overview,
                          where the part of the URL after /project/ matches your
                          project’s name.
                        </p>
                      </li>

                      <li>
                        <a href="#">Add Application</a>

                        <p>
                          Now yoy can add application from Add app option. After
                          this go into the app settings and into the cloud
                          messaging section. You can find server key for android
                          application and APNS Topic, .pem file for ios
                          application. Add those credentials here.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className=" col-md-6   ">
              <div className="WeeklyMain   ">
                <div class="row">
                  <div class="">
                    <h4>Push Notification Settings</h4>

                    <div className="my-3">
                      <label> Server Key</label>
                      <input
                        //   value={newPassword}
                        placeholder=""
                        type="text"
                        className="tasKCategory1"
                        style={{
                          //   fontSize: "14px",
                          height: "38px",
                          borderRadius: "10px",
                        }}
                        //   onChange={(e) => {
                        //     setNewPassword(e.target.value);
                        //   }}
                      />
                    </div>

                    <div className="my-3">
                      <label>APND Topic</label>
                      <input
                        //   value={newPassword}
                        placeholder=""
                        type="text"
                        className="tasKCategory1"
                        style={{
                          //   fontSize: "14px",
                          height: "38px",
                          borderRadius: "10px",
                        }}
                        //   onChange={(e) => {
                        //     setNewPassword(e.target.value);
                        //   }}
                      />
                    </div>

                    <div className="my-3">
                      <label>APND Certificate</label>
                      <input placeholder="" type="file" style={{}} />
                    </div>

                    <div className="my-3 buttonpushmain">
                      <Button className="custoBtnForCrmCall">Submit</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
