import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const UploadCandidateOld = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const CandidateData = [
    {
      name: "Aadya Keshari",
      email: "aadyakeshari6@gmail.com",
      phonenumber: "+919140474996",
      workexperience: "2Y 7M",
      currentlocation: "Pune",
      perferredlocation: "Pune",
      technology: "",
    },
    {
      name: "Aaisha Saeed Sanglikar",
      email: "sanglikaraaisha@gmail.com",
      phonenumber: "+918983588165",
      workexperience: "2Y 7M",
      currentlocation: "Pune",
      perferredlocation: "Pune",
      technology: "iOS",
    },
    {
      name: "Abdulla Ansar Vp",
      email: "akkuansar50@gmail.com",
      phonenumber: "+918593973153",
      workexperience: "3Y 0M",
      currentlocation: "Kochi/cochin",
      perferredlocation: "Kochi/cochin",
      technology: "Wordpress",
    },
    {
      name: "Abhijay Pawar",
      email: "pawar.abhijay@gmail.com",
      phonenumber: "+917387427246",
      workexperience: "3Y 0M",
      currentlocation: "Pune",
      perferredlocation: "Hyderabad/secunderabad,pune,mumbai (all Areas)",
      technology: "Automation QA",
    },
    {
      name: "Abhijay Pawar",
      email: "pawar.abhijay@gmail.com",
      phonenumber: "+917387427246",
      workexperience: "3Y 0M",
      currentlocation: "Pune",
      perferredlocation: "Hyderabad/secunderabad,pune,mumbai (all Areas)",
      technology: "Automation QA",
    },
    {
      name: "Abhimanyu V S",
      email: "abhimanyu186@gmail.com",
      phonenumber: "+919995527829",
      workexperience: "3Y 7M",
      currentlocation: "Idukki",
      perferredlocation:
        "Kochi/cochin,trivandrum/thiruvananthapuram,bangalore/bengaluru",
      technology: "Content Writer",
    },
    {
      name: "Achala Belokar",
      email: "achalabelokar@gmail.com",
      phonenumber: "+918668379159",
      workexperience: "3Y 0M",
      currentlocation: "Pune",
      perferredlocation: "Bangalore/bengaluru,mumbai,pune",
      technology: "QA Automation",
    },
    {
      name: "Adarsh Kc",
      email: "kcadarsh18@gmail.com",
      phonenumber: "+919895095940",
      workexperience: "4Y 7M",
      currentlocation: "Cannanore/kannur",
      perferredlocation:
        "Calicut/kozhikode,trivandrum/thiruvananthapuram,kochi/cochin",
      technology: "iOS",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Upload Candidate Data</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="registerbutton">
              <input name="choosefile" type="file" />
            </div>

            <div className="entries-wrapper mt-0 cus-mb">
              <button className="sml-btn-sub-header" style={{ color: "#fff" }}>
                Upload
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="registerbutton cus-mb">
              <p>Candidate Data Count : 262</p>
            </div>
          </div>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Candidate Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Work Experience</th>
                <th scope="col">Current Location</th>
                <th scope="col">Perferred Location</th>
                <th scope="col">Technology</th>
              </tr>
            </thead>
            <tbody>
              {CandidateData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.name}</td>
                  <td>{data.email}</td>
                  <td>{data.phonenumber}</td>
                  <td>{data.workexperience}</td>
                  <td>{data.currentlocation}</td>
                  <td>{data.perferredlocation}</td>
                  <td>{data.technology}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default UploadCandidateOld;
