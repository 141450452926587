import Eyes from "../../assets/images/ic_show_eyes.svg";
import React, { useContext, useEffect, useState } from "react";
import importicon from "../../assets/images/import.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const WalletRecharge = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="employee_wallet_sales">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Wallet Recharge </h6>
          </div>
          <div className="mainTableBox">
            <div className="upperFlex">
              <button className="CreateWallet">
                {" "}
                <Link to="/RechargeWallet"> Create Wallet Recharge</Link>
              </button>
              <button className="exportBtn">
                <img src={importicon} alt="" />
              </button>
            </div>
            <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
              <table className="">
                <thead className="firstTabelRow">
                  <tr classname="row-hover modal-footer-table-body-tr">
                    <th>#</th>
                    <th>Employee</th>
                    <th>Amount</th>
                    <th>Date & Time</th>
                    <th>Recharge ID</th>
                    <th>Reference No</th>
                    <th>Recharge Mode</th>
                    <th>Operator</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-row-custom">
                    <td></td>
                    <td>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">All</option>
                        <option value="One">One</option>
                        <option value="Two">Two</option>
                      </select>
                    </td>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <input type="date" className="form-control" />
                    </td>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <input type="text" className="form-control" />
                    </td>
                    <td>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">All</option>
                        <option value="One">One</option>
                        <option value="Two">Two</option>
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">All</option>
                        <option value="One">One</option>
                        <option value="Two">Two</option>
                      </select>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <button className="clearBtn">Clear</button>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>1</td>
                    <td>Image</td>
                    <td>Alex</td>
                    <td>500</td>
                    <td>Information</td>
                    <td>Information</td>
                    <td>Information</td>
                    <td>Information</td>
                    <td>29-02-2024:10:00:pm</td>
                    <td></td>
                    <td></td>
                    <td className="text-end">
                      <button className="actionBtn">
                        <Link to="/TransactionReceipt">
                          <img src={Eyes} alt="" />
                        </Link>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="paginationTxt">
              <h1>Showing 1-1 of 1 Items</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletRecharge;
