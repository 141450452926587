import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import Eyes from "../../assets/images/ic_show_eyes.svg";


const ProductStock = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2">Products Stock</h6>
                        <div className="btnFlex">
                            <button className="CategoryStockBtn">
                                <Link to="/AddProductStock">
                                    Add or Adjust Product Stock
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="mainTableBox">
                        <div className="insideTable">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Stock</th>
                                        <th>product</th>
                                        <th>Stock in hand</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                            >
                                                <option value="">All</option>
                                                <option value="One">One</option>
                                                <option value="Two">Two</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Image</td>
                                        <td>Alex</td>
                                        <td>500</td>
                                        <td></td>
                                        <td>
                                            <button className="actionBtn">
                                                <Link to="/Canteen">
                                                    <img src={Eyes} alt="" />
                                                </Link>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductStock;
